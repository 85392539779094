import axios from "axios";

function getCarModels(idCD, marca, anio) {
    let carModelsUrl = process.env.REACT_APP_BACK_SERVER + '/getModelosAutos'
    return axios.get(carModelsUrl, {
        params: {
            'idcanaldigital': idCD,
            'marca': marca,
            'anio': anio
        }
    });
}

export default getCarModels;