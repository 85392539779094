import React, { createRef, useContext, useEffect, useState } from "react";
import SelectField from "../../FormFields/SelectField";
import { useFormikContext } from "formik";
import ClienteDirectoContext from "../../../../context/ClienteDirectoContext";
import InputField from "../../FormFields/InputField";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import InputRefField from "../../FormFields/InputRefField";
import MotovehiculoContext from "../../../../context/products/MotovehiculoContext/MotovehiculoContext";
import { findSelectedLabel } from "../../../findSelectedLabel";
import { Grid } from "@mantine/core";
import { ZipCodeFieldPackage } from "../../../ZipCodeFieldPackage";

export default function VehiculoForm(props) {
    const {
        formField: {
            vehiculo: {
                marca, anio, version, ceroKm
            },
            cliente: {
                nombre,
                apellido,
                telefono,
                email,
                codigosLocalidad,
                codigoPostal,
                idProvincia
            },
        },
    } = props;
    const { values, setFieldValue } = useFormikContext();
    const {localidades} = useContext(ClienteDirectoContext);
    const ref = createRef();

    const {
        marcas,
        anios,
        versiones,
        vehicleDetails,
        updateVersions,
        isLoadingVersions
    } = useContext(MotovehiculoContext);

    const [localidadOptions, setLocalidadOptions] = useState(localidades);
    const [provinciasOptions, setProvinciasOptions] = useState([]);

    const cerokm_options = [
        { value: 'true', label: 'Si' },
        { value: 'false', label: 'No' }
    ];

    useEffect(() => {
        setFieldValue(`vehiculo.${version.name}`, '', false);
        if (values.vehiculo.anio !== '') {
            updateVersions(values.vehiculo.marca, values.vehiculo.anio);
        }
    }, [values.vehiculo.anio, values.vehiculo.marca]);

    return (
        <React.Fragment>
            <Grid align="center">
                <Grid.Col xs={12} sm={6}>
                    <SelectField
                        name={`vehiculo.${marca.name}`}
                        label={marca.label}
                        data={marcas}
                        onChangeAction={value => {
                            vehicleDetails.current = {
                                ...vehicleDetails.current,
                                detalle_marca: findSelectedLabel(marcas, value)
                            }
                        }}
                    />
                </Grid.Col>

                <Grid.Col xs={12} sm={6}>
                    <SelectField
                        name={`vehiculo.${anio.name}`}
                        label={anio.label}
                        data={anios}
                    />
                </Grid.Col>

                <Grid.Col xs={12} sm={6}>
                    <SelectField
                        name={`vehiculo.${version.name}`}
                        label={version.label}
                        loadingData={isLoadingVersions}
                        data={versiones}
                        onChangeAction={value => {
                            vehicleDetails.current = {
                                ...vehicleDetails.current,
                                detalle_version: findSelectedLabel(versiones, value)
                            }
                        }}
                        clearable
                    />
                </Grid.Col>

                <Grid.Col xs={12} sm={6}>
                    <SelectField
                        name={`vehiculo.${ceroKm.name}`}
                        label={ceroKm.label}
                        data={cerokm_options}
                    />
                    {values.vehiculo.tieneGnc === 'true' && (
                        <div className="fill__div" />
                    )}
                </Grid.Col>

                <ZipCodeFieldPackage
                    localidadField={codigosLocalidad}
                    localidadSize={6}
                    codigoPostalField={codigoPostal}
                    codigoPostalSize={6}
                    provinciaField={idProvincia}
                    provinciaSize={6}
                    localidadOptions={localidadOptions}
                    setLocalidadOptions={setLocalidadOptions}
                    provinciasOptions={provinciasOptions}
                    setProvinciaOptions={setProvinciasOptions} />

                <Grid.Col xs={12} sm={6}>
                    <InputField
                        name={`cliente.${nombre.name}`}
                        label={nombre.label}
                        required={true}
                    />
                </Grid.Col>

                <Grid.Col xs={12} sm={6}>
                    <InputField
                        name={`cliente.${apellido.name}`}
                        label={apellido.label}
                        required={true}
                    />
                </Grid.Col>

                <Grid.Col xs={12} sm={6}>
                    <div className="field">
                        <PhoneInput
                            defaultCountry="AR"
                            ref={ref}
                            inputComponent={InputRefField}
                            name={`cliente.${telefono.name}`}
                            label={telefono.label}
                            value={values.cliente.telefono}
                            onChange={(e) => {
                                setFieldValue(`cliente.${telefono.name}`, e)
                            }}
                        />
                    </div>
                </Grid.Col>

                <Grid.Col xs={12} sm={6}>
                    <InputField
                        name={`cliente.${email.name}`}
                        label={email.label}
                        required={true}
                    />
                </Grid.Col>
            </Grid>
        </React.Fragment>
    );
}
