import React from 'react';
import { ReactComponent as Linkedin } from '../assets/socialMedia/linkedin.svg'
import { ReactComponent as Instagram } from '../assets/socialMedia/instagram.svg'
import { ReactComponent as Youtube } from '../assets/socialMedia/youtube.svg'
import { ReactComponent as Tiktok } from '../assets/socialMedia/tiktok.svg'
import { ReactComponent as Other } from '../assets/socialMedia/other.svg'
import { ReactComponent as Facebook } from '../assets/socialMedia/facebook.svg';

const socialNetworks = {
    'linkedin': <Linkedin className="footer__icons--filled"/>,
    'instagram': <Instagram className="footer__icons--filled"/>,
    'facebook': <Facebook className="footer__icons--filled"/>,
    'tiktok': <Tiktok className="footer__icons--filled"/>,
    'youtube': <Youtube className="footer__icons--filled"/>
}

/**
 * If the link contains any of the social network names, return the corresponding image, otherwise return the default image.
 * @param link - The link that you want to check if it contains any of the social networks.
 * @returns The image of the social network that is found in the link.
 */
function containsAny(link) {
    const found = Object.keys(socialNetworks).find(v => link.includes(v));
    return <a href={'https://' + link} target="_blank">{found ? socialNetworks[found] : <Other className="footer__icons--filled"/>}</a>
}

function SocialMediaLinks(props) {
    const {links} = props;
    return (
        <div className="structure__center">
            {links.map(link => (<div key={link}>
                {containsAny(link)}
            </div>))}
        </div>
    );
}

export default SocialMediaLinks;