import React, {createRef, useEffect} from 'react';
import {useFormikContext} from 'formik'
import CuitGenerator from "../../../Utils/CuitGenerator";
import InputField from "../../FormFields/InputField";
import SelectField from "../../FormFields/SelectField";
import DateField from "../../FormFields/DateField";
import PhoneInput from "react-phone-number-input";
import InputRefField from "../../FormFields/InputRefField";

export default function ClienteForm(props) {
    const {
        formField: {
            cliente: {
                dni,
                cuit,
                nombre,
                apellido,
                fechaNacimiento,
                sexo,
                calle,
                numero,
                opcional,
                telefono,
                email,
            }
        },
    } = props;
    const {values, setFieldValue} = useFormikContext();
    const ref = createRef();

    const opcionesSexo = [
        {value: '1', label: 'Femenino'},
        {value: '2', label: 'Masculino'}
    ];

    useEffect(() => {
        if (values.cliente.dni) {
            if ((values.cliente.dni).length === 8 && values.cliente.sexo !== '') {
                let generatedCuit = CuitGenerator(values.cliente.dni, values.cliente.sexo)
                setFieldValue('cliente.cuit', generatedCuit)
            }
        }
    }, [values.cliente.dni, values.cliente.sexo]);

    return (
        <React.Fragment>
            <div className="structure__form structure__center">
                <InputField
                    name={`cliente.${nombre.name}`}
                    label={nombre.label}
                />
                <InputField
                    name={`cliente.${apellido.name}`}
                    label={apellido.label}
                />
                <SelectField
                    name={`cliente.${sexo.name}`}
                    label={sexo.label}
                    data={opcionesSexo}
                />
                <InputField
                    name={`cliente.${dni.name}`}
                    label={dni.label}
                />
                {/*Used to be RegularInputField*/}
                <InputField
                    name={`cliente.${cuit.name}`}
                    label={cuit.label}
                />
                <DateField
                    name={`cliente.${fechaNacimiento.name}`}
                    label={fechaNacimiento.label}
                />
                <div className="field">
                    <PhoneInput
                        defaultCountry="AR"
                        ref={ref}
                        inputComponent={InputRefField}
                        name={`cliente.${telefono.name}`}
                        label={telefono.label}
                        value={values.cliente.telefono}
                        onChange={(e) => {
                            setFieldValue(`cliente.${telefono.name}`, e)
                        }}
                    />
                </div>
                <InputField
                    name={`cliente.${email.name}`}
                    label={email.label}
                />
                <InputField
                    name={`cliente.${calle.name}`}
                    label={calle.label}
                />
                <InputField
                    name={`cliente.${numero.name}`}
                    label={numero.label}
                />
                <InputField
                    name={`cliente.${opcional.name}`}
                    label={opcional.label}
                />
            </div>
        </React.Fragment>
    );
}
