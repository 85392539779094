import React, {useEffect, useState} from 'react';
import InputField from "../../FormFields/InputField";
import {FieldArray, useFormikContext} from "formik";
import minus from "../../../../assets/systemIcons/minus.png";
import add from "../../../../assets/systemIcons/add.png";

import {Dropzone, MIME_TYPES} from "@mantine/dropzone";
import {Group, Text, Modal} from "@mantine/core";
import {IconUpload, IconPhoto, IconX} from '@tabler/icons';
import DropzoneFile from "../../../DropzoneFile";
import NumberField from "../../FormFields/NumberField";
import {currencyFormat} from "../../../Utils/currencyFormat";
import useWindowDimensions from "../../../Utils/useWindowDimensions";
import PopOverInfo from "../../../PopOverInfo";
import samplePhotos from "../../../../assets/others/SamplePhotos.jpg"
import {saveFileContent} from "../../../Utils/saveFileContent";
import normalizeString from '../../../Utils/normalizeString';

const modalStyles = {
    layout: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    title: {
        fontFamily: 'sans-serif',
        color: "#35303F",
        fontSize: "1rem",
        fontWeight: 600,
        textAlign: "center",
        lineHeight: "1.5"
    },
    text: {
        fontFamily: 'sans-serif',
        color: "#35303F",
        fontSize: "1rem",
        fontWeight: 500,
        textAlign: "start",
        lineHeight: "1",
        marginTop: "16px"
    },
    note: {
        fontFamily: 'sans-serif',
        color: "#9a9a9a",
        fontSize: "0.75rem",
        fontWeight: 400,
        textAlign: "center",
        lineHeight: "1",
        marginTop: "8px"
    }
}

export default function EmisionForm(props) {
    const {
        formField: {
            vehiculo: {
                patente,
                motor,
                chasis,
                valorGnc,
                tieneGnc,
                gnc: {
                    descripcionGnc,
                    marcaRegulador,
                    numeroRegulador,
                    cilindros: {
                        marcaCilindro,
                        numeroCilindro,
                    },
                },
            },
        },
    } = props;
    const {width} = useWindowDimensions();
    const isMobile = width < 768;
    const {values, setFieldValue, errors} = useFormikContext();
    const [openPhotoHelp, setOpenPhotoHelp] = useState(false);
    const currentWeight = React.useRef(0);
    const [error, setError] = React.useState(null)

    const cilindroVacio = {
        [marcaCilindro.name]: '',
        [numeroCilindro.name]: '',
    };

    useEffect(() => {
        if (typeof errors?.vehiculo?.inspeccionPrevia?.archivos === "string") {
            setError(errors['vehiculo']['inspeccionPrevia']['archivos'])
        }
    }, [errors?.vehiculo?.inspeccionPrevia?.archivos]);

    const buttonsToShow = (index, length, arrayHelpers, emptyArray) => {
        if (length === 1) {
            return (
                <div className="form__item__iconTiny">
                    <button className="button__squaredTiny" type="button"
                            onClick={() => {
                                arrayHelpers.push(emptyArray)
                            }}>
                        <img src={add} width={16} height={16} alt={"+"}/>
                    </button>
                </div>)
        } else if (index !== length - 1) {
            return (<div className="form__item__iconTiny">
                <button className="button__squaredTiny" type="button"
                        onClick={() => arrayHelpers.remove(index)}>
                    <img src={minus} width={16} height={16} alt={"-"}/>
                </button>
            </div>)
        } else {
            return (
                <div className="form__item__iconTiny">
                    <button className="button__squaredTiny--half" type="button"
                            onClick={() => arrayHelpers.remove(index)}>
                        <img src={minus} width={8} height={8} alt={"-"}/>
                    </button>
                    <button className="button__squaredTiny--half" type="button"
                            onClick={() => arrayHelpers.push(emptyArray)}>
                        <img src={add} width={8} height={8} alt={"+"}/>
                    </button>
                </div>
            )
        }
    }

    return (
        <React.Fragment>
            <div className="structure__margin--top">
                <div className="structure__columnToRow">
                    <p className="form__subtitle">
                        {`${values.vehiculo.detalle_marca} - ${values.vehiculo.anio} `}
                    </p>
                    <p className="form__subtitle">
                        {` ${values.vehiculo.detalle_version}`}
                    </p>
                </div>

                <div className="structure__columnToRow">
                    <p className="form__text">
                        Suma asegurada: <span
                        className="form__text--dark">${currencyFormat(values.vehiculo.valor)}</span>
                    </p>
                </div>

            </div>
            <div className="structure__form">
                <InputField
                    name={`vehiculo.${patente.name}`}
                    label={patente.label}
                    format={"UPPERCASE"}
                    required={true}
                />
                <InputField
                    name={`vehiculo.${motor.name}`}
                    label={motor.label}
                    required={true}
                />
                <InputField
                    name={`vehiculo.${chasis.name}`}
                    label={chasis.label}
                    required={true}
                />
                <InputField
                    name={`vehiculo.${tieneGnc.name}`}
                    value={values.vehiculo.tieneGnc === "true" ? "Si" : "No"}
                    label={tieneGnc.label}
                    disabled
                />
                {values.vehiculo.tieneGnc === "true" &&
                <NumberField
                    name={`vehiculo.${valorGnc.name}`}
                    label={valorGnc.label}
                    disabled
                />
                }
            </div>
            {values.vehiculo.tieneGnc === "true" &&
            <>
                <p className="form__text--primary structure__margin--top">Datos del equipo GNC</p>
                <div className="structure__form">
                    <InputField
                        name={`vehiculo.gnc.${descripcionGnc.name}`}
                        label={descripcionGnc.label}
                        required={true}
                    />

                    {/*Item to fill one place which organizes the form*/}
                    <div className="fill__div"/>

                    <InputField
                        name={`vehiculo.gnc.${marcaRegulador.name}`}
                        label={marcaRegulador.label}
                        required={true}
                    />
                    <InputField
                        name={`vehiculo.gnc.${numeroRegulador.name}`}
                        label={numeroRegulador.label}
                        required={true}
                    />

                    <FieldArray name='vehiculo.gnc.cilindros'>{(arrayHelpers) => (
                        values.vehiculo.gnc.cilindros.map((e, index) => {
                            if (isMobile) {
                                return (
                                    <div className="form__item--automotor">
                                        <InputField
                                            name={`vehiculo.gnc.cilindros.[${index}].${marcaCilindro.name}`}
                                            label={marcaCilindro.label}
                                            size="xs"
                                            required={true}
                                        />
                                        <InputField
                                            name={`vehiculo.gnc.cilindros.[${index}].${numeroCilindro.name}`}
                                            label={numeroCilindro.label}
                                            size="xs"
                                            required={true}
                                        />
                                        {buttonsToShow(index, values.vehiculo.gnc.cilindros.length, arrayHelpers, cilindroVacio)}
                                    </div>
                                )
                            } else {
                                return (
                                    <>
                                        <InputField
                                            name={`vehiculo.gnc.cilindros.[${index}].${marcaCilindro.name}`}
                                            label={marcaCilindro.label}
                                            required={true}
                                        />
                                        <div className="form__item--cilindro">
                                            <InputField
                                                name={`vehiculo.gnc.cilindros.[${index}].${numeroCilindro.name}`}
                                                label={numeroCilindro.label}
                                                required={true}
                                            />
                                            {buttonsToShow(index, values.vehiculo.gnc.cilindros.length, arrayHelpers, cilindroVacio)}
                                        </div>
                                    </>
                                )
                            }

                        })
                    )}</FieldArray>
                </div>
            </>
            }

            <div className="structure__margin--topOnly">
                <div className="structure__start">
                    <label className="label">Archivos</label>
                    <div onClick={() => setOpenPhotoHelp(true)}>
                        <PopOverInfo descripcion="¿Qué archivos subir y cómo?"/></div>
                </div>
                <Dropzone
                    className={values.vehiculo.inspeccionPrevia.archivos.length >= 10 && "dropzone__disabled"}
                    onDrop={(files) => {
                        let json = []
                        let len = values.vehiculo.inspeccionPrevia.archivos.length
                        setError(null)
                        files.map(async function (file, index) {
                            const filename = file.name.split(/[.]+/)[0];
                            currentWeight.current += file.size;
                            if (len < 10 && currentWeight.current < 10485760) {
                                let extension = file.name.split(/[.]+/).pop().toUpperCase();
                                if (extension !== 'JFIF') {
                                    if (filename.length === 0) {
                                        setError('El nombre del archivo debe tener al menos 1 caracter.')
                                        currentWeight.current -= file.size
                                    } else {
                                        const fileData = file.slice();
                                        const normalizedFilename = normalizeString(filename).substring(0, 24);
                                        const normalizedFilePath = `${normalizedFilename}.${extension}`;

                                        json.push(                                                    {
                                            "name": normalizedFilePath,
                                            "extension": file.type.split("/")[1],
                                            "content": "",
                                            "file": new File([fileData], normalizedFilePath, {
                                                path: normalizedFilePath,
                                                size: fileData.size,
                                                type: file.type,
                                            }),
                                            "type": null,
                                        })

                                        saveFileContent(file).then((fileContent) => {
                                            json[index].content = fileContent;
                                        });
                                    }
                                } else {
                                    setError('El formato .JFIF no es válido.')
                                    currentWeight.current -= file.size
                                }
                            } else {
                                setError('El tamaño total supera los 10MB.')
                                currentWeight.current -= file.size
                            }
                            len += 1
                        })
                        setFieldValue('vehiculo.inspeccionPrevia.archivos', values.vehiculo.inspeccionPrevia.archivos.concat(json), false);
                    }}
                    onReject={(files) => console.log('rejected files', files)}
                    maxSize={3 * 1024 ** 2}
                    maxFiles={10}
                    accept={[MIME_TYPES.png, MIME_TYPES.jpeg, MIME_TYPES.pdf]}
                    disabled={values.vehiculo.inspeccionPrevia.archivos.length >= 10}
                    {...props}
                >
                    <Group position="center" spacing="xl" style={{minHeight: 220, pointerEvents: 'none'}}>
                        <Dropzone.Accept>
                            <IconUpload
                                size={50}
                                stroke={1.5}
                            />
                        </Dropzone.Accept>
                        <Dropzone.Reject>
                            <IconX
                                size={50}
                                stroke={1.5}
                            />
                        </Dropzone.Reject>
                        <Dropzone.Idle>
                            <IconPhoto size={50} stroke={1.5}/>
                        </Dropzone.Idle>

                        <div>
                            <Text size="xl" inline align="center">
                                Arrastre o haga clic para seleccionar los archivos
                            </Text>
                            <Text size="sm" color="dimmed" inline mt={7} align="center">
                                Puede adjuntar un máximo de 10 archivos (imágenes o pdf), cada archivo no debe
                                superar los 5mb y en total no pueden exceder los 10mb.
                            </Text>
                        </div>
                    </Group>
                </Dropzone>
                <div style={{
                    display: error ? 'flex' : 'none',
                    width: '100%',
                    paddingTop: '12px',
                    justifyContent: 'center'
                }}>
                    <p className="errorMessage structure__margin--topOnly"
                       style={{color: '#c92a2a', letterSpacing: -0.8}}
                    >
                        {error}
                    </p>
                </div>

                <FieldArray name='vehiculo.inspeccionPrevia.archivos'>{({remove}) => (
                    <div className="dropzone__grid">
                        {
                            values.vehiculo.inspeccionPrevia.archivos.map((file, index) => {
                                return (
                                    <DropzoneFile
                                        file={file}
                                        index={index}
                                        onDelete={() => {
                                            remove(index)
                                            currentWeight.current -= file.file.size
                                        }}
                                    />)
                            })
                        }
                    </div>
                )}</FieldArray>
            </div>

            <Modal
                opened={openPhotoHelp}
                onClose={() => setOpenPhotoHelp(false)}
                centered
                withCloseButton={false}
                size="auto"
            >
                <div style={modalStyles['layout']}>
                    <p style={modalStyles['title']}>
                        Deben tomarse 4 (cuatro) fotos con luz diurna: desde una perspectiva frontal, otra posterior
                        y dos laterales.
                    </p>
                    <p style={modalStyles['text']}>Por ejemplo:</p>
                    <img src={samplePhotos} alt="" style={{maxWidth: '100%', maxHeight: '100%'}}/>
                    <p style={modalStyles['note']}>
                        NOTA: Si el auto es 0km puede adjuntar el certificado de no rodamiento en lugar de las
                        fotos.
                    </p>
                </div>
            </Modal>
        </React.Fragment>
    );
}
