import React, {useState} from 'react';
import {useFormikContext} from 'formik';
import {NumberInput} from "@mantine/core";
import {jsonFlatten} from "../../Utils/jsonFlatten";
import {currencyFormat} from "../../Utils/currencyFormat";

const NumberField = (props) => {
    const {name, searchName = null, notFormat, onChangeAction, rightSection, ...rest} = props;
    const {values, errors, touched, setFieldTouched} = useFormikContext();
    const [showWarning, setShowWarning] = useState({show:false, label: "mínima", value: 0});

    const handleErrorText = () => {
        if (jsonFlatten(errors)[name] && jsonFlatten(touched)[name]) {
            return jsonFlatten(errors)[name];
        }
    }

    function setDataOnNestedObject(key,val,obj) {
        let ka = key.split(".");
        if (ka.length < 2) {
            obj[ka[0]] = val;
        } else {
            if (!obj[ka[0]]) obj[ka[0]] = {};
            obj = obj[ka.shift()];
            setDataOnNestedObject(ka.join("."),val,obj);
        }
    }

    const handleChange = (v) => {
        setFieldTouched(name, true)
        if (v !== undefined)
            setDataOnNestedObject(name,v, values);
        else if (props.min !== undefined) {
            setDataOnNestedObject(name, props.min, values);
        } else {
            setDataOnNestedObject(name, 0, values);
        }
        if (onChangeAction) {
            onChangeAction(v)
        }
    }

    const handleBlur = (v) => {
        if (props.min !== undefined) {
            if (parseInt(v.target.value.split(" ")[1].replace(/\./g, "")) < props.min){
                setShowWarning({show:true, label: "mínima", value: props.min})
            } else if (parseInt(v.target.value.split(" ")[1].replace(/\./g, "")) > props.max ) {
                setShowWarning({show:true, label: "máxima", value: props.max})
            }
        }
    }

    return (
        <div className="field">
            <NumberInput
                value={searchName ? jsonFlatten(values)[searchName] : jsonFlatten(values)[name]}
                parser={(value) => value.replace(/\$\s?|(\.*)/g, '')}
                formatter={(value) => {
                    if (notFormat) return value
                    return !Number.isNaN(parseFloat(value))
                        ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                        : '$ '
                }}
                onChange={(v) => handleChange(v)}
                onBlur={(v) => handleBlur(v)}
                error={handleErrorText()}
                hideControls
                radius="md"
                size="md"
                rightSection={rightSection}
                rightSectionWidth={"auto"}
                classNames={{
                    wrapper: 'mantine__field__wrapper',
                    label: 'mantine__field__label',
                    input: 'mantine__field__field',
                    required: 'mantine__field__required',
                    error: 'mantine__field__error',
                    rightSection: 'mantine__field__rightSection',
                }}
                {...rest}
            />
            <p className="warningMessage" style={{display: showWarning['show'] ? "block" : "none"}}>
                La suma {showWarning['label']} es $ {currencyFormat(showWarning['value'])}
            </p>
        </div>
    );
};

export default NumberField;
