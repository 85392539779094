import React, {Fragment, useEffect, useState} from 'react';
import {Divider, Select} from "@mantine/core";
import SmallLoading from "../../SmallLoading";
import {useFormikContext} from "formik";
import {handleDownloadDocs} from "../../Utils/handleDownloadDocs";
import "./EmisionSuccess.css";

function EmisionSuccess(props) {
    const {emisionResponse, setActiveStep, setEmisionResponse, emissionIdentifiers} = props;
    const {values} = useFormikContext();
    const [selectedDocument, setSelectedDocument] = useState("");
    const emissionStatus = emisionResponse['status'] || emisionResponse['estado'];

    const [loading, setLoading] = useState(false);

    const downloadDocumentation = async () => {
        setLoading(true)

        let url = process.env.REACT_APP_QUOTATION_MICROSERVICE + '/documentacion/asistencia-viajero'

        let passengerSelected = {}
        emisionResponse['pasajeros'].forEach((pasajero) => {
            pasajero?.documentacion?.forEach(documento => {
                if (documento === selectedDocument) {
                    passengerSelected = pasajero
                }
            })
        })

        try {
            await handleDownloadDocs (
                url,
                'TerraWind',
                selectedDocument,
                passengerSelected['identificadores'],
                "documentacion.pdf"
            );
        } catch (error) {
            alert("Ocurrió un error al descargar su documento, utilice sus códigos para consultarlos en la web de la aseguradora")
        }

        setLoading(false)
    }

    useEffect(() => {
        if (emisionResponse && emisionResponse['documentacion']) {
            setSelectedDocument(emisionResponse['documentacion'][0])
        }
    }, []);

    const returnDOM = () => {
        if (emissionStatus !== undefined && emissionStatus !== "Failed") {
            return (<Fragment>
                <div className="structure__column">
                    <p className="form__title">¡FELICITACIONES!</p>
                    <p className="form__subtitle">El seguro de los pasajeros se emitió correctamente.</p>

                    <p className="form__text--center structure__margin--top">
                        Toda la información correspondiente a tu póliza y de tu nuevo Productor Asesor de
                        Seguros será enviada a tu email {values.cliente.email}.
                        <br/><br/>
                        ¡También podés descargar tu documentación a continuación!
                    </p>
                </div>

                <Divider my="sm" size="xs"/>

                <div className="successModal__listContainer">
                    {Object.keys(emissionIdentifiers).map((key) => (
                        <div key={key} className="structure__margin--top--md">
                            <p className="form__text--primary">{key}</p>
                            <ul className="successModal__list">
                                {emissionIdentifiers[key].map((value, index) => (
                                    <li key={index} className="form__text--center">{value}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>

                <Divider my="sm" size="xs"/>

                <p className="form__text--primary">Documentación</p>

                <div className="structure__margin--top">
                    <Select
                        label=""
                        placeholder="Seleccione el documento a descargar"
                        onChange={(e) => setSelectedDocument(e)}
                        defaultValue={emisionResponse?.pasajeros[0]['documentacion'][0]}
                        data={
                            emisionResponse?.pasajeros.map(pasajero => {
                                return pasajero['documentacion'][0]
                            })
                        }
                    />
                </div>

                <div className="structure__center">
                    <button
                        type="button"
                        onClick={() => {
                            downloadDocumentation()
                        }}
                        color="primary"
                        className="button"
                        style={{width: '110px', height: '40px'}}
                    >
                        {!loading ? <p>Obtener</p> : <SmallLoading/>}
                    </button>
                </div>
            </Fragment>)
        } else if (emissionStatus === "Failed") {
            return (<Fragment>
                <div className="structure__margin--top ">
                    <p className="form__title">
                        Ocurrió un error al procesar el pedido
                    </p>
                </div>

                <div className="structure__margin--top">
                    <ul>
                        {emisionResponse?.errors.map((row) => (
                            <li className="form__text--light">
                                <div style={{margin: "8px 8px 0 0"}}>
                                    {row['detail'] === 'Internal Error' ? 'Hay un problema con el servicio, vuelva a intentarlo más tarde.' : row['detail']}
                                </div>

                            </li>
                        ))}
                    </ul>
                </div>

                <div className="structure__margin structure__center" style={{marginTop: "32px"}}>
                    <button
                        className="button"
                        onClick={() => {
                            setEmisionResponse(null)
                            setActiveStep(3)
                        }}
                    >
                        Volver al Formulario
                    </button>
                </div>
            </Fragment>)
        } else {
            return (
                <div className="structure__margin--topLG">
                    <p className="form__subtitle structure__center">
                        Hubo un problema al emitir. Por favor, vuelva a intentarlo más tarde
                    </p>
                </div>
            )
        }
    }

    return (
        <Fragment>
            {returnDOM()}
        </Fragment>
    );
}

export default EmisionSuccess;