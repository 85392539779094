import React from 'react';
import {useFormikContext} from 'formik';
import {TextInput} from '@mantine/core';
import {jsonFlatten} from "../../Utils/jsonFlatten";

const InputField = (props) => {
    const {label, name, format, ...rest} = props;
    const {values, errors, touched, setFieldValue, setFieldTouched} = useFormikContext()

    const handleErrorText = () => {
        if (jsonFlatten(errors)[name] && jsonFlatten(touched)[name]) {
            return jsonFlatten(errors)[name];
        }
    }

    const handleChange = (e) => {
        setFieldTouched(name, true)
        if (format === "UPPERCASE")
            setFieldValue(name, e.target.value.toString().toUpperCase())
        else
            setFieldValue(name, e.target.value)
    }

    return (
        <TextInput
            id={name}
            label={label}
            error={handleErrorText()}
            value={jsonFlatten(values)[name]}
            onChange={(e) => handleChange(e)}
            radius="md"
            size="md"
            classNames={{
                wrapper: 'mantine__field__wrapper',
                label: 'mantine__field__label',
                input: 'mantine__field__field',
                required: 'mantine__field__required',
                error: 'mantine__field__error',
                rightSection: 'mantine__field__rightSection',
            }}
            {...rest}
        />
    );
};

export default InputField;