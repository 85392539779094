import React, {useState} from 'react';
import {Popover} from '@mantine/core';
import infoIcon from '../assets/systemIcons/info.png';
import useWindowDimensions from "./Utils/useWindowDimensions";

function PopOverInfo(props) {
    const {width} = useWindowDimensions();
    const isMobile = width < 768;
    const [opened, setOpened] = useState(false);
    const {descripcion} = props;

    return (
        <Popover
            width={isMobile ? "80%" : "40%"}
            transition="slide-up"
            position="bottom"
            withArrow shadow="md"
            opened={opened}>
            <Popover.Target>
                <div className="popover">
                    <img
                        className="popover__image"
                        src={infoIcon}
                        alt={"i"}
                        onMouseEnter={() => setOpened(true)}
                        onMouseLeave={() => setOpened(false)}
                    />
                </div>
            </Popover.Target>
            <Popover.Dropdown>
                <p className="popover__text">{descripcion}</p>
            </Popover.Dropdown>
        </Popover>
    );
}

export default PopOverInfo;
