import React from 'react';
import { Carousel } from '@mantine/carousel';
import add from '../assets/systemIcons/add.png'
import minus from '../assets/systemIcons/minus.png'
import {currencyFormat} from "./Utils/currencyFormat";

function FranchiseSelector(props) {
    const {franchises, initial, setSelected} = props;
    let selected = initial;
    const formatValue = (_fullValue) => {
        if (typeof _fullValue === "string") {
            const percentRegex = /(\d+)%/;
            const moneyRegex = /\$\d+/;
            const match = _fullValue.match(percentRegex);
            const match2 = _fullValue.match(moneyRegex);
            if (match && match.length > 1) {
                const porcentaje = match[1];
                return (porcentaje + '%')
            }else if (match2 && match2.length > 0) {
                const plata = match2[0];
                return plata;
            }
            else return _fullValue;
        }
        else return `$ ${currencyFormat(_fullValue)}`;
    }

    return (
        <div className="franchise">
            <p className="franchise__text">Monto franquicia</p>

            <div className="franchise__selector">
                <Carousel slideSize="100%" sx={{ width: 200 }} height={24} slideGap="xl" controlsOffset="xs" draggable={false}
                          classNames={{
                              controls: 'carousel__controls',
                              control: 'carousel__control'
                          }}
                          nextControlIcon={<img className="carousel__icons" src={add}/>}
                          previousControlIcon={<img className="carousel__icons" src={minus}/>}
                          initialSlide={initial}
                          onNextSlide={() => {
                              if (selected < franchises.length - 1) {
                                  selected += 1
                                  setSelected(selected)
                              }
                          }}
                          onPreviousSlide={() => {
                              if (selected > 0) {
                                  selected -= 1
                                  setSelected(selected)
                              }
                          }}

                >
                    {franchises.map((franchise, idx) =>
                        <Carousel.Slide id={idx}><p className="franchise__number">{formatValue(franchise)}</p></Carousel.Slide>
                    )}
                </Carousel>
            </div>

        </div>
    );
}

export default FranchiseSelector;