import axios from "axios";

async function getLocalidadOptionsByZipCode(zipCode) {
    const url = process.env.REACT_APP_BACK_SERVER + '/getDatosLocalidades';
    try {
        const response = await axios.get(url, {params: {
                "codPostal": zipCode
            }});
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export {getLocalidadOptionsByZipCode}