import React, {forwardRef} from "react";
import {Input, TextInput} from '@mantine/core';
import {useFormikContext} from "formik";
import {jsonFlatten} from "../../Utils/jsonFlatten";

const InputRefField = (props, ref) => {
    const {name, label} = props;
    const {touched, errors} = useFormikContext()

    const handleErrorText = () => {
        if (jsonFlatten(errors)[name] && jsonFlatten(touched)[name]) {
            return jsonFlatten(errors)[name];
        }
    }

    return (
        <Input.Wrapper label={label}
                       required
                       styles={{
                           label: {marginLeft: "-48px", fontSize: "14px"},
                           root: {width: "100%"}
                       }}
                       classNames={{
                           label: 'mantine__field__label',
                           required: 'mantine__field__required',
                           error: 'mantine__field__error',
                       }}
        >
            <TextInput
                {...props}
                label={""}
                ref={ref}
                id={name}
                error={handleErrorText()}
                radius="md"
                size="md"
                classNames={{
                    wrapper: 'mantine__field__wrapper',
                    input: 'mantine__field__field',
                    rightSection: 'mantine__field__rightSection',
                }}
            />
        </Input.Wrapper>
    );
};

export default forwardRef(InputRefField);