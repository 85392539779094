import React, {useContext} from 'react';
import ClienteDirectoContext from "../context/ClienteDirectoContext";

const NavBar = () => {
    const {logo} = useContext(ClienteDirectoContext);
    return (
        <div className="navbar">
            <img className="navbar__logo" src={logo} alt="Logo_polkista"/>
            {/*Disabled temporarily*/}
            {/*<img className="navbar__logo neutralTheme__hide" src={sancorLogo} alt="Logo_aseguradora"/>*/}
        </div>
    );
}

export default NavBar;