import React, {useContext} from 'react';
import ClienteDirectoContext from "../context/ClienteDirectoContext";
import SocialMediaLinks from "./SocialMediaLinks";

function LandingBanner(props) {
    const {datosPolkista, description, redesSociales} = useContext(ClienteDirectoContext);

    return (
        <section className="banner">
            <div className="banner__nameblock">
                <p className="banner__title">{`${datosPolkista['Nombre']} ${datosPolkista['Apellido']}`}</p>
                <div className="structure__end">
                    <SocialMediaLinks links={redesSociales}/>
                </div>
            </div>
            <div className="banner__descriptionblock">
                <ul>
                    {description.map((descr, id) =>
                        <li className="banner__text" key={id}>{descr}</li>
                    )}
                </ul>
            </div>
        </section>
    );
}

export default LandingBanner;