import React from "react";
import {Loader} from "@mantine/core";

const SmallLoading = () => (
    <div className="spinner" style={{height: '0', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Loader color="gray" size="sm" />
    </div>
);

export default SmallLoading;
