import React, {Fragment, useContext, useEffect, useState} from "react";
import {Formik, Form} from "formik";

import {customerMotovehiculoValidation} from "../FormsModel/validationSchema";
import {customerMotovehiculoInitialValues} from "../FormsModel/formInitialValues";
import formModel from '../FormsModel/formModel';

import VehiculoForm from './Forms/VehiculoForm';
import EmisionForm from "./Forms/EmisionForm";
import TitularForm from "./Forms/TitularForm";
import SolicitudForm from "./Forms/SolicitudForm";
import Axios from "axios";
import ProgressBar from "../../ProgressBar";
import CotizacionSuccess from "./CotizacionSuccess";
import Loading from "../../Loading";
import ClienteDirectoContext from "../../../context/ClienteDirectoContext";
import {formatPhoneNumberIntl} from "react-phone-number-input";
import EmisionSuccess from "./EmisionSuccess";
import {ReactComponent as IconMotovehiculo} from '../../../assets/products/moto.svg';
import moment from "moment";
import {fillAdvancedConfigByCompany} from "./AuxFunctions/fillAdvancedConfigByCompany";
import fetchContextData from "../../../context/utils/fetchContextData";
import loadMotovehiculoData from "../../../context/products/MotovehiculoContext/loadMotovehiculoData";
import MotovehiculoContextProvider from "../../../context/products/MotovehiculoContext/MotovehiculoContextProvider";
import MotovehiculoContext from "../../../context/products/MotovehiculoContext/MotovehiculoContext";

const steps = ["Vehículo", "¡Elegí tu plan!", "Titular", "Póliza", "Resumen"];
const {formField} = formModel;

function _renderStepContent(step, cotizacionResponse, finalValues, modelos, setModelos, setActiveStep, setEmisionResponse, emisionResponse, currentProduct) {
    switch (step) {
        case 0:
            return <VehiculoForm formField={formField} modelos={modelos} setModelos={setModelos}/>
        case 1:
            return <div>
                {(cotizacionResponse !== null && finalValues !== null)
                    ?
                    <CotizacionSuccess finalValues={finalValues}
                                       currentProduct={currentProduct}
                                       cotizacionResponse={cotizacionResponse}/>
                    : (
                        <div style={{margin: "32px 16px"}}>
                            <p className="form__title">
                                Cotizando...
                            </p>
                            <Loading/>
                        </div>
                    )
                }
            </div>
        case 2:
            return <TitularForm formField={formField}/>
        case 3:
            return <EmisionForm formField={formField}/>
        case 4:
            return <SolicitudForm formField={formField} cotResponse={cotizacionResponse}/>
        case 5:
            return <div style={{margin: "32px 16px"}}>
                {emisionResponse !== null
                    ?
                    <EmisionSuccess emisionResponse={emisionResponse} setActiveStep={setActiveStep}
                                    setEmisionResponse={setEmisionResponse} finalValues={finalValues}/>
                    : (
                        <Fragment>
                            <p className="form__title">
                                Emitiendo...
                            </p>
                            <Loading/>
                            <p className="form__text--center">
                                Esto puede demorar hasta 60 segundos.
                            </p>
                        </Fragment>
                    )
                }
            </div>
        default:
            return <div>Not Found</div>;
    }
}

function CheckoutCotizar() {
    const [activeStep, setActiveStep] = useState(0);
    const currentValidationSchema = customerMotovehiculoValidation[activeStep];
    const [cotizacionResponse, setCotizacionResponse] = useState(null)
    const [emisionResponse, setEmisionResponse] = useState(null)
    const [finalValues, setFinalValues] = useState(null);
    const [modelos, setModelos] = useState([]);
    const {activeProducts, idRowPaginasPolkista, companiesDict} = useContext(ClienteDirectoContext);
    const currentProduct = 'motovehiculo'
    const {vehicleDetails} = useContext(MotovehiculoContext);

    async function _cotizarForm(values, actions) {

        let url = process.env.REACT_APP_BACK_SERVER;
        let jsonData = {
            "idCanalDigital": idRowPaginasPolkista,
        }

        url += '/newcotizar/motovehiculo';

        const companiesToQuote = activeProducts[currentProduct]['codigos_aseguradoras'].map((el) => el['id_aseguradora'])

        values.vehiculo = {
            ...values.vehiculo,
            ...vehicleDetails.current
        }


        jsonData = {
            ...jsonData,
            "motovehiculo": {
                ...values.vehiculo,
                "sumaAseguradaAdicional": values.valorAdicional,
                "esCeroKm": values.vehiculo.ceroKm,
            },
            "situacionImpositiva": values.situacionImpositiva,
            "tipoPersona": values.personaJuridica,
            "nombreLocalidad": values.nombreLocalidad,
            "companies": companiesToQuote,
            "fecha": moment().format('DD/MM/YYYY'),
            "idProvincia": values.idProvincia,
            "codigoPostal": values.codigoPostal,
            "codigosLocalidad": values.codigosLocalidad,
            "configuracionAvanzada": {
                ...await fillAdvancedConfigByCompany(
                    companiesToQuote,
                    values,
                    activeProducts[currentProduct]['codigos_aseguradoras'],
                    companiesToQuote.map(id => Object.keys(companiesDict).find(key => companiesDict[key].id === id)))
            },
            "cliente": {
                "nombre": values.cliente.nombre,
                "apellido": values.cliente.apellido,
                "telefono": values.cliente.telefono,
                "email": values.cliente.email
            },
            "saveToDatabase": true,
        }

        Axios.post(url, jsonData)
            .then((res) => {
                const formattedResponse = {
                    "idCotizar": res.data.idCotizar,
                    "vehicle": {
                        "year": values.vehiculo.anio,
                        "Marca": values.vehiculo.marca,
                    },
                    "planCD": res.data
                }
                setCotizacionResponse(formattedResponse)
                actions.setSubmitting(false);
                setActiveStep(activeStep + 1);
            }).catch(() => {
            setCotizacionResponse("Error");
        }).finally(() => {
            setFinalValues(jsonData)
            actions.setSubmitting(false);
        })
    }

    async function _emitirForm(values, actions) {
        let url = process.env.REACT_APP_BACK_SERVER;

        let _telefono = formatPhoneNumberIntl(values.cliente.telefono).split(" ")
        let codArea = _telefono[1]
        let telefono = `${_telefono[1]}${_telefono[2]}${_telefono[3]}`

        url += '/newemitir/motovehiculo';
        let premiumToSend = values.premium;
        if (values.compania === 'RUS') {
            premiumToSend = Math.ceil((values.premium * parseInt(values.parametrosAdicionales.cuotas)));
        }

        let jsonData = {
            "idCanalDigital": idRowPaginasPolkista,
            "sumaAseguradaAdicional": values.vehiculo.valorAdicional,
            "tipoPersona": values.personaJuridica,
            "situacionImpositiva": values.situacionImpositiva,
            "codigosLocalidad": values.codigosLocalidad,
            "nombreModelo": values.vehiculo.nombreModelo,
            "nombreLocalidad": values.nombreLocalidad,
            "codigoPostal": values.codigoPostal,
            "fecha": moment().format('YYYY-MM-DD'),
            "company": values.compania,
            "companyId": companiesDict[values.compania]['id'],
            "nombreCobertura": values.nombreCobertura,
            "vigenciaDesde": moment(values.vigenciaDesde).format('YYYY-MM-DD'),
            "vigenciaHasta": moment(values.vigenciaHasta).format('YYYY-MM-DD'),
            "formaDePago": values.formaDePago,
            "infoDePago": {
                "numeroCbu": values.infoDePago.numeroCbu,
                "marcaTarjeta": values.infoDePago.marcaTarjeta,
                "numeroTarjeta": values.infoDePago.numeroTarjeta,
            },
            "clientes": [
                {
                    "rol": values.cliente.rol,
                    "tipoPersona": values.cliente.personaJuridica,
                    "dni": values.cliente.dni,
                    "cuit": values.cliente.cuit,
                    "nombre": values.cliente.nombre,
                    "apellido": values.cliente.apellido,
                    "fechaNacimiento": moment(values.cliente.fechaNacimiento).format('YYYY-MM-DD'),
                    "sexo": values.cliente.sexo,
                    "calle": values.cliente.calle,
                    "numero": values.cliente.numero,
                    "opcional": values.cliente.opcional,
                    "codigoPostal": values.codigoPostal,
                    "codigosLocalidad": values.codigosLocalidad,
                    "email": values.cliente.email,
                    "situacionImpositiva": values.cliente.situacionIva,
                    "codigoArea": codArea,
                    "telefono": telefono,
                }
            ],
            "comentariosSolicitud": values.comentariosSolicitud,
            "vehiculo": {
                "marca": values.vehiculo.marca,
                "version": values.vehiculo.version,
                "anio": values.vehiculo.anio,
                "detalle_marca": values.vehiculo.detalle_marca,
                "detalle_version": values.vehiculo.detalle_version,
                "sumaAseguradaVehiculo": values.vehiculo.valor,
                "patente": values.vehiculo.patente,
                "motor": values.vehiculo.motor,
                "chasis": values.vehiculo.chasis,
                "esCeroKm": values.vehiculo.ceroKm,
                "bienesAdicionales": values.bienesAsegurados,
                "nombreModelo": values.vehiculo.nombreModelo,
                "inspeccionPrevia": {
                    "fechaInspeccion": moment(values.vehiculo.inspeccionPrevia.fechaInspeccion).format('YYYY-MM-DD'),
                    "comentariosInspeccion": values.vehiculo.inspeccionPrevia.comentariosInspeccion,
                    "archivos": [
                        ...values.vehiculo.inspeccionPrevia.archivos,
                    ],
                    "daniosPreexistentes": false //TODO: Add
                }
            },
            "idCotizacion": values.idCotizar,
            "premio": premiumToSend,
            "idCobertura": values.selectedCobertura,
            "parametrosAdicionales": {
                ...values.parametrosAdicionales
            },
            "saveToDatabase": true,
        }

        Axios.post(url, jsonData)
            .then(res => {
                setEmisionResponse(res.data)
            }).catch(() => {
            setEmisionResponse("Error")
        }).finally(() => {
            setFinalValues(values);
            actions.setSubmitting(false);
        });
    }

    function _handleSubmit(values, actions) {
        actions.setSubmitting(true);
        switch (activeStep) {
            case 0:
                setActiveStep(activeStep + 1);
                _cotizarForm(values, actions)
                break;
            case 4:
                setActiveStep(activeStep + 1);
                _emitirForm(values, actions);
                break;
            default:
                actions.setTouched({});
                actions.setSubmitting(false);
                setActiveStep(activeStep + 1);
                break;
        }
    }

    function _handleBack() {
        if (activeStep === 1) {
            setFinalValues(null)
        } else if (activeStep === 5) {
            setEmisionResponse(null)
        }
        setActiveStep(activeStep - 1);
    }

    const buttonText = () => {
        switch (activeStep) {
            case 0:
                return "Cotizar";
            case 4:
                return "Confirmar";
            default:
                return "Siguiente";
        }
    }

    return (
        <>
            <div className="form__circle__container">
                <div className="form__circle">
                    <IconMotovehiculo className="form__icon--design"/>
                </div>
            </div>

            <div style={{margin: "16px 0px"}}>
                <ProgressBar activeStep={activeStep} stepTitles={steps}/>
            </div>

            <Fragment>
                <Formik
                    initialValues={customerMotovehiculoInitialValues}
                    validationSchema={currentValidationSchema}
                    validateOnBlur={false}
                    onSubmit={_handleSubmit}
                >
                    {({isSubmitting}) => (
                        <Form id={"customerMotovehiculoForm"}>
                            {
                                _renderStepContent(
                                    activeStep,
                                    cotizacionResponse,
                                    finalValues,
                                    modelos,
                                    setModelos,
                                    setActiveStep,
                                    setEmisionResponse,
                                    emisionResponse,
                                    currentProduct)
                            }

                            <div
                                className="form__footer"
                                style={{display: activeStep > 4 && emisionResponse !== "Error" && "none"}}
                            >
                                {activeStep !== 0 && (emisionResponse === null || emisionResponse === "Error") && (
                                    <button
                                        type="button"
                                        onClick={_handleBack}
                                        className="button__outlined"
                                        disabled={isSubmitting}
                                    >
                                        Atras
                                    </button>
                                )}
                                {activeStep !== 1 && activeStep < 5 && (
                                    <button
                                        className="button"
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        {buttonText()}
                                    </button>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            </Fragment>
        </>
    );
}

function withMotovehiculoData(WrappedComponent) {
    return function WrappedWithMotovehiculoData(props) {
        const [loadingPage, setLoadingPage] = useState(true);
        const [errorPage, setErrorPage] = useState(null);
        const [motovehiculoData, setMotovehiculoData] = useState(null);
        const {idRowPaginasPolkista} = useContext(ClienteDirectoContext);

        useEffect(() => {
            fetchContextData([() => loadMotovehiculoData(idRowPaginasPolkista)], [setMotovehiculoData], setLoadingPage, ['CDMotovehiculoMarcas'])
                .then(errors => {
                        if (errors.length > 0) {
                            setErrorPage({
                                title: "Hubo un problema al cargar la información del usuario, por favor vuelva a intentarlo más tarde.",
                                typeError: "Ha ocurrido un error en la comunicación con el servicio",
                                suggestedActions: ["Reintentá el proceso una vez más", "Si el problema persiste ponete en contacto con el área de soporte"],
                                detailedError: errors[0].error.response?.data?.detail || "Sin datos del error",
                            })
                        }
                    }
                )
        }, []);

        if (loadingPage || errorPage) return <Loading/>

        return (
            <MotovehiculoContextProvider initialData={motovehiculoData}>
                <WrappedComponent {...props} />
            </MotovehiculoContextProvider>
        );
    };
}

export default withMotovehiculoData(CheckoutCotizar);