import axios from "axios";

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null
};

const handleDownloadDocs = (url, company, document, identificadores, documentName) => {
    let body = {
        "company": company,
        "documento": document,
    };
    identificadores.map(id => {
        body[id.key] = id.valor.toString()
    })

    let headers = {
        'Content-Type': 'application/json',
    };

    let config = {headers: headers, responseType: "blob"};

    return axios.post(url, body, config)
        .then(res => {
            let documentUrl = URL.createObjectURL(res.data);
            openInNewTab(documentUrl)
        }).catch(err => {
            console.error(err);
            alert("Ocurrió un error al descargar su documento, utilice sus códigos para consultarlos en la web de la aseguradora")
        });
};


export {handleDownloadDocs};