async function fetchContextData(loadFunctions, stateSetters, setLoading, storageKeys = null) {
    if (loadFunctions.length !== stateSetters.length) {
        throw new Error("La cantidad de funciones de carga debe ser igual a la cantidad de setters de estado.");
    }

    setLoading(true);

    const results = await Promise.allSettled(loadFunctions.map((fn, index) => {
        if (storageKeys && storageKeys[index] && sessionStorage.getItem(storageKeys[index])) {
            const storedValue = JSON.parse(sessionStorage.getItem(storageKeys[index]));
            return Promise.resolve(storedValue);
        }
        return fn();
    }));

    let errors = [];

    results.forEach((result, index) => {
        if (result.status === 'fulfilled') {
            stateSetters[index](result.value);
            if (storageKeys && storageKeys[index]) {
                sessionStorage.setItem(storageKeys[index], JSON.stringify(result.value));
            }
        } else {
            errors.push({index: index, error: result.reason});
            stateSetters[index](null);
        }
    });

    setLoading(false);

    return errors;
}

export default fetchContextData;