import React, {Fragment, useEffect, useState} from 'react';
import {Divider, Select} from '@mantine/core';
import SmallLoading from "../../SmallLoading";
import {useFormikContext} from "formik";
import {handleDownloadDocs} from "../../Utils/handleDownloadDocs";

function EmisionSuccess(props) {
    const {emisionResponse, setActiveStep, setEmisionResponse, finalValues} = props;
    const {values} = useFormikContext();
    const [selectedDocument, setSelectedDocument] = useState("");

    const [loading, setLoading] = useState(false);

    const downloadDocumentation = async () => {
        setLoading(true)

        let url = process.env.REACT_APP_QUOTATION_MICROSERVICE + '/documentacion/auto'
        try {
            await handleDownloadDocs (
                url,
                finalValues.compania,
                selectedDocument,
                emisionResponse['identificadores'],
                "documentacion.pdf"
            );
        } catch (error) {
            alert("Ocurrió un error al descargar su documento, utilice sus códigos para consultarlos en la web de la aseguradora")
        }

        setLoading(false)
    }

    useEffect(() => {
        if (emisionResponse && emisionResponse['documentacion']) {
            setSelectedDocument(emisionResponse['documentacion'][0])
        }
    }, []);

    const returnDOM = () => {
        if (emisionResponse['status'] !== undefined && emisionResponse['status'] !== "Failed") {
            return (
                <Fragment>
                    <div className="structure__column">
                        <p className="form__title">¡FELICITACIONES!</p>
                        <p className="form__subtitle">Ahora tus pertenencias están aseguradas.</p>

                        <p className="form__text--center structure__margin--top">
                            Toda la información correspondiente a tu póliza y de tu nuevo Productor Asesor
                            de
                            Seguros será enviada a tu email {values.cliente.email}.
                            <br/><br/>
                            ¡También podés descargar tu documentación a continuación!
                        </p>
                    </div>

                    <Divider my="sm" size="xs"/>

                    {
                        emisionResponse['identificadores'].filter(v => v.key !== "certificateNumber").map(id => (
                            <div className="structure__margin--top--md">
                                <p className="form__text--primary">{id.nombre}</p>
                                <p className="form__text--center">{id.valor}</p>
                            </div>
                        ))
                    }

                    <Divider my="sm" size="xs"/>

                    <p className="form__text--primary">Documentación</p>

                    <div className="structure__margin--top">
                        <Select
                            placeholder="Seleccione el documento a descargar"
                            onChange={(e) => setSelectedDocument(e)}
                            defaultValue={emisionResponse['documentacion'][0]}
                            data={
                                emisionResponse['documentacion'].map(doc => {
                                    return {value: doc, label: doc}
                                })
                            }
                        />
                    </div>

                    <div className="structure__center">
                        <button
                            type="button"
                            onClick={() => {
                                downloadDocumentation()
                            }}
                            color="primary"
                            className="button"
                            style={{width: '110px', height: '40px'}}
                        >
                            {!loading ? <p>Obtener</p> : <SmallLoading/>}
                        </button>
                    </div>

                    <div className="structure__margin">
                        {emisionResponse['status'] === "Pending" && (
                            <p className="form__text--center">
                                El certificado de cobertura provisoria es un documento válido para demostrar
                                la
                                cobertura de su hogar.
                                En el transcurso de 5 días hábiles estará disponible el resto de la
                                documentación.
                                En caso de que exista alguna irregularidad, un asesor se pondrá en
                                contacto.
                            </p>
                        )}
                    </div>
                </Fragment>
            )
        } else if (emisionResponse['status'] === "Failed") {
            return (
                <Fragment>
                    <div className="structure__margin--top ">
                        <p className="form__title">
                            Ocurrió un error al procesar el pedido
                        </p>
                    </div>

                    <div className="structure__margin--top">
                        <ul>
                            {emisionResponse?.errors.map((row) => (
                                <li className="form__text--light">
                                    <div style={{margin: "8px 8px 0 0"}}>
                                        {row['detail'] === 'Internal Error' ? 'Hay un problema con el servicio, vuelva a intentarlo más tarde.' : row['detail']}
                                    </div>

                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="structure__margin structure__center">
                        <button
                            className="button"
                            onClick={() => {
                                setEmisionResponse(null)
                                setActiveStep(3)
                            }}
                        >
                            Volver al Formulario
                        </button>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <div className="structure__margin--topLG">
                    <p className="form__subtitle structure__center">
                        Hubo un problema al emitir. Por favor, vuelva a intentarlo más tarde
                    </p>
                </div>
            )
        }
    }

    return (
        <Fragment>
            {returnDOM()}
        </Fragment>
    );
}

export default EmisionSuccess;
