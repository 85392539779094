import React, {useEffect, useState} from 'react';
import {NumberInput} from '@mantine/core';
import {useField} from "formik";

function MantineNumberField(props) {
    const {label, name, min = 0, notFormat, rightSection, onChangeAction} = props;
    const [field, meta, helpers] = useField(name);
    const parsedValue = parseInt(field.value);
    const initialValue = isNaN(parsedValue) ? '' : parsedValue;
    const [value, setValue] = useState(initialValue);
    const {setValue: setFieldValue, setTouched} = helpers;

    useEffect(() => {
        const updatedParsedValue = parseInt(field.value);
        const updatedValue = isNaN(updatedParsedValue) ? '' : updatedParsedValue;
        setValue(updatedValue);
    }, [field.value]);

    return (
        <NumberInput
            {...props}
            hideControls
            label={label}
            value={value}
            onChange={(e) => {
                setTouched(name, true)
                if (e === "") {
                    setFieldValue(min)
                    setValue(min)
                } else {
                    setFieldValue(e)
                    setValue(e)
                }
                if (onChangeAction) {
                    onChangeAction(e)
                }
            }
            }
            parser={(v) => v.replace(/\$\s?|(\.*)/g, '')}
            formatter={(v) => {
                if (notFormat) {
                    return v
                }
                return !Number.isNaN(parseFloat(v))
                    ? `$ ${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                    : '$ '
            }
            }
            radius="md"
            size="md"
            error={meta.error && meta.touched && <div>{meta.error}</div>}
            classNames={{
                wrapper: 'mantine__field__wrapper',
                label: meta.error && meta.touched ? 'mantine__field__label--error' : 'mantine__field__label',
                input: meta.error && meta.touched ? 'mantine__field__field--error' : 'mantine__field__field',
                required: meta.error && meta.touched ? 'mantine__field__required--error' : 'mantine__field__required',
                error: 'mantine__field__error',
                rightSection: 'mantine__field__rightSection',
            }}
            rightSectionWidth={rightSection ? "auto" : 36}
        />
    );
}

export default MantineNumberField;