import React, {useContext} from 'react';
import {ErrorMessage, FieldArray, useFormikContext} from 'formik'
import ClienteDirectoContext from "../../../../context/ClienteDirectoContext";
import PopOverInfo from "../../../PopOverInfo";
import InputField from "../../FormFields/InputField";
import NumberField from "../../FormFields/NumberField";
import add from '../../../../assets/systemIcons/add.png';
import minus from '../../../../assets/systemIcons/minus.png';
import {currencyFormat} from "../../../Utils/currencyFormat";
import useWindowDimensions from "../../../Utils/useWindowDimensions";

export default function SolicitudForm(props) {
    const {width} = useWindowDimensions();
    const isMobile = width < 768;
    const {
        formField: {
            detalleEquipoElectronico: {
                descripcionElectronico,
                sumaAseguradaElectronico,
                codigoElectronico,
                codigoGrupoElectronico
            },
            detalleBicicletas: {
                descripcionBicicletas,
                sumaAseguradaBicicletas,
                codigoBicicletas,
                codigoGrupoBicicletas
            },
        },
        cotResponse
    } = props;

    const {
        coberturasInfo
    } = useContext(ClienteDirectoContext);

    const {values, errors} = useFormikContext();

    const equipoElectronicoVacio = {
        [descripcionElectronico.name]: '',
        [sumaAseguradaElectronico.name]: '',
        [codigoElectronico.name]: 201,
        [codigoGrupoElectronico.name]: 34,
    };
    const bicicletasVacio = {
        [descripcionBicicletas.name]: '',
        [sumaAseguradaBicicletas.name]: '',
        [codigoBicicletas.name]: 209,
        [codigoGrupoBicicletas.name]: 31,
    };

    const checkError = (prod) => {
        let prodName;
        if (prod === 1) prodName = "detalleBicicletas"
        if (prod === 3) prodName = "detalleEquipoElectronico"

        if (errors[prodName] !== undefined) {
            return (errors[prodName].includes("Las sumas declaradas deben coincidir con las aseguradas"))
        } else return false
    }

    const buttonsToShow = (index, length, arrayHelpers, emptyArray) => {
        if (length === 1) {
            return (
                <div className="form__item__icon">
                    <button className="button__squared" type="button"
                            onClick={() => {
                                arrayHelpers.push(emptyArray)
                            }}>
                        <img src={add} width={16} height={16} alt={"+"}/>
                    </button>
                </div>)
        } else if (index !== length - 1) {
            return (<div className="form__item__icon">
                <button className="button__squared" type="button"
                        onClick={() => arrayHelpers.remove(index)}>
                    <img src={minus} width={16} height={16} alt={"-"}/>
                </button>
            </div>)
        } else {
            return (
                <div className="form__item__icon">
                    <button className="button__squared--half" type="button"
                            onClick={() => arrayHelpers.remove(index)}>
                        <img src={minus} width={8} height={8} alt={"-"}/>
                    </button>
                    <button className="button__squared--half" type="button"
                            onClick={() => arrayHelpers.push(emptyArray)}>
                        <img src={add} width={8} height={8} alt={"+"}/>
                    </button>
                </div>
            )
        }
    }

    return (
        <React.Fragment>
            <div className="form__header">
                <p className="form__title">
                    Cobertura
                </p>
                <p className="form__title">
                    Suma Asegurada
                </p>
            </div>
            {Object.keys(values.coberturasAdicionales).map(cobertura => {
                if (values.coberturasAdicionales[cobertura].selectedCobertura) {
                    return (
                        <>
                            <div className="form__item--solicitud" key={cobertura}>
                                <p className="form__text">{coberturasInfo[cobertura].descripcion}</p>

                                <p className="form__text--right">$ {currencyFormat(values.coberturasAdicionales[cobertura].sumaAsegurada)}</p>

                                <div className="structure__center">
                                    <PopOverInfo descripcion={coberturasInfo[cobertura].descripcionCompleta}/>
                                </div>
                            </div>
                            {cobertura === "48" &&
                            <>
                                <p className="form__text--center" style={{margin: "0 16px"}}>
                                    Detallá marca, modelo, rodado y número de cuadro de tu bicicleta:
                                </p>

                                <div>
                                    <FieldArray name='detalleBicicletas'>{(arrayHelpers) => (
                                        values.detalleBicicletas.map((item, index) => (
                                            <div className="form__item--solicitud">
                                                <InputField
                                                    name={`detalleBicicletas.[${index}].${descripcionBicicletas.name}`}
                                                    size={isMobile ? "xs" : "sm"}
                                                    placeholder="Descripción"
                                                />
                                                <NumberField
                                                    name={`detalleBicicletas.${index}.${sumaAseguradaBicicletas.name}`}
                                                    searchName={`detalleBicicletas.[${index}].${sumaAseguradaBicicletas.name}`}
                                                    size={isMobile ? "xs" : "sm"}
                                                />
                                                {buttonsToShow(index, values.detalleBicicletas.length, arrayHelpers, bicicletasVacio)}
                                            </div>

                                        ))
                                    )}</FieldArray>
                                    {checkError(1)
                                    &&
                                    <div className="structure__margin--top">
                                        <ErrorMessage name='detalleBicicletas'
                                                      render={msg => <div
                                                          className="errorMessage"
                                                      >{msg}</div>}/>
                                        <p className="errorMessage"> ($ {values['coberturasAdicionales']['48'].sumaAsegurada})</p>
                                    </div>}
                                </div>
                            </>
                            }
                            {cobertura === "26" &&
                            <>
                                <p className="form__text--center" style={{margin: "0 16px"}}>
                                    Declaración de objetos asegurados bajo la cobertura "Robo de objetos varios":
                                </p>

                                <div>
                                    <FieldArray name='detalleEquipoElectronico'>{(arrayHelpers) => (
                                        values.detalleEquipoElectronico.map((item, index) => (
                                            <div className="form__item--solicitud">
                                                <InputField
                                                    name={`detalleEquipoElectronico.[${index}].${descripcionElectronico.name}`}
                                                    size={isMobile ? "xs" : "sm"}
                                                    placeholder="Descripción"
                                                />
                                                <NumberField
                                                    name={`detalleEquipoElectronico.${index}.${sumaAseguradaElectronico.name}`}
                                                    searchName={`detalleEquipoElectronico.[${index}].${sumaAseguradaElectronico.name}`}
                                                    size={isMobile ? "xs" : "sm"}
                                                />
                                                {buttonsToShow(index, values.detalleEquipoElectronico.length, arrayHelpers, equipoElectronicoVacio)}
                                            </div>

                                        ))
                                    )}</FieldArray>
                                    {checkError(3)
                                    &&
                                    <div className="structure__margin--top">
                                        <ErrorMessage name='detalleEquipoElectronico'
                                                      render={msg => <div
                                                          className="errorMessage"
                                                      >{msg}</div>}/>
                                        <p className="errorMessage"
                                        > ($ {values['coberturasAdicionales']['26'].sumaAsegurada})</p>
                                    </div>}
                                </div>
                            </>
                            }
                        </>

                    )
                }
            })}
            <div className="form__price">
                <p className="form__subtitle--white">Cuota mensual:</p>
                <p className="form__title--white">$ {currencyFormat(cotResponse.premioMensual.toFixed(0))}</p>
            </div>
        </React.Fragment>
    );
}
