import React from 'react';
import './PopUpCard.css'
import useWindowDimensions from "../Utils/useWindowDimensions";

function PopUpCard(props) {
    const {fullSpace, children} = props
    const {width} = useWindowDimensions();
    return (
        <div className={fullSpace && width < 480 ? "popupCard__container__fullSpace" : "popupCard__container"}>
            {children}
        </div>
    );
}

export default PopUpCard;