const fillCoberturasValues = (values) => {
    let coberturasSend = []

    for (let cobertura in values.coberturas) {
        coberturasSend.push({
            numeroCobertura: cobertura,
            sumaAsegurada: values.coberturas[cobertura].sumaAsegurada
        })
    }

    for (let coberturaAd in values.coberturasAdicionales) {
        if (values.coberturasAdicionales[coberturaAd].selectedCobertura)
            coberturasSend.push({
                numeroCobertura: coberturaAd,
                sumaAsegurada: values.coberturasAdicionales[coberturaAd].sumaAsegurada
            })
    }

    return coberturasSend;
}

export {fillCoberturasValues};