import React, {useContext, useMemo, useState} from 'react';
import {useFormikContext} from "formik";
import {format} from "date-fns";
import ClienteDirectoContext from "../../../../../../context/ClienteDirectoContext";
import GenericCoberturaCard from "../../../../../GenericCoberturaCard";
import {ReactComponent as PassengerIcon} from "../../../../../../assets/systemIcons/ic_user.svg";
import "./CotizarSuccessView.css";
import { CarouselWrapper } from '../../../../../CarouselWrapper';

const carouselCardsBuilder = (_cards, _type, _finalValues, _currentProduct) => {
    return (
        <CarouselWrapper slideSize="auto" withControls slideGap="xl"
            slidesToScroll={1}
            initialSlide={0}
            widthIndicators={false}
            withDots={false}
            draggable >
            {_cards.map(_cardInfo => {
                let cardInfo = _cardInfo
                let description = ''
                cardInfo.displayPremium = cardInfo.premium;

                return (<GenericCoberturaCard
                    finalValues={_finalValues}
                    currentProduct={_currentProduct}
                    cardInfo={{ ...cardInfo, "description": description, "compania": "TerraWind", moreDetails: _cardInfo.benefits, nombre: _cardInfo.name }} />)
            })}
        </CarouselWrapper>
    )
}

export default function CotizacionSuccessView(props) {
    const {finalValues, cotizacionResponse, currentProduct} = props;
    const {destinos} = useContext(ClienteDirectoContext);
    const {values} = useFormikContext();
    const formattedVigenciaDesde = useMemo(() => format(values.vigenciaDesde, 'dd/MM/yyyy'), [values.vigenciaDesde])
    const formattedVigenciaHasta = useMemo(() => format(values.vigenciaHasta, 'dd/MM/yyyy'), [values.vigenciaHasta])
    const passengersNumber = values.pasajeros.length


    return (
        <React.Fragment>
            {cotizacionResponse === "Error"
                ?
                <div className="structure__margin--topLG">
                    <p className="form__subtitle structure__center">
                        Hubo un problema al cotizar. Por favor, vuelva a intentarlo más tarde
                    </p>
                </div>
                :
                <>
                    <div className="structure__margin--top asistenciaViajero__cotSuccess__titleContainer">
                        <p className="form__text--dark">
                            {values.nombrePais}
                        </p>
                        <p className="form__text--dark" style={{textAlign: "center"}}>
                            {formattedVigenciaDesde} - {formattedVigenciaHasta}
                        </p>
                        <div className="asistenciaViajero__cotSuccess__passengerContainer">
                            <PassengerIcon className="asistenciaViajero__cotSuccess__passengerIcon"/>
                            <p>
                                {passengersNumber}
                            </p>
                        </div>
                    </div>

                    <div className="tabs__margin">
                        {carouselCardsBuilder(cotizacionResponse?.productos, "", finalValues, currentProduct)}
                    </div>
                </>
            }
        </React.Fragment>
    );
}