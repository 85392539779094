const fillCoberturasValues = (values, coberturasInfo) => {
    let coberturasSend = [
        {
            numeroCobertura: '1',
            sumaAsegurada: coberturasInfo['1'].sumaChica,
        },
        {
            numeroCobertura: '2',
            sumaAsegurada: coberturasInfo['2'].sumaChica,
        },
    ]

    for (let coberturaAd in values.coberturasAdicionales) {
        if (values.coberturasAdicionales[coberturaAd].selectedCobertura)
            coberturasSend.push({
                numeroCobertura: coberturaAd,
                sumaAsegurada: values.coberturasAdicionales[coberturaAd].sumaAsegurada
            })
    }

    return coberturasSend;
}

export {fillCoberturasValues};
