import React from 'react';
import {Loader} from "@mantine/core";

function LoadingData() {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Loader size="lg" color="gray"/>
        </div>
    );
}

export default LoadingData;