import React from 'react';

const MotovehiculoContext = React.createContext({
    marcas: null,
    anios: null,
    modelos: null,
    versiones: null,
    vehicleDetails: null,
    updateVersions: null,
    isLoadingModels: null,
    isLoadingVersions: null,
    modelsError: null,
    versionsError: null
});

export default MotovehiculoContext;