export default function getValueByPath(obj, path) {
    const pathParts = path.split('.');

    return pathParts.reduce((acc, part) => {
        if (!acc) return undefined;

        const isArrayAccess = part.match(/.*\[[0-9]*\]/);

        if (isArrayAccess) {
            const field = isArrayAccess[0].split('[');
            const fieldName = field[0];
            const index = parseInt(field[1].slice(0, -1));
            return acc[fieldName][index];
        } else {
            return acc[part];
        }
    }, obj);
}