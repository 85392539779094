import {Checkbox} from "@mantine/core";

function MantineCheckbox(props) {
    const {checkedState, onChange, label, size = "xs"} = props;

    return (
        <Checkbox
            checked={checkedState}
            onChange={onChange}
            label={label}
            classNames={{
                input: 'checkbox',
            }}
            size={size}
        />
    );
}

export default MantineCheckbox;