import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {Formik, Form} from 'formik';

import InformacionForm from './Forms/InformacionForm';
import PolizaForm from "./Forms/PolizaForm";
import SolicitudForm from './Forms/SolicitudForm';
import ClienteForm from './Forms/ClienteForm';

import {customerMicrosegurosValidation} from "../FormsModel/validationSchema";
import {customerMicrosegurosInitialValues} from "../FormsModel/formInitialValues";
import formModel from '../FormsModel/formModel';

import CotizacionSuccess from "./CotizacionSuccess";
import EmisionSuccess from "./EmisionSuccess";
import axios from 'axios';
import Loading from "../../Loading";
import ClienteDirectoContext from "../../../context/ClienteDirectoContext";
import moment from "moment";
import {formatPhoneNumberIntl} from "react-phone-number-input";
import { ReactComponent as IconLock } from '../../../assets/products/candado.svg';
import { ReactComponent as IconBike } from '../../../assets/products/bici.svg';
import { ReactComponent as IconDeviceLaptop } from '../../../assets/products/notebook.svg';
import { ReactComponent as IconDeviceTablet } from '../../../assets/products/tablet.svg';
import ProgressBar from "../../ProgressBar";
import {fillAdvancedConfigByCompany} from "./AuxFunctions/fillAdvancedConfigByCompany";
import {fillCoberturasValues} from  "./AuxFunctions/fillCoberturasValues";
import {newFillEquipoElectronicoValues} from "./AuxFunctions/newFillEquipoElectronicoValues";
import { buildLocationCode } from '../../Utils/buildLocationCode';

const steps = ['Información', 'Resumen', 'Cliente', 'Póliza', 'Solicitud'];
const {formField} = formModel;

const coberturasAdicionalesIcons = {
    26: <IconLock className="form__icon--design"/>,
    48: <IconBike className="form__icon--design" style={{marginTop: "4px"}}/>,
    37: <IconDeviceTablet className="form__icon--design" style={{marginTop: "6px"}}/>,
    36: <IconDeviceLaptop className="form__icon--design" style={{marginTop: "6px"}}/>,
}

function _renderStepContent(step, cotizacionResponse, finalValues, emisionResponse, setEmisionResponse, setActiveStep) {
    switch (step) {
        case 0:
            return <InformacionForm formField={formField}/>
        case 1:
            return <div>
                {(cotizacionResponse !== null && finalValues !== null)
                    ?
                    <CotizacionSuccess finalValues={finalValues}
                                       cotizacionResponse={cotizacionResponse}/>
                    : (
                        <div style={{margin: "32px 16px"}}>
                            <p className="form__title">
                                Cotizando...
                            </p>
                            <Loading/>
                        </div>
                    )
                }
            </div>
        case 2:
            return <ClienteForm formField={formField}/>
        case 3:
            return <PolizaForm formField={formField}/>
        case 4:
            return <SolicitudForm formField={formField} cotResponse={cotizacionResponse}/>
        case 5:
            return (
                <Fragment>
                    <div style={{margin: "16px"}}>
                        {(emisionResponse !== null && finalValues !== null)
                            ? <EmisionSuccess emisionResponse={emisionResponse} setEmisionResponse={setEmisionResponse}
                                              setActiveStep={setActiveStep} finalValues={finalValues}/>
                            : (
                                <Fragment>
                                    <p className="form__title">
                                        Procesando el pedido...
                                    </p>
                                    <Loading/>
                                    <p className="form__text--center">
                                        Esto puede demorar hasta 60 segundos.
                                    </p>
                                </Fragment>
                            )
                        }
                    </div>
                </Fragment>
            )
        default:
            return <div>Not Found</div>;
    }
}

export default function CheckoutPage(props) {
    const {pickedCobertura} = props;
    const [activeStep, setActiveStep] = useState(0);
    let formikRef = useRef();
    const currentValidationSchema = customerMicrosegurosValidation[activeStep];
    const [cotizacionResponse, setCotizacionResponse] = useState(null);
    const [emisionResponse, setEmisionResponse] = useState(null);
    const [finalValues, setFinalValues] = useState(null);
    const {activeProducts, idRowPaginasPolkista, coberturasInfo, companiesDict} = useContext(ClienteDirectoContext);
    const currentProduct = 'microseguros'

    let microsegurosInitialValues = {
        ...customerMicrosegurosInitialValues,
        coberturasAdicionales: {
            ...customerMicrosegurosInitialValues['coberturasAdicionales'],
            [pickedCobertura]: {
                selectedCobertura: true,
                sumaAsegurada: coberturasInfo[pickedCobertura].sumaChica
            }
        }
    }

    async function _cotizarForm(values, actions) {

        let jsonData = {
            "idCanalDigital": idRowPaginasPolkista,
        }
        let url = process.env.REACT_APP_BACK_SERVER;

        url += '/newcotizar/microseguros';
        const companiesToQuote = activeProducts[currentProduct]['codigos_aseguradoras'].map((el) => el['id_aseguradora'])
        const companiesNames = companiesToQuote.map(company => Object.keys(companiesDict).find(key => companiesDict[key].id === company));
        const locationCodes = await buildLocationCode(companiesNames, values.codigosLocalidad);

        //TODO: replace hardcoded key (0) with dynamic value
        const companyCodesObj = activeProducts[currentProduct]['codigos_aseguradoras']
            .find(item => item.id_aseguradora === companiesToQuote[0]);
        const discount = companyCodesObj['descuentos']['general']

        const advancedConfig = await fillAdvancedConfigByCompany(companiesToQuote, values, activeProducts[currentProduct]['codigos_aseguradoras'])

        jsonData = {
            ...jsonData,
            "coberturas": [
                {
                    numeroCobertura: '1',
                    sumaAsegurada: coberturasInfo['1'].sumaMinima,
                },
                {
                    numeroCobertura: '2',
                    sumaAsegurada: coberturasInfo['2'].sumaMinima,
                },
            ],
            "coberturasAdicionales": Object.keys(values.coberturasAdicionales).map((cobertura) => {
                if (values.coberturasAdicionales[cobertura].selectedCobertura) {
                    return ({
                        numeroCobertura: cobertura,
                        sumaAsegurada: values.coberturasAdicionales[cobertura].sumaAsegurada
                    })
                }
            }).filter((cobertura) => cobertura !== undefined),
            // "companies": companiesToQuote, //TODO: Replace name
            "company": companiesToQuote,
            "parametrosAdicionales": {
                ...advancedConfig
            },
            "nombreLocalidad": values.nombreLocalidad,
            "origen": "POLKO",
            "tamanioVivienda": values.tamanioVivienda,
            "tipoVivienda": values.tipoVivienda,
            "codigosLocalidad": values.codigosLocalidad,
            "codigosLocalidades": locationCodes,
            "idProvincia": values.idProvincia,
            "codigoPostal": values.codigoPostal,
            //TODO: In order to make the producto multi-company,
            // use configuracionAvanzada instead of parametrosAdicionales
            "configuracionAvanzada": {
                "Sancor": {
                    "descuento": discount > 0 ? discount - 5 : 0,
                    "codSancorLocalidad": advancedConfig.codSancorLocalidad
                }
            },
            "operacionCliente": true,
            "cliente": {
                "nombre": values.cliente.nombre,
                "apellido": values.cliente.apellido,
                "telefono": values.cliente.telefono,
                "email": values.cliente.email
            },
        }

        axios.post(url, {...jsonData, ...{saveToDatabase: true}}).then(res => {
            setFinalValues(jsonData);

            setCotizacionResponse({
                ...res.data.Sancor, //Today only Sancor is used
                "idCotizar": res.data.idCotizar
            });

            actions.setSubmitting(false);
        }).catch(() => {
            setFinalValues(jsonData);
            setCotizacionResponse("Error");
            actions.setSubmitting(false);
        });
    }

    async function _emitirForm(values, actions) {
        let url = process.env.REACT_APP_BACK_SERVER;

        let coberturasValues = fillCoberturasValues(values, coberturasInfo);

        let jsonData = {
            "idCanalDigital": idRowPaginasPolkista,
            "operacionCliente": true,
            "premium": cotizacionResponse.premioMensual,
            "coberturas": coberturasValues,
            "vigenciaDesde": moment(values.vigenciaDesde).format('YYYY-MM-DD'),
            "vigenciaHasta": moment(values.vigenciaHasta).format('YYYY-MM-DD'),
        }

        url += '/newemitir/microseguros';
        const equiposElectronicos = newFillEquipoElectronicoValues(values)
        const companiesToQuote = activeProducts[currentProduct]['codigos_aseguradoras'].map((el) => el['id_aseguradora'])
        const companiesNames = companiesToQuote.map(company => Object.keys(companiesDict).find(key => companiesDict[key].id === company));
        const locationCodes = await buildLocationCode(companiesNames, values.codigosLocalidad);

        let _telefono = formatPhoneNumberIntl(values.cliente.telefono).split(" ")
        let codArea = _telefono[1]
        let telefono = `${_telefono[1]}${_telefono[2]}${_telefono[3]}`

        jsonData = {
            ...jsonData,
            "company": "Sancor",
            "idCotizacion": cotizacionResponse.idCotizar,
            "tipoVivienda": values.tipoVivienda,
            "tamanioVivienda": values.tamanioVivienda,
            "formaDePago": values.formaDePago,
            "infoDePago": {
                ...values.infoDePago,
            },
            "clientes": [
                {
                    "rol": values.cliente.rol,
                    "tipoPersona": values.cliente.personaJuridica,
                    "cuit": values.cliente.cuit,
                    "nombre": values.cliente.nombre,
                    "apellido": values.cliente.apellido,
                    "sexo": values.cliente.sexo,
                    "calle": values.cliente.calle,
                    "numero": values.cliente.numero,
                    "opcional": values.cliente.opcional,
                    "email": values.cliente.email,
                    "situacionImpositiva": values.cliente.situacionIva,
                    "dni": values.cliente.dni.toString(),
                    "codigoPostal": values.codigoPostal,
                    "codigosLocalidad": values.codigosLocalidad,
                    "fechaNacimiento": moment(values.cliente.fechaNacimiento).format('YYYY-MM-DD'),
                    "codigoArea": codArea,
                    "telefono": telefono,
                }
            ],
            "parametrosAdicionales": {
                ...fillAdvancedConfigByCompany(companiesToQuote, values, activeProducts[currentProduct]['codigos_aseguradoras']),
                "codSancorLocalidad": locationCodes['CodigoLocalidadSancor'],
                "descuento": values.descuentoEspecial
            },
            "comentariosSolicitud": "",
            ...(equiposElectronicos ? { "detallesAdicionales": {...equiposElectronicos}} : {}),
        }

        setFinalValues(jsonData);

        axios.post(url, jsonData).then(res => {
            setEmisionResponse(res.data)
            actions.setSubmitting(false);
        }).catch(() => {
            setEmisionResponse("Error")
            actions.setSubmitting(false);
        });
    }

    function _handleSubmit(values, actions) {
        actions.setSubmitting(true);
        // scrollIntoView()
        switch (activeStep) {
            case 0:
                setActiveStep(activeStep + 1);
                _cotizarForm(values, actions);
                break;
            case 4:
                setActiveStep(activeStep + 1);
                _emitirForm(values, actions);
                break;
            default:
                actions.setTouched({});
                actions.setSubmitting(false);
                setActiveStep(activeStep + 1);
                break;
        }
    }

    function _handleBack() {
        if (activeStep === 1) {
            if (cotizacionResponse === "Error")
                setCotizacionResponse(null)
            setFinalValues(null)
        } else if (activeStep === 5) {
            setEmisionResponse(null)
        }
        setActiveStep(activeStep - 1);
    }

    const buttonText = (currentStep) => {
        let text;
        switch (currentStep) {
            case 0:
                text = 'Cotizar';
                break;
            case 1:
                text = 'Contratar';
                break;
            case 4:
                text = 'Confirmar';
                break;
            default:
                text = 'Siguiente';
                break;
        }
        return text;
    }

    useEffect(() => {
        microsegurosInitialValues = {
            ...customerMicrosegurosInitialValues,
            coberturasAdicionales: {
                ...customerMicrosegurosInitialValues['coberturasAdicionales'],
                [pickedCobertura]: {
                    selectedCobertura: true,
                    sumaAsegurada: coberturasInfo[pickedCobertura].sumaChica
                }
            }
        }
        formikRef.current.setValues(microsegurosInitialValues)
    }, [pickedCobertura]);

    return (
        <React.Fragment>
            <div className="form__content">
                <div className="form__circle__container">
                    <div className="form__circle">
                        {coberturasAdicionalesIcons[pickedCobertura]}
                    </div>
                </div>

                <div style={{margin: "16px 0px"}}>
                    <ProgressBar activeStep={activeStep} stepTitles={steps}/>
                </div>
                <Fragment>

                    <Formik
                        initialValues={microsegurosInitialValues}
                        validationSchema={currentValidationSchema}
                        validateOnBlur={false}
                        innerRef={formikRef}
                        onSubmit={_handleSubmit}
                    >
                        {({isSubmitting}) => (
                            <Form id={"customerMicrosegurosForm"}>
                                {_renderStepContent(activeStep, cotizacionResponse, finalValues, emisionResponse, setEmisionResponse, setActiveStep)}
                                <div
                                    className="form__footer"
                                    style={{display: activeStep > 4 && emisionResponse !== "Error" && "none"}}
                                >
                                    {activeStep !== 0 && (emisionResponse === null || emisionResponse === "Error") &&(
                                        <button
                                            type="button"
                                            onClick={_handleBack}
                                            className="button__outlined"
                                            disabled={isSubmitting}
                                        >
                                            Atras
                                        </button>
                                    )}
                                    {cotizacionResponse !== "Error" && activeStep < 5 &&
                                        <button
                                            className="button"
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            {buttonText(activeStep)}
                                        </button>
                                    }
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Fragment>
            </div>
        </React.Fragment>
    );
}
