export default {
    formField: {
        frecuenciaPago: {
            name: 'frecuenciaPago',
            label: 'Frecuencia de pago',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        cuotas: {
            name: 'cuotas',
            label: 'Cantidad de cuotas',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        ajusteAutomatico: {
            name: 'ajusteAutomatico',
            label: 'Ajuste automático',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        vigenciaDesde: {
            name: 'vigenciaDesde',
            label: 'Vigencia Desde ',
            requiredErrorMsg: 'Debe especificar una fecha'
        },
        vigenciaHasta: {
            name: 'vigenciaHasta',
            label: 'Vigencia Hasta ',
            requiredErrorMsg: 'Debe especificar una fecha'
        },
        formaDePago: {
            name: 'formaDePago',
            label: 'Forma De Pago ',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        codigoFormaDePago: {
            name: 'codigoFormaDePago',
            label: 'Forma De Pago ',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        moduloCobertura: {
            name: 'moduloCobertura',
            label: 'Módulo de la cobertura ',
            requiredErrorMsg: 'Debe especificar la cobertura'
        },
        premium: {
            name: 'premium',
            label: 'Cuota Mensual ',
            requiredErrorMsg: 'Debe indicar el valor de la cuota mensual'
        },
        cliente: {
            rol: {
                name: 'rol',
                label: 'Rol ',
                requiredErrorMsg: 'Debe seleccionar una opción'
            },
            personaJuridica: {
                name: 'personaJuridica',
                label: 'Tipo Persona',
                requiredErrorMsg: 'Debe seleccionar una opción'
            },
            dni: {
                name: 'dni',
                label: 'DNI ',
                requiredErrorMsg: 'Debe especificar este campo'
            },
            cuit: {
                name: 'cuit',
                label: 'CUIT/CUIL ',
                requiredErrorMsg: 'Debe especificar el CUIT/CUIL'
            },
            nombre: {
                name: 'nombre',
                label: 'Nombre',
                requiredErrorMsg: 'Debe especificar este campo'
            },
            apellido: {
                name: 'apellido',
                label: 'Apellido ',
                requiredErrorMsg: 'Debe especificar este campo'
            },
            fechaNacimiento: {
                name: 'fechaNacimiento',
                label: 'Fecha de nacimiento ',
                requiredErrorMsg: 'Debe especificar la fecha de nacimiento'
            },
            estadoCivil: {
                name: 'estadoCivil',
                label: 'Estado civil ',
                requiredErrorMsg: 'Debe seleccionar un estado civil'
            },
            sexo: {
                name: 'sexo',
                label: 'Sexo ',
                requiredErrorMsg: 'Debe seleccionar un sexo'
            },
            domicilio: {
                name: 'domicilio',
                label: 'Dirección de domicilio ',
                requiredErrorMsg: 'Debe especificar una dirección de domicilio'
            },
            //New domicilio fields
            calle: {
                name: 'calle',
                label: 'Dirección de domicilio',
                requiredErrorMsg: 'Debe especificar una dirección de domicilio'
            },
            numero: {
                name: 'numero',
                label: 'Número de domicilio',
                requiredErrorMsg: 'Debe especificar el número de su domicilio'
            },
            opcional: {
                name: 'opcional',
                label: 'Torre / Piso / Dpto / Lote',
                requiredErrorMsg: 'Debe especificar una dirección de domicilio'
            },
            codigosLocalidad: {
                name: 'codigosLocalidad',
                label: 'Localidad',
                requiredErrorMsg: 'Debe seleccionar una opción'
            },
            codigoPostal: {
                name: 'codigoPostal',
                label: "Código postal",
                requiredErrorMsg: 'Debe especificar un código postal'
            },
            idProvincia: {
                name: 'idProvincia',
                label: 'Provincia',
                requiredErrorMsg: 'Debe seleccionar una opción'
            },
            codigoArea: {
                name: 'codigoArea',
                label: 'Código de área ',
                requiredErrorMsg: 'Debe especificar el código de área'
            },
            telefono: {
                name: 'telefono',
                label: 'Celular ',
                requiredErrorMsg: 'Debe especificar un celular'
            },
            email: {
                name: 'email',
                label: 'Email ',
                requiredErrorMsg: 'Debe especificar un email'
            },
            situacionIva: {
                name: 'situacionIva',
                label: 'Situación ante el IVA ',
                requiredErrorMsg: 'Debe seleccionar una opción'
            },
            categoriaMonotributo: {
                name: 'categoriaMonotributo',
                label: 'Categoría de monotributo ',
                requiredErrorMsg: 'Debe seleccionar una opción'
            },
            actividadPrincipal: {
                name: 'actividadPrincipal',
                label: 'Actividad principal (CIIU de AFIP) ',
                requiredErrorMsg: 'Debe seleccionar una opción'
            },
            lugarNacimiento: {
                name: 'lugarNacimiento',
                label: 'Lugar de nacimiento ',
                requiredErrorMsg: 'Debe especificar un lugar de nacimiento'
            }
        },
        emailDespachoElectronico: {
            name: 'emailDespachoElectronico',
            label: 'Email al que enviaremos la documentación ',
            requiredErrorMsg: 'Debe especificar un email'
        },
        vehiculo: {
            valorAdicional: {
                name: 'valorAdicional',
                label: 'Valor adicional (accesorios)',
                requiredErrorMsg: 'Debe especificar un valor (puede ser cero)'
            },
            equipoDeRastreo: {
                name: 'equipoDeRastreo',
                label: 'Solicitar equipo de rastreo ',
                requiredErrorMsg: 'Debe seleccionar una opción'
            },
            marca: {
                name: 'marca',
                label: 'Marca',
                requiredErrorMsg: ''
            },
            modelo: {
                name: 'modelo',
                label: 'Modelo',
                requiredErrorMsg: ''
            },
            version: {
                name: 'version',
                label: 'Versión',
                requiredErrorMsg: ''
            },
            anio: {
                name: 'anio',
                label: 'Año',
                requiredErrorMsg: 'Debe especificar el año del modelo'
            },
            tipoUso: {
                name: 'tipoUso',
                label: 'Uso ',
                requiredErrorMsg: 'Debe seleccionar una opción'
            },
            valor: {
                name: 'valor',
                label: 'Valor del vehículo ',
                requiredErrorMsg: 'Debe especificar el valor del vehículo'
            },
            usoVehiculo: {
                name: 'usoVehiculo',
                label: 'Tipo de uso',
                requiredErrorMsg: ''
            },
            ceroKm: {
                name: 'ceroKm',
                label: 'Vehículo 0 Km ',
                requiredErrorMsg: 'Debe seleccionar una opción'
            },
            mesAnioFacturacion: {
                name: 'mesAnioFacturacion',
                label: 'Fecha de facturación',
                requiredErrorMsg: 'Debe especificar la fecha'
            },
            patente: {
                name: 'patente',
                label: 'Patente ',
                requiredErrorMsg: 'Debe especificar la patente'
            },
            motor: {
                name: 'motor',
                label: 'Número de motor ',
                requiredErrorMsg: 'Debe especificar el número de motor'
            },
            chasis: {
                name: 'chasis',
                label: 'Número de chasis ',
                requiredErrorMsg: 'Debe especificar el número de chasis'
            },
            tieneGnc: {
                name: 'tieneGnc',
                label: 'Tiene equipo de GNC ',
                requiredErrorMsg: 'Debe seleccionar una opción'
            },
            valorGnc: {
                name: 'valorGnc',
                label: 'Valor del equipo de GNC',
                requiredErrorMsg: 'Debe ingresar el valor del equipo de GNC'
            },
            gnc: {
                descripcionGnc: {
                    name: 'descripcionGnc',
                    label: 'Descripción ',
                    requiredErrorMsg: 'Debe completar la descripción'
                },
                marcaRegulador: {
                    name: 'marcaRegulador',
                    label: 'Marca del regulador ',
                    requiredErrorMsg: 'Debe especificar la marca del regulador'
                },
                numeroRegulador: {
                    name: 'numeroRegulador',
                    label: 'Número del regulador ',
                    requiredErrorMsg: 'Debe especificar el número del regulador'
                },
                cilindros: {
                    marcaCilindro: {
                        name: 'marcaCilindro',
                        label: 'Marca del cilindro ',
                        requiredErrorMsg: 'Debe especificar la marca del cilindro'
                    },
                    numeroCilindro: {
                        name: 'numeroCilindro',
                        label: 'Número del cilindro ',
                        requiredErrorMsg: 'Debe especificar el número del cilindro'
                    },
                },
                valorTotal: {
                    name: 'valorTotal',
                    label: 'Valor del equipo de GNC ',
                    requiredErrorMsg: 'Debe especificar el valor del equipo'
                }
            },
            inspeccionPrevia: {
                archivos: {
                    name: 'archivos',
                    label: 'Archivos'
                },
                daniosPreexistentes: {
                    name: 'daniosPreexistentes',
                    label: 'Daños preexistentes',
                },
                comentariosInspeccion: {
                    name: 'comentariosInspeccion',
                    label: 'Comentarios Inspección'
                },
                fechaInspeccion: {
                    name: 'fechaInspeccion',
                    label: 'Fecha de la inspección ',
                    requiredErrorMsg: 'Debe especificar la fecha de inspección'
                },
                tipoCombustible: {
                    name: 'tipoCombustible',
                    label: 'Tipo de combustible ',
                    requiredErrorMsg: 'Debe seleccionar una opción'
                },
                aspectoGeneral: {
                    name: 'aspectoGeneral',
                    label: 'Aspecto general del vehículo',
                    requiredErrorMsg: ''
                },
                estadoCristales: {
                    name: 'estadoCristales',
                    label: 'Estado de los cristales ',
                    requiredErrorMsg: 'Debe seleccionar una opción'
                },
                resultadoInspeccion: {
                    name: 'resultadoInspeccion',
                    label: 'Resultado de la inspección ',
                    requiredErrorMsg: 'Debe seleccionar una opción'
                },
                kilometraje: {
                    name: 'kilometraje',
                    label: 'Kilometraje ',
                    requiredErrorMsg: 'Debe especificar el kilometraje'
                },
                aspectoPintura: {
                    name: 'aspectoPintura',
                    label: 'Aspecto de la pintura',
                    requiredErrorMsg: ''
                },
                tipoPintura: {
                    name: 'tipoPintura',
                    label: 'Tipo de pintura',
                    requiredErrorMsg: ''
                },
                aspectoChapa: {
                    name: 'aspectoChapa',
                    label: 'Aspecto de la chapa',
                    requiredErrorMsg: ''
                },
                aspectoNeumaticos: {
                    name: 'aspectoNeumaticos',
                    label: 'Aspecto de los neumáticos',
                    requiredErrorMsg: ''
                },
                aspectoTapizado: {
                    name: 'aspectoTapizado',
                    label: 'Aspecto del tapizado',
                    requiredErrorMsg: ''
                },
                colorTapizado: {
                    name: 'colorTapizado',
                    label: 'Color del tapizado',
                    requiredErrorMsg: ''
                },
                tipoTapizado: {
                    name: 'tipoTapizado',
                    label: 'Tipo de tapizado',
                    requiredErrorMsg: ''
                },
                colorVehiculo: {
                    name: 'colorVehiculo',
                    label: 'Color del vehículo ',
                    requiredErrorMsg: 'Debe especificar el color del vehículo'
                },
                tipoLlantas: {
                    name: 'tipoLlantas',
                    label: 'Tipo de llantas',
                    requiredErrorMsg: ''
                }
            },
        },
        bienesAsegurados: {
            id: {
                name: 'id',
                label: 'Bien asegurado ',
                requiredErrorMsg: 'Debe seleccionar una opción'
            },
            descripcion: {
                name: 'descripcion',
                label: 'Descripción ',
                requiredErrorMsg: 'Debe completar la descripción'
            },
            suma: {
                name: 'suma',
                label: 'Suma asegurada ',
                requiredErrorMsg: 'Debe especificar el monto'
            },
        },
        configuracionAvanzada: {
            sumaAseguradaVehiculo: {
                name: 'sumaAseguradaVehiculo',
            },
            codigos: {
                name: 'codigos',
            },
            RUS: {
                codRUSLocalidad: {
                    name: 'codRUSLocalidad',
                },
                tipoVigencia: {
                    name: 'tipoVigencia',
                },
                usoVehiculoRUS: {
                    name: 'usoVehiculoRUS',
                },
            },
            Sancor: {
                codSancorLocalidad: {
                    name: 'codSancorLocalidad',
                },
                fechaCotizacion: {
                    name: 'fechaCotizacion',
                }
            },
            Zurich: {
                codZurichLocalidad: {
                    name: 'codZurichLocalidad',
                },
                idViaCobro: {
                    name: 'idViaCobro',
                    label: 'Forma de pago'
                },
                mesAnioFacturacion: {
                    name: 'mesAnioFacturacion',
                    label: 'Fecha de facturación',
                    requiredErrorMsg: 'Debe especificar la fecha'
                },
            }
        },
        tamanioVivienda: {
            name: 'tamanioVivienda',
            label: 'Tamaño de vivienda ',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        tipoVivienda: {
            name: 'tipoVivienda',
            label: 'Tipo de vivienda ',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        coberturas: {
            sumaAsegurada: {
                name: 'sumaAsegurada',
                label: 'Suma asegurada',
                requiredErrorMsg: 'Debe completar este campo'
            },
            numeroCobertura: {
                name: 'numeroCobertura',
                label: 'Número cobertura',
                requiredErrorMsg: 'Debe completar este campo'
            },
            selectedCobertura: {
                name: 'selectedCobertura',
            },
        },
        coberturasAdicionales: {
            sumaAseguradaAdicional: {
                name: 'sumaAsegurada',
                label: 'Suma asegurada',
                requiredErrorMsg: 'Debe completar este campo'
            },
            numeroCoberturaAdicional: {
                name: 'numeroCobertura',
                label: 'Número cobertura',
                requiredErrorMsg: 'Debe completar este campo'
            },
            selectedCoberturaAdicional: {
                name: 'selectedCoberturaAdicional',
            },
        },
        situacionImpositiva: {
            name: 'situacionImpositiva',
            label: 'Situación impositiva',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        personaJuridica: {
            name: 'personaJuridica',
            label: 'Persona jurídica',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        codigosLocalidad: {
            name: 'codigosLocalidad',
            label: 'Localidad',
            requiredErrorMsg: 'Debe especificar una localidad'
        },
        vigencia: {
            name: 'vigencia',
            label: 'Vigencia',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        fechaVigencia: {
            name: 'fechaVigencia',
            label: 'Desde',
            requiredErrorMsg: 'Debe especificar una fecha'
        },
        descuentoModelo: {
            name: 'descuentoModelo',
            label: 'Porcentaje de Descuento',
            requiredErrorMsg: 'Debe especificar un porcentaje de descuento'
        },
        descuentoEspecial: {
            name: 'descuentoEspecial',
            label: 'Descuento especial',
            requiredErrorMsg: 'Debe especificar un porcentaje de descuento'
        },
        equipoTelematica: {
            name: 'equipoTelematica',
            label: 'Equipo telemática',
            requiredErrorMsg: 'Debe seleccionar una opción'
        },
        comentariosSolicitud: {
            name: 'comentariosSolicitud',
            label: 'Comentarios de la solicitud',
            requiredErrorMsg: ''
        },
        detalleEquipoElectronico: {
            descripcionElectronico: {
                name: 'descripcionElectronico',
                label: 'Descripción',
                requiredErrorMsg: 'Debe completar este campo'
            },
            sumaAseguradaElectronico: {
                name: 'sumaAseguradaElectronico',
                label: 'Suma Asegurada',
                requiredErrorMsg: 'Debe completar este campo'
            },
            codigoElectronico: {
                name: 'codigoElectronico',
            },
            codigoGrupoElectronico: {
                name: 'codigoGrupoElectronico',
            },
        },
        detalleBicicletas: {
            descripcionBicicletas: {
                name: 'descripcionBicicletas',
                label: 'Descripción',
                requiredErrorMsg: 'Debe completar este campo'
            },
            sumaAseguradaBicicletas: {
                name: 'sumaAseguradaBicicletas',
                label: 'Suma Asegurada',
                requiredErrorMsg: 'Debe completar este campo'
            },
            codigoBicicletas: {
                name: 'codigoBicicletas',
            },
            codigoGrupoBicicletas: {
                name: 'codigoGrupoBicicletas',
            }
        },
        infoDePago: {
            codigoAgencia: {
                name: 'codigoAgencia',
                label: 'Sucursal de Débito',
                requiredErrorMsg: ''
            },
            numeroCbu: {
                name: 'numeroCbu',
                label: 'Número de CBU ',
                requiredErrorMsg: 'Debe especificar un número de CBU'
            },
            marcaTarjeta: {
                name: 'marcaTarjeta',
                label: 'Marca de la tarjeta ',
                requiredErrorMsg: 'Debe seleccionar una opción'
            },
            numeroTarjeta: {
                name: 'numeroTarjeta',
                label: 'Número de la tarjeta ',
                requiredErrorMsg: 'Debe especificar un número de tarjeta'
            },
            vencimientoTarjeta: {
                name: 'vencimientoTarjeta',
                label: 'Vencimiento de la tarjeta (MM/YY) ',
                requiredErrorMsg: 'Debe especificar el vencimiento de la tarjeta'
            }
        },
        despachoElectronico: {
            name: 'despachoElectronico',
            label: '¿Recibir póliza mediante un mail?',
            requiredErrorMsg: 'Porfavor especifique una opción'
        },
        scoring: {
            garage: {
                name: 'garage',
                label: '¿Guarda en garage?',
                requiredErrorMsg: ''
            },
            edadConductores: {
                name: 'edadConductores',
                label: '¿Conductores menores de 25 años?',
                requiredErrorMsg: ''
            },
            kmsAnuales: {
                name: 'kmsAnuales',
                label: '¿Más de 25000 Kms anuales?',
                requiredErrorMsg: ''
            },
        },
    }
};

