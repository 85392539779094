const saveFileContent = (file) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onloadend = () => {
            const fileContent = reader.result.split(',')[1];
            resolve(fileContent);
        };
        reader.onerror = () => {
            reader.abort();
            reject(new DOMException("Error al leer el archivo."));
        };
        reader.readAsDataURL(file);
    });
};

export {saveFileContent}