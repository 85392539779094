import React, {useContext} from 'react';
import ClienteDirectoContext from "../context/ClienteDirectoContext";
import polko_white from "../assets/logos/polko_white.png"
import ssn_white from "../assets/logos/ssn_white.png"
import {IconBrandWhatsapp, IconMail, IconMapPin} from '@tabler/icons';
import {capitalizeString} from "./Utils/capitalizeString";

function Footer() {
    const {datosMaster} = useContext(ClienteDirectoContext);

    if (datosMaster && datosMaster.telefono) {
        if (datosMaster.telefono.charAt(0) !== '+') {
            datosMaster.telefono = '+' + datosMaster.telefono;
        }
    }

    return (
        <div className="footer">
            <div className="footer__container">
                <div className="footer__container--logo">
                    <img src={polko_white} alt={"polko"} className="footer__logos--big"/>
                </div>
                <div>
                    <div className="section__container">
                        <p className="footer__text--bold">{`${datosMaster['nombre']} ${datosMaster['apellido']}`}</p>
                        <div className="structure__center">
                            <img src={ssn_white} alt={"ssn"} className="footer__logos--desktop"/>
                            <p className="footer__text--regular">{datosMaster['matricula'] && `Matrícula ${datosMaster['matricula']}`}</p>
                        </div>
                    </div>

                    <div className="section__container">
                        <div className="structure__center">
                            <IconBrandWhatsapp className="footer__icons"/>
                            <p className="footer__text--regular">{datosMaster['telefono']}</p>
                        </div>
                        <div className="structure__center structure__margin--topXS">
                            <IconMail className="footer__icons"/>
                            <p className="footer__text--regular">{datosMaster['email']}</p>
                        </div>
                        <div className="structure__center structure__margin--topXS">
                            <IconMapPin className="footer__icons"/>
                            <p className="footer__text--regular">{capitalizeString(datosMaster['direccion'])}</p>
                        </div>
                    </div>
                </div>
                <div className="footer__bottom">
                    <img src={polko_white} alt={"polko"} className="footer__logos"/>
                    <img src={ssn_white} alt={"ssn"} className="footer__logos"/>
                </div>
            </div>
        </div>
    );
}

export default Footer;