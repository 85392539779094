const newFillEquipoElectronicoValues = (values) => {
    const equipoElectronicoValues = {}

    let bicicletas = []
    values.detalleBicicletas.map(item => {
        if (item.sumaAseguradaBicicletas !== '')
            bicicletas.push({
                descripcion: item.descripcionBicicletas,
                sumaAsegurada: parseInt(item.sumaAseguradaBicicletas),
                codigo: item.codigoBicicletas,
                codigoGrupo: item.codigoGrupoBicicletas
            })
    })

    let electronico = []
    values.detalleEquipoElectronico.map(item => {
        if (item.sumaAseguradaElectronico !== '')
            electronico.push({
                descripcion: item.descripcionElectronico,
                sumaAsegurada: parseInt(item.sumaAseguradaElectronico),
                codigo: item.codigoElectronico,
                codigoGrupo: item.codigoGrupoElectronico
            })
    })

    if (bicicletas.length) {
        equipoElectronicoValues["bicicletas"] = bicicletas;
    }

    if (electronico.length) {
        equipoElectronicoValues["equiposElectronicos"] = electronico;
    }

    if (!bicicletas.length && !electronico.length) {
        return null;
    } else {
        return equipoElectronicoValues;
    }
}

export {newFillEquipoElectronicoValues};