import React, {useContext, useMemo, useState} from 'react';
import {useFormikContext} from "formik";
import {Collapse, Divider} from '@mantine/core';
import {ReactComponent as PassengerIcon} from "../../../../../../assets/systemIcons/ic_user.svg";
import {ReactComponent as ArrowIcon} from '../../../../../../assets/systemIcons/ic_chevron_down.svg';
import "./SolicitudForm.css";
import {format} from "date-fns";
import ClienteDirectoContext from "../../../../../../context/ClienteDirectoContext";
import {currencyFormat} from "../../../../../Utils/currencyFormat";
import AsistenciaViajeroContext from "../../../../../../context/products/AsistenciaViajeroContext";

export default function SolicitudForm() {
    const {values} = useFormikContext();
    const {companiesDict} = useContext(ClienteDirectoContext);
    const {destinos} = useContext(AsistenciaViajeroContext)
    const [openStates, setOpenStates] = useState(values.pasajeros.map(() => false));
    const formattedVigenciaDesde = useMemo(() => format(values.vigenciaDesde, 'dd/MM/yyyy'), [values.vigenciaDesde])
    const formattedVigenciaHasta = useMemo(() => format(values.vigenciaHasta, 'dd/MM/yyyy'), [values.vigenciaHasta])
    const countryName = useMemo(() => destinos.find((destino) => destino.value === values.idPais)?.label, [values.idPais])

    const toggle = index => {
        setOpenStates(currentStates =>
            currentStates.map((state, i) => (i === index ? !state : state))
        );
    };


    return (
        <React.Fragment>
            <div className="automotor__formStep__container">
                <div className="autem__solicitud__header">
                    <p className="form__text--primary autem__solicitud__headerText">Detalles del viaje:</p>
                    <Divider my="sm" size="xs"/>
                    <div className="structure__margin--topXS structure__center structure__columnToRow--centered">
                        <div className="passengerBox__listDataContainer">
                            <p className="form__text--dark">Cobertura: <span className="form__text--light">{values.nombreCobertura}</span></p>
                            <p className="form__text--dark">Destino: <span className="form__text--light">{countryName}</span></p>
                            <p className="form__text--dark">Fechas: <span className="form__text--light">{formattedVigenciaDesde} - {formattedVigenciaHasta}</span></p>
                        </div>
                        <img className="form__aseguradora structure__margin--XS" src={companiesDict['TerraWind']['logo']} alt={'TerraWind'}/>
                    </div>
                </div>
                <div className="autem__solicitud__body">
                    <div className="autem__solicitud__header">
                        <p className="form__text--primary autem__solicitud__headerText">Datos de los asegurados:</p>
                        <Divider my="sm" size="xs"/>
                        <div className="passengerBox__listPassengersContainer">
                        {values.pasajeros.map((pasajero, index) => (
                            <div key={index} className="passengerBox__clientRow">
                                <div onClick={() => toggle(index)} className="passengerBox__infoContainer">
                                    <PassengerIcon className="passengerBox__icon"/>
                                    <p className="passengerBox__personalInfo">{pasajero.nombre} {pasajero.apellido}</p>
                                    <p className="passengerBox__personalInfo">{pasajero.numeroDocumento}</p>
                                    <ArrowIcon className={`passengerBox__arrowIcon ${openStates[index] && 'passengerBox__arrowIcon--top'}`}/>
                                </div>
                                <Collapse in={openStates[index]}>
                                    <div className="passengerBox__collapsedPersonalInfoBox">
                                        <p className="passengerBox__personalInfo"><span className="passengerBox__personalInfo--highlighted">Celular:</span> {pasajero.telefono}</p>
                                        <p className="passengerBox__personalInfo"><span className="passengerBox__personalInfo--highlighted">Email:</span> {pasajero.email}</p>
                                        <hr className="passengerBox__hrDivider"/>
                                        <p className="passengerBox__personalInfo--sectionTitle">Contacto de emergencia</p>
                                        <p className="passengerBox__personalInfo">{values.nombre_contacto_emergencia} {values.apellido_contacto_emergencia}</p>
                                        <p className="passengerBox__personalInfo"><span className="passengerBox__personalInfo--highlighted">Celular:</span> {values.telefono_contacto_emergencia}</p>
                                    </div>
                                </Collapse>
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
                <div className="structure__margin--topOnly">
                    <p className="form__price">
                        <p className="form__subtitle--white">TOTAL</p>
                        <p className="form__title--white">${currencyFormat(parseInt(values.premium))}</p>
                    </p>
                </div>
            </div>
        </React.Fragment>
    );
}