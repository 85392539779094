import React, {Fragment, useContext, useEffect} from 'react';
import ClienteDirectoContext from "../../../context/ClienteDirectoContext";
import {currencyFormat} from "../../Utils/currencyFormat";
import {Divider} from "@mantine/core";


function CotizacionSuccess(props) {
    const {cotizacionResponse} = props;
    const {coberturasInfo} = useContext(ClienteDirectoContext);

    useEffect(() => {
        if (cotizacionResponse !== "Error") {
            cotizacionResponse['detalleCoberturas']["obligatorias"].sort((a, b) => parseInt(a.numeroCobertura) - parseInt(b.numeroCobertura));
            cotizacionResponse['detalleCoberturas']["adicionales"].sort((a, b) => parseInt(a.numeroCobertura) - parseInt(b.numeroCobertura));
        }
    }, []);

    return (
        <React.Fragment>
            {cotizacionResponse === "Error"
                ?
                <div className="structure__margin--topLG">
                    <p className="form__subtitle structure__center">
                        Hubo un problema al cotizar. Por favor, vuelva a intentarlo más tarde.
                    </p>
                </div>
                :
                <Fragment>
                    <p className="form__text--primary structure__start">
                        Resumen de cotización:
                    </p>
                    <p className="form__subtitle" style={{fontWeight: "600"}}>
                        Cuota Mensual: ${currencyFormat(cotizacionResponse['premioMensual'].toFixed(0))}
                    </p>

                    <Divider my="sm" size="xs"/>

                    <p className="form__text--primary structure__start structure__margin--topOnly">
                        Detalle Coberturas:
                    </p>

                    {cotizacionResponse['detalleCoberturas']["obligatorias"].map((item, index) => (
                        <div className="form__body">
                            <p className="form__text--dark">{coberturasInfo[item.numeroCobertura].descripcion}</p>
                            <p className="form__text--light">{`Suma Asegurada: $ ${currencyFormat(item.sumaAsegurada)}`}</p>
                        </div>
                    ))}

                    <p className="form__text--primary structure__start structure__margin--topOnly"
                       style={{display: cotizacionResponse['detalleCoberturas']["adicionales"].length === 0 && "none"}}>
                        Adicionales:
                    </p>
                    {cotizacionResponse['detalleCoberturas']["adicionales"].map((item, index) => (
                        <div className="form__body">
                            <p className="form__text--dark">{coberturasInfo[item.numeroCobertura].descripcion}</p>
                            <p className="form__text--light">{`Suma Asegurada: $ ${currencyFormat(item.sumaAsegurada)}`}</p>
                        </div>
                    ))}
                    <p className="form__note">* La aseguradora indemnizará los daños hasta las sumas aseguradas
                        contratadas.</p>
                </Fragment>
            }
        </React.Fragment>
    );
}

export default CotizacionSuccess;
