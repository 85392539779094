import React, {useContext, useEffect, useState} from 'react';
import {currencyFormat} from "./Utils/currencyFormat";
import rightArrow from "../assets/systemIcons/right-arrow.png"
import FranchiseSelector from "./FranchiseSelector";
import ClienteDirectoContext from "../context/ClienteDirectoContext";
import {useFormikContext} from "formik";
import {todoRiesgoAmountReplacer} from "./Utils/todoRiesgoAmountReplacer";
import {franchiseStringToObject} from "./Utils/franchiseStringToObject";

const productNamesByKey = {
    "vehiculo": "vehículo",
    "motovehiculo": "motovehículo",
}

function CoberturaCard(props) {
    const {
        cardInfo:
            {id, nombre, displayPremium: premium, description, compania, hasFranchise, franchises, parametrosAdicionales},
        currentProduct,
        finalValues
    } = props;
    const [variantDetails, setVariantDetails] = useState(false);
    const [nombreCobertura, setNombreCobertura] = useState(nombre);
    const [selected, setSelected] = useState(0);
    const {companiesDict, coberturasProductsInfo} = useContext(ClienteDirectoContext);
    const {setFieldValue} = useFormikContext();

    const descriptionItems = () => {
        //Create coverage details
        let coverageDetails;
        if (hasFranchise) {
            coverageDetails = Object.keys(coberturasProductsInfo[currentProduct][compania][id[selected]]['detalle']).map(det => {
                if (det === "APD") {
                    const franchiseValue = franchises[selected];
                    let franchiseDisplay;

                    if (typeof franchiseValue === "number") {
                        franchiseDisplay = `Franquicia de $${currencyFormat(franchiseValue)}`;
                    }
                    else if (typeof franchiseValue === "string") {
                        const franchiseObj = franchiseStringToObject(franchiseValue);
                        if (franchiseObj) {
                            if (franchiseObj.var === -1){
                                franchiseDisplay = `Franquicia de $${currencyFormat(franchiseObj.min)}`;
                            }
                            else franchiseDisplay = `Franquicia Variable: ${franchiseObj.var}% - Mínimo: $${currencyFormat(franchiseObj.min)}`;
                        } else {
                            franchiseDisplay = franchiseValue; // o cualquier manejo de error que desees.
                        }
                    }
                    else franchiseDisplay = "";

                    return (
                        <p className="coberturaCard__item form__text--light structure__margin--XS">
                            {franchiseDisplay}
                        </p>
                    )
                }
                else return (
                    <p className="coberturaCard__item form__text--light structure__margin--XS">
                        {coberturasProductsInfo[currentProduct]['names'][det]}
                    </p>
                )
            })

        } else {
            coverageDetails = Object.keys(coberturasProductsInfo[currentProduct][compania][id]['detalle']).map(det => {
                if (coberturasProductsInfo[currentProduct][compania][id]['detalle'][det]) {
                    return (
                        coberturasProductsInfo[currentProduct]['names'][det] ?
                            <p className="coberturaCard__item form__text--light structure__margin--XS">
                            {coberturasProductsInfo[currentProduct]['names'][det]}
                        </p> :
                            null
                    )
                }
            })
        }

        //Add sumaAsegurada to details
        const sumaAseguradaVehiculo =
            parametrosAdicionales.sumaAseguradaVehiculo !== undefined
                ? currencyFormat(parametrosAdicionales.sumaAseguradaVehiculo)
                : 0;
        coverageDetails.unshift(
            <p className="coberturaCard__item form__text--light structure__margin--XS">
                Suma asegurada del {productNamesByKey[currentProduct]}: $ {sumaAseguradaVehiculo}
            </p>
        )

        //Add vigencia to details
        let vigenciaText = "Anual con refacturación mensual"
        if (compania === "RUS")
            vigenciaText = "Semestral con refacturación trimestral"
        coverageDetails.push(
            <p className="coberturaCard__item form__text--light structure__margin--XS">
                Vigencia: {vigenciaText}
            </p>
        )

        return coverageDetails
    }

    const assignValues = () => {
        let nombreCoberturaToSave = nombreCobertura;
        let aditionalParameters = {
            ...finalValues.configuracionAvanzada[compania]
        }

        if (compania === "Sancor") {
            aditionalParameters = {
                ...aditionalParameters,
                "equipoDeRastreo": parametrosAdicionales.trackingEquipment ?? "false",
                "outStandard": parametrosAdicionales.outStandardSancor ?? "false",
                "sumaAseguradaVehiculo": parametrosAdicionales.sumaAseguradaVehiculo,
            }
        } else if (compania === "RUS") {
            aditionalParameters = {
                ...aditionalParameters,
                ...parametrosAdicionales,
            }
            nombreCoberturaToSave += ` - ${parametrosAdicionales.codigoRC}`
        }

        setFieldValue("compania", compania);
        setFieldValue("selectedCobertura", hasFranchise ? id[selected] : id);
        setFieldValue("vehiculo.valor", parametrosAdicionales.sumaAseguradaVehiculo ?? 0);
        setFieldValue("premium", hasFranchise ? premium[selected] : premium);
        setFieldValue("nombreCobertura", nombreCoberturaToSave)
        setFieldValue("parametrosAdicionales", aditionalParameters);
    }

    useEffect(() => {
        // Fix for missing coverage names, commented due to merge conflict
        // if (coberturasProductsInfo[currentProduct][compania].hasOwnProperty([id[selected]])) {
        if (!hasFranchise) {
            if (coberturasProductsInfo[currentProduct][compania][id] === undefined) {
                setNombreCobertura(nombre)
            } else {
                setNombreCobertura(coberturasProductsInfo[currentProduct][compania][id]['nombre_comercial'])
            }
        } else {
            const coverageFixedName = todoRiesgoAmountReplacer(
                compania,
                coberturasProductsInfo[currentProduct][compania][id[selected]]['nombre_comercial'],
                id[selected],
                franchises[selected]
            )
            setNombreCobertura(coverageFixedName)
        }
    }, [selected]);

    return (
        <div className="coberturaCard__container">
            <div className={variantDetails ? "coberturaCard--details" : "coberturaCard"}>
                <div className="coberturaCard__header">
                    <img className="coberturaCard__logo" src={companiesDict[compania]['logo']} alt="aseguradora_logo"/>
                </div>
                <div className="coberturaCard__body">
                    <div className="coberturaCard__titleContainer">
                        <p className={variantDetails ? "coberturaCard__title--primary" : "coberturaCard__title"}>
                            {variantDetails ? "Este plan incluye" : nombreCobertura}
                        </p>
                    </div>

                    {variantDetails
                        ?
                        <div className="structure__margin">
                                {descriptionItems()}

                            <div className="structure__column structure__margin--topOnly">
                                <p className="coberturaCard__title--primary coberturaCard__title--noBorder">
                                    {nombreCobertura}
                                </p>
                                <p className="coberturaCard__premium">$
                                    <span className="coberturaCard__premium--number">
                                    {hasFranchise ? currencyFormat(Math.ceil(premium[selected])) : currencyFormat(Math.ceil(premium))}
                                    </span>
                                    / mes
                                </p>
                            </div>

                        </div>
                        :
                        <div className="coberturaCard__info">
                            <p className="coberturaCard__premium">$
                                <span className="coberturaCard__premium--number">
                                    {hasFranchise ? currencyFormat(Math.ceil(premium[selected])) : currencyFormat(Math.ceil(premium))}
                                </span>
                                /mes
                            </p>
                            <p className="coberturaCard__description">{description}</p>
                            {
                                hasFranchise &&
                                <FranchiseSelector franchises={franchises} initial={selected}
                                                   setSelected={setSelected}/>
                            }
                        </div>
                    }

                    <div className="coberturaCard__footer">
                        <button
                            className="button__secondary--large"
                            type="button"
                            onClick={() => setVariantDetails(!variantDetails)}
                        >
                            <span style={{marginRight: "8px"}}>
                                {variantDetails
                                    ? "Volver"
                                    : "Ver detalle"
                                }
                            </span>
                            <img className="navbar__icon"
                                 src={rightArrow}/>
                        </button>
                        <button
                            className="button--large"
                            onClick={() => assignValues()}
                            type="submit"
                        >
                            Contratar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CoberturaCard;