import React, {useContext} from 'react';
import AsistenciaViajeroContextProvider from './AsistenciaViajeroContextProvider';
import AsistenciaViajeroContext from "./AsistenciaViajeroContext";
import Loading from "../../../components/Loading";

/**
 * Inserts UserContextProvider in the react tree
 * before adding the given component.
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withAsistenciaViajeroContextProvider = (Component) => (props) => (
    <AsistenciaViajeroContextProvider setLoading={props.setLoading}>
        <Component {...props} />
    </AsistenciaViajeroContextProvider>
);

/**
 * Renders the given component when the user context is ready.
 *
 * @param {Object} Component The component to render.
 * @param {Object} Component The rendered component.
 * @returns {Object} The component to render or the LoadingPage component.
 */
const withAsistenciaViajeroContextReady = (Component) => (props) => {

    const {isReady, asistenciaViajeroContextError} = useContext(AsistenciaViajeroContext);
    if (asistenciaViajeroContextError) {
        console.log(asistenciaViajeroContextError.detailedError);
    }
    return isReady ? <Component {...props} /> :
        <Loading/>
};


export {
    withAsistenciaViajeroContextProvider,
    withAsistenciaViajeroContextReady
};