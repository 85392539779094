import * as Yup from 'yup';
import {CardNumberValidator} from "../../Utils/CardNumberValidator";
import formModel from './formModel';
import {isValidPhoneNumber} from 'react-phone-number-input'

const moment = require('moment');

const {
    formField: {
        infoDePago: {
            numeroCbu,
            marcaTarjeta,
            numeroTarjeta,
        },
        formaDePago, vigenciaDesde,
        configuracionAvanzada: {
            Zurich: {
                mesAnioFacturacion,
            }
        },
        cliente: {
            sexo,
            calle,
            numero,
            codigosLocalidad,
            email,
            situacionIva,
            cuit,
            nombre,
            telefono,
            apellido,
            fechaNacimiento,
            dni,
            personaJuridica,
            lugarNacimiento,
            categoriaMonotributo,
            actividadPrincipal,
            rol,
            estadoCivil,
        },
        vehiculo: {
            marca,
            modelo,
            anio,
            valor,
            tieneGnc,
            valorGnc,
            valorAdicional,
            usoVehiculo,
            ceroKm,
            gnc: {
                descripcionGnc,
                valorTotal,
                numeroRegulador,
                cilindros: {marcaCilindro, numeroCilindro},
                marcaRegulador,
            },
            patente,
            motor,
            chasis,
            inspeccionPrevia: {
                comentariosInspeccion,
                colorVehiculo,
                estadoCristales,
                resultadoInspeccion,
                kilometraje,
                fechaInspeccion,
                tipoCombustible,
                archivos
            },
        },
        detalleEquipoElectronico: {
            descripcionElectronico,
            sumaAseguradaElectronico,
        },
        detalleBicicletas: {
            descripcionBicicletas,
            sumaAseguradaBicicletas,
        },
        tamanioVivienda,
        tipoVivienda,
    }
} = formModel;

let tipoTarjeta;
let equipoElectronicoSelected = false;
let bicicletasSelected = false;
const fecha45dias = moment(moment().add(45, 'days').calendar(), 'MM/DD/YYYY').toDate();
const fecha30dias = moment(moment().subtract(30, 'days').calendar(), 'MM/DD/YYYY').toDate();

function formatDate(date) {
    return new Date(date).toLocaleDateString()
}

const clienteValidation = {
    [rol.name]: Yup.string().required(`${rol.requiredErrorMsg}`),
    [dni.name]: Yup.number().when([personaJuridica.name], {
        is: '0',
        then: Yup.number()
            .required(`${dni.requiredErrorMsg}`)
            .min(1000000, "El documento ingresado no es válido")
            .max(99999999, "El documento ingresado no es válido")
            .integer()
            .typeError("Introduzca únicamente números"),
    }),
    [cuit.name]: Yup.string()
        .required(`${cuit.requiredErrorMsg}`)
        .matches(/^(20|23|27|30|33)([0-9]{9}|-[0-9]{8}-[0-9]{1})$/, "El CUIT ingresado no es válido"),
    [nombre.name]: Yup.string().when([personaJuridica.name], {
        is: '0',
        then: Yup.string()
            .required(`${nombre.requiredErrorMsg}`)
            .matches(/^[aA-zZ\s]+$/, "Ingrese un nombre válido")
            .max(40, "El nombre ingresado es muy largo"),
    }),
    [apellido.name]: Yup.string().when([personaJuridica.name], {
        is: '0',
        then: Yup.string()
            .required(`${apellido.requiredErrorMsg}`)
            .matches(/^[aA-zZ\s]+$/, "Ingrese un apellido válido")
            .max(40, "El valor ingresado es muy largo"),
    }).when([personaJuridica.name], {
        is: '1',
        then: Yup.string()
            .required(`${apellido.requiredErrorMsg}`)
            .matches(/^(?=[a-zA-Z0-9()_+=,.:\s-]*$)/, "Ingrese una razón social válida")
            .max(40, "El valor ingresado es muy largo"),
    }),
    [fechaNacimiento.name]: Yup.date().when([personaJuridica.name], {
        is: '0',
        then: Yup.date()
            .max(new Date(Date.now() - 567648000000), "El cliente debe tener al menos 18 años para contratar")
            .required(`${fechaNacimiento.requiredErrorMsg}`),
    }),
    [estadoCivil.name]: Yup.string().when([personaJuridica.name], {
        is: '0',
        then: Yup.string().required(`${estadoCivil.requiredErrorMsg}`),
    }),
    [sexo.name]: Yup.string().when([personaJuridica.name], {
        is: '0',
        then: Yup.string().required(`${sexo.requiredErrorMsg}`),
    }),
    [calle.name]: Yup.string()
        .required(`${calle.requiredErrorMsg}`)
        .matches(/^(?=[a-zA-Z0-9()_+=,.:\s-]*$)/, "Esta utilizando carácteres no válidos")
        .min(5, 'Ingrese al menos 5 caracteres'),
    [numero.name]: Yup.number()
        .required(`${numero.requiredErrorMsg}`)
        .integer()
        .typeError("Introduzca únicamente números"),
    [email.name]: Yup.string()
        .required(`${email.requiredErrorMsg}`)
        .email("Ingrese un email válido"),
    [situacionIva.name]: Yup.string().required(`${situacionIva.requiredErrorMsg}`),
    [categoriaMonotributo.name]: Yup.string().required(`${categoriaMonotributo.requiredErrorMsg}`),
    [actividadPrincipal.name]: Yup.string().when([personaJuridica.name], {
        is: '0',
        then: Yup.string().required(`${actividadPrincipal.requiredErrorMsg}`),
    }),
    [lugarNacimiento.name]: Yup.string().when([personaJuridica.name], {
        is: '0',
        then: Yup.string()
            .required(`${lugarNacimiento.requiredErrorMsg}`)
            .matches(/^([A-Za-z0-9 -]+)$/, "Esta utilizando carácteres no válidos"),
    }),
}

const infoDePagoValidation = {
    infoDePago: Yup.object().when([formaDePago.name], {
        is: '4',    // Débito por CBU
        then: Yup.object().shape({
            [numeroCbu.name]: Yup.string()
                .required(`${numeroCbu.requiredErrorMsg}`)
                .matches(/^[0-9]+$/, "Ingrese un número válido")
                .min(22, 'El número de CBU debe tener 22 dígitos')
                .max(22, 'El número de CBU debe tener 22 dígitos'),
        })
    }).when([formaDePago.name], {
        is: '2',    // Tarjeta de crédito
        then: Yup.object().shape({
            [marcaTarjeta.name]: Yup.string().test('cardType', 'Seleccione un tipo de tarjeta', type => {
                tipoTarjeta = type;
                if (tipoTarjeta !== null) return true;
            }).required(`${marcaTarjeta.requiredErrorMsg}`),
            [numeroTarjeta.name]: Yup
                .string()
                .test('cardNumber',
                    'El número de tarjeta ingresado es inválido', //validation message
                    value => {
                        if (tipoTarjeta === "9") {
                            if (value !== undefined && value.length === 15)
                                return CardNumberValidator(tipoTarjeta, value)
                        } else {
                            if (value !== undefined && value.length === 16)
                                return CardNumberValidator(tipoTarjeta, value)
                        }
                    })
                .required()
        })
    })
}

const detallesValidation = {
    //DummyValue for trigger callback method
    dummyValue: Yup.string(),
    detalleBicicletas: Yup.array().of(
        Yup.object().shape({
            [sumaAseguradaBicicletas.name]: Yup.number()
                .integer()
                .typeError("Introduzca únicamente números")
                .when("dummyValue", {
                    is: () => {
                        return bicicletasSelected === true
                    },
                    then: Yup.number()
                        .required(`${sumaAseguradaBicicletas.requiredErrorMsg}`)
                        .typeError("Introduzca únicamente números")
                        .test('notZeroInsuredSum', "La suma debe ser superior a cero", (value) => {
                            return value > 0
                        })
                }),
            [descripcionBicicletas.name]: Yup.string()
                .matches(/^[A-Za-z0-9 ,-]+$/, "Ingrese una descripción válida")
                .when("dummyValue", {
                    is: () => {
                        return bicicletasSelected === true
                    },
                    then: Yup.string().required(`${descripcionBicicletas.requiredErrorMsg}`)
                }),
        })).test('insuredSumsAgreggationBicicletas', "Las sumas declaradas deben coincidir con las aseguradas", (sumas, context) => {
            const coberturasAd = context.parent.coberturasAdicionales;
            let sumaAsegurada = 0;
            bicicletasSelected = false

            //MICRO
            for (let cobertura in coberturasAd) {
                if (cobertura === '48' && coberturasAd[cobertura].selectedCobertura) {
                    bicicletasSelected = true
                    sumaAsegurada = coberturasAd[cobertura].sumaAsegurada
                }
            }

            let totalInsuredValue = 0;
            sumas.map((item) => {
                if (item.sumaAseguradaBicicletas !== undefined)
                    totalInsuredValue += parseInt(item.sumaAseguradaBicicletas.toString().replace(/\./g, ""));
            })

            return (sumaAsegurada === totalInsuredValue)
        }
    ),
    detalleEquipoElectronico: Yup.array().of(
        Yup.object().shape({
            [sumaAseguradaElectronico.name]: Yup.number()
                .integer()
                .typeError("Introduzca únicamente números")
                .when("dummyValue", {
                    is: () => {
                        return equipoElectronicoSelected === true
                    },
                    then: Yup.number()
                        .required(`${sumaAseguradaElectronico.requiredErrorMsg}`)
                        .typeError("Introduzca únicamente números")
                        .test('notZeroInsuredSum', "La suma debe ser superior a cero", (value) => value > 0)
                }),
            [descripcionElectronico.name]: Yup.string()
                .matches(/^[A-Za-z0-9 ,-]+$/, "Ingrese una descripción válida")
                .when("dummyValue", {
                    is: () => {
                        return equipoElectronicoSelected === true
                    },
                    then: Yup.string().required(`${descripcionElectronico.requiredErrorMsg}`)
                }),
        })).test('insuredSumsAgreggationElectrónicos', "Las sumas declaradas deben coincidir con las aseguradas", (sumas, context) => {
            const coberturasAd = context.parent.coberturasAdicionales;
            let sumaAsegurada = 0;
            equipoElectronicoSelected = false

            //MICRO
            for (let cobertura in coberturasAd) {
                if (cobertura === '26' && coberturasAd[cobertura].selectedCobertura) {
                    equipoElectronicoSelected = true
                    sumaAsegurada = coberturasAd[cobertura].sumaAsegurada
                }
            }

            let totalInsuredValue = 0;
            sumas.map((item) => {
                if (item.sumaAseguradaElectronico !== undefined)
                    totalInsuredValue += parseInt(item.sumaAseguradaElectronico.toString().replace(/\./g, ""));
            })

            return (sumaAsegurada === totalInsuredValue)
        }
    ),
}

const customerCombinadoValidation = [
    Yup.object().shape({
        [codigosLocalidad.name]: Yup.string().required(`${codigosLocalidad.requiredErrorMsg}`),
        [tipoVivienda.name]: Yup.string().required(`${tipoVivienda.requiredErrorMsg}`),
        [tamanioVivienda.name]: Yup.string().required(`${tamanioVivienda.requiredErrorMsg}`),
        cliente: Yup.object().shape({
            [nombre.name]: Yup.string()
                .required(`${nombre.requiredErrorMsg}`)
                .matches(/^[aA-zZ\s]+$/, "Ingrese un nombre válido")
                .max(40, "El nombre ingresado es muy largo"),
            [apellido.name]: Yup.string()
                .required(`${apellido.requiredErrorMsg}`)
                .matches(/^[aA-zZ\s]+$/, "Ingrese un apellido válido")
                .max(40, "El apellido ingresado es muy largo"),
            [email.name]: Yup.string()
                .required(`${email.requiredErrorMsg}`)
                .email("Ingrese un email válido"),
        }),
    }),
    Yup.object().shape({}),
    Yup.object().shape({}),
    Yup.object().shape({
        cliente: Yup.object().shape({
            ...clienteValidation,
        }),
    }),
    Yup.object().shape({
        [vigenciaDesde.name]: Yup.date()
            .required(`${vigenciaDesde.requiredErrorMsg}`)
            .min(fecha30dias, "La fecha no puede ser anterior a 30 días del día de la fecha")
            .max(fecha45dias, `La fecha no puede ser posterior a ${formatDate(fecha45dias)}`),
        [formaDePago.name]: Yup.string().required(`${formaDePago.requiredErrorMsg}`),
        ...infoDePagoValidation,
    }),
    Yup.object().shape({
        ...detallesValidation,
    }),
];

const customerMicrosegurosValidation = [
    Yup.object().shape({
        [codigosLocalidad.name]: Yup.string().required(`${codigosLocalidad.requiredErrorMsg}`), // poner el formato que corresponda
        [tipoVivienda.name]: Yup.string().required(`${tipoVivienda.requiredErrorMsg}`),
        [tamanioVivienda.name]: Yup.string().required(`${tamanioVivienda.requiredErrorMsg}`),
        cliente: Yup.object().shape({
            [nombre.name]: Yup.string()
                .required(`${nombre.requiredErrorMsg}`)
                .matches(/^[aA-zZ\s]+$/, "Ingrese un nombre válido")
                .max(40, "El nombre ingresado es muy largo"),
            [apellido.name]: Yup.string()
                .required(`${apellido.requiredErrorMsg}`)
                .matches(/^[aA-zZ\s]+$/, "Ingrese un apellido válido")
                .max(40, "El apellido ingresado es muy largo"),
            [email.name]: Yup.string()
                .required(`${email.requiredErrorMsg}`)
                .email("Ingrese un email válido"),
        }),
        coberturasAdicionales: Yup.object().test('product-selection', 'Debe seleccionar al menos un producto', coberturas => {
            let isThereAnySelected = false;
            for (let cob in coberturas) {
                if (coberturas[cob].selectedCobertura === true) {
                    isThereAnySelected = true;
                }
            }
            return isThereAnySelected
        })
    }),
    Yup.object().shape({}),
    Yup.object().shape({
        cliente: Yup.object().shape({
            ...clienteValidation,
        })
    }),
    Yup.object().shape({
        [vigenciaDesde.name]: Yup.date()
            .required(`${vigenciaDesde.requiredErrorMsg}`)
            .min(fecha30dias, "La fecha no puede ser anterior a 30 días del día de la fecha")
            .max(fecha45dias, `La fecha no puede ser posterior a ${formatDate(fecha45dias)}`),
        [formaDePago.name]: Yup.string().required(`${formaDePago.requiredErrorMsg}`),
        ...infoDePagoValidation,
    }),
    Yup.object().shape({
        ...detallesValidation,
    }),
];

const customerAutomotorValidation = [
        Yup.object().shape({
            cliente: Yup.object().shape({
                [nombre.name]: Yup.string()
                    .required(`${nombre.requiredErrorMsg}`)
                    .matches(/^[aA-zZ\s]+$/, "Ingrese un nombre válido")
                    .max(40, "El nombre ingresado es muy largo"),
                [apellido.name]: Yup.string()
                    .required(`${apellido.requiredErrorMsg}`)
                    .matches(/^[aA-zZ\s]+$/, "Ingrese un apellido válido")
                    .max(40, "El apellido ingresado es muy largo"),
                [email.name]: Yup.string()
                    .required(`${email.requiredErrorMsg}`)
                    .email("Ingrese un email válido"),
                [telefono.name]: Yup.string()
                    .test('telefono', "El celular ingresado no es válido",
                        (value) => {
                            if (typeof value === "string")
                                return (isValidPhoneNumber(value))
                            else return false
                        }
                    )
            }),
            [codigosLocalidad.name]: Yup.string().required(`${codigosLocalidad.requiredErrorMsg}`),
            vehiculo: Yup.object().shape({
                [valorGnc.name]: Yup.number().when([tieneGnc.name], {
                    is: 'true',
                    then: Yup.number().required(`${valorGnc.requiredErrorMsg}`)
                        .min(150000, "El valor ingresado es muy bajo")
                        .max(1000000, "El valor máximo es $1.000.000")
                }),
                [ceroKm.name]: Yup.string(),
            }),
            configuracionAvanzada: Yup.object().shape({
                Zurich: Yup.object().shape({
                    mesAnioFacturacion: Yup.date()
                        .nullable()
                        .test(
                        'mesAnioFacturacion-conditional-required',
                            `${mesAnioFacturacion.requiredErrorMsg}`,
                        function (value) {
                            const {vehiculo} = this.from[2].value;

                            if (vehiculo && vehiculo.ceroKm === 'true') {
                                return value != null;
                            }
                            return true;
                        }
                    ),
                })
            })

        }),
        Yup.object().shape({}),
        Yup.object().shape({}),
        Yup.object().shape({
            cliente: Yup.object().shape({
                ...clienteValidation,
            }),
            ...infoDePagoValidation,
        }),
        Yup.object().shape({
            vehiculo: Yup.object().shape({
                [patente.name]: Yup.string().when([ceroKm.name], {
                    is: (val) => {
                        return (val !== 'true')
                    },
                    then:
                        Yup.string()
                            .required(`${patente.requiredErrorMsg}`)
                            .matches(/^[a-zA-Z]{2,2}[0-9]{3,3}[a-zA-Z]{2,2}|[a-zA-Z]{3,3}[0-9]{3,3}$/, "La patente ingresada no es válida"),
                }),
                [motor.name]: Yup.string()
                    .required(`${motor.requiredErrorMsg}`)
                    .matches(/^(?=[a-zA-Z0-9()_+=,.:\s-]*$)/, "Por favor, evite caracteres especiales")
                    .min(4, 'El número ingresado es muy corto')
                    .max(20, "El número ingresado es muy largo"),
                [chasis.name]: Yup.string()
                    .required(`${chasis.requiredErrorMsg}`)
                    .matches(/^(?=[a-zA-Z0-9()_+=,.:\s-]*$)/, "Por favor, evite caracteres especiales")
                    .min(4, 'El número ingresado es muy corto')
                    .max(20, "El número ingresado es muy largo"),
                [anio.name]: Yup.number()
                    .required(`${anio.requiredErrorMsg}`)
                    .integer()
                    .min(2001, "El año ingresado es muy antiguo")
                    .max(moment().format("YYYY"), "Ingrese un año válido")
                    .typeError("Ingrese un año válido"),
                [usoVehiculo.name]: Yup.string().required(`${usoVehiculo.requiredErrorMsg}`),
                [valor.name]: Yup.number()
                    .required(`${valor.requiredErrorMsg}`)
                    .typeError("El valor ingresado no es válido"),
                [tieneGnc.name]: Yup.string().required(`${tieneGnc.requiredErrorMsg}`),
                [ceroKm.name]: Yup.string().when([anio.name], {
                    is: moment().format('YYYY'),
                    then: Yup.string().required(`${ceroKm.requiredErrorMsg}`),
                }),
                gnc: Yup.object().when([tieneGnc.name], {
                    is: 'true',
                    then: Yup.object().shape({
                        [descripcionGnc.name]: Yup.string()
                            .required(`${descripcionGnc.requiredErrorMsg}`)
                            .matches(/^([A-Za-z0-9 -]+)$/, "Esta utilizando carácteres no válidos"),
                        [marcaRegulador.name]: Yup.string()
                            .required(`${marcaRegulador.requiredErrorMsg}`)
                            .matches(/^([A-Za-z0-9 -]+)$/, "Esta utilizando carácteres no válidos"),
                        [numeroRegulador.name]: Yup.string()
                            .required(`${numeroRegulador.requiredErrorMsg}`)
                            .matches(/^([A-Za-z0-9 -]+)$/, "Esta utilizando carácteres no válidos"),
                        [valorTotal.name]: Yup.number()
                            .required(`${valorTotal.requiredErrorMsg}`)
                            .min(1000, "El monto mínimo es $1.000")
                            .max(2000000, "El monto máximo es $2.000.000"),
                        cilindros: Yup.array().of(
                            Yup.object().shape({
                                [marcaCilindro.name]: Yup.string()
                                    .required(`${marcaCilindro.requiredErrorMsg}`)
                                    .matches(/^([A-Za-z0-9 -]+)$/, "Esta utilizando carácteres no válidos"),
                                [numeroCilindro.name]: Yup.string()
                                    .required(`${numeroCilindro.requiredErrorMsg}`)
                                    .matches(/^([A-Za-z0-9 -]+)$/, "Esta utilizando carácteres no válidos"),
                            })
                        )
                    })
                }),
                inspeccionPrevia: Yup.object().shape({
                    [comentariosInspeccion.name]: Yup.string().matches(/^(?=[a-zA-Z0-9()_+=,.:\s-]*$)/, "Por favor, evite caracteres especiales"),
                    [fechaInspeccion.name]: Yup.date().required(`${fechaInspeccion.requiredErrorMsg}`),
                    [tipoCombustible.name]: Yup.string().required(`${tipoCombustible.requiredErrorMsg}`),
                    [estadoCristales.name]: Yup.string().required(`${estadoCristales.requiredErrorMsg}`),
                    [resultadoInspeccion.name]: Yup.string().required(`${resultadoInspeccion.requiredErrorMsg}`),
                    [kilometraje.name]: Yup.number()
                        .required(`${kilometraje.requiredErrorMsg}`)
                        .integer()
                        .typeError("El valor ingresado no es válido"),
                    [colorVehiculo.name]: Yup.string()
                        .required(`${colorVehiculo.requiredErrorMsg}`)
                        .matches(/^[A-Za-z ]+$/, "Utilice letras mayúsculas y números"),
                    [archivos.name]: Yup.array()
                        .min(1, "Debe Ingresar al menos un archivo")
                        .of(
                            Yup.object().shape({
                                type: Yup.string().required("Asigne las etiquetas a los archivos")
                            })
                        )
                }),
                [marca.name]: Yup.string()
                    .required(`${marca.requiredErrorMsg}`)
                    .matches(/^[A-Za-z0-9 ,-]+$/, "Esta utilizando carácteres no válidos"),
                // [modelo.name]: Yup.string()
                //     .required(`${modelo.requiredErrorMsg}`)
                //     .matches(/^[A-Za-z0-9 ,-]+$/, "Esta utilizando carácteres no válidos"),
                [valorAdicional.name]: Yup.number()
                    .required(`${valorAdicional.requiredErrorMsg}`)
                    .min(0, "El monto mínimo es 0")
                    .max(2000000, "El monto máximo es $2.000.000"),
                [valorGnc.name]: Yup.string().when([tieneGnc.name], {
                    is: 'true',
                    then: Yup.string().required(`${valorGnc.requiredErrorMsg}`)
                        .matches(/^[1-9]\d*$/, "El valor ingresado no es válido")
                        .min(4, "El valor ingresado es muy bajo")
                }),
            })
        }),
    ]
;


const customerMotovehiculoValidation = [
    Yup.object().shape({
        cliente: Yup.object().shape({
            [nombre.name]: Yup.string()
                .required(`${nombre.requiredErrorMsg}`)
                .matches(/^[aA-zZ\s]+$/, "Ingrese un nombre válido")
                .max(40, "El nombre ingresado es muy largo"),
            [apellido.name]: Yup.string()
                .required(`${apellido.requiredErrorMsg}`)
                .matches(/^[aA-zZ\s]+$/, "Ingrese un apellido válido")
                .max(40, "El apellido ingresado es muy largo"),
            [email.name]: Yup.string()
                .required(`${email.requiredErrorMsg}`)
                .email("Ingrese un email válido"),
            [telefono.name]: Yup.string()
                .test('telefono', "El celular ingresado no es válido",
                    (value) => {
                        if (typeof value === "string")
                            return (isValidPhoneNumber(value))
                        else return false
                    }
                )
        }),
        [codigosLocalidad.name]: Yup.string().required(`${codigosLocalidad.requiredErrorMsg}`),
    }),
    Yup.object().shape({}),
    Yup.object().shape({
        cliente: Yup.object().shape({
           ...clienteValidation,
        }),
        ...infoDePagoValidation,
    }),
    Yup.object().shape({
        vehiculo: Yup.object().shape({
            [patente.name]: Yup.string().test('patente', 'La patente ingresada no es válida', (value, context) => {
                const regex1 = /^[A-Z]\d{3}[a-zA-Z]{3}$/i;
                const regex2 = /^\d{3}[a-zA-Z]{3}$/i;
                const ceroKmFieldValue = context.parent.ceroKm;

                /**
                 * If ceroKmFieldValue === "true"
                 *     ? value could be undefined or (if its defined) pass the regex test
                 *     : value has to pass the regex test
                 * */
                return ceroKmFieldValue === "true"
                    ? !value || [regex1, regex2].some(e => e.test(value))
                    : [regex1, regex2].some(e => e.test(value));
            }),
            [motor.name]: Yup.string()
                .required(`${motor.requiredErrorMsg}`)
                .matches(/^(?=[a-zA-Z0-9()_+=,.:\s-]*$)/, "Por favor, evite caracteres especiales")
                .min(4, 'El número ingresado es muy corto')
                .max(20, "El número ingresado es muy largo"),
            [chasis.name]: Yup.string()
                .required(`${chasis.requiredErrorMsg}`)
                .matches(/^(?=[a-zA-Z0-9()_+=,.:\s-]*$)/, "Por favor, evite caracteres especiales")
                .min(4, 'El número ingresado es muy corto')
                .max(20, "El número ingresado es muy largo"),
            [anio.name]: Yup.number()
                .required(`${anio.requiredErrorMsg}`)
                .integer()
                .min(2001, "El año ingresado es muy antiguo")
                .max(moment().format("YYYY"), "Ingrese un año válido")
                .typeError("Ingrese un año válido"),
            [usoVehiculo.name]: Yup.string().required(`${usoVehiculo.requiredErrorMsg}`),
            [valor.name]: Yup.number()
                .required(`${valor.requiredErrorMsg}`)
                .typeError("El valor ingresado no es válido"),
            [ceroKm.name]: Yup.string().when([anio.name], {
                is: moment().format('YYYY'),
                then: Yup.string().required(`${ceroKm.requiredErrorMsg}`),
            }),
            inspeccionPrevia: Yup.object().shape({
                [comentariosInspeccion.name]: Yup.string().matches(/^(?=[a-zA-Z0-9()_+=,.:\s-]*$)/, "Por favor, evite caracteres especiales"),
                [fechaInspeccion.name]: Yup.date().required(`${fechaInspeccion.requiredErrorMsg}`),
                [archivos.name]: Yup.array()
                    .min(1, "Debe Ingresar al menos un archivo")
                    .of(
                        Yup.object().shape({
                            type: Yup.string().required("Asigne las etiquetas a los archivos")
                        })
                    )
            }),
            [marca.name]: Yup.string()
                .required(`${marca.requiredErrorMsg}`)
                .matches(/^[A-Za-z0-9 ,-]+$/, "Esta utilizando carácteres no válidos"),
            // [modelo.name]: Yup.string()
            //     .required(`${modelo.requiredErrorMsg}`)
            //     .matches(/^[A-Za-z0-9 ,-]+$/, "Esta utilizando carácteres no válidos"),
            [valorAdicional.name]: Yup.number()
                .required(`${valorAdicional.requiredErrorMsg}`)
                .min(0, "El monto mínimo es 0")
                .max(2000000, "El monto máximo es $2.000.000"),
        })
    }),
];


export {
    customerMicrosegurosValidation,
    customerCombinadoValidation,
    customerAutomotorValidation,
    customerMotovehiculoValidation
};