import moment from "moment/moment";

const fillAdvancedConfigByCompany = (companies, values, productCodesData, locationCodes, localidadOptions) => {
    let advancedConfig = {};
    const HARDCODED_DISCOUNT = 0;

    for (let company of companies) {
        const companyCodesObj = productCodesData.find(item => item.id_aseguradora === company);
        const discount = companyCodesObj.descuentos.general;
        const discountFP = companyCodesObj.descuentos.fuera_pauta;
        switch (company) {
            case 1: //Sancor
                advancedConfig = {
                    ...advancedConfig,
                    "Sancor": {
                        ...values.configuracionAvanzada.Sancor,
                        "codigos": companyCodesObj.codigos,
                        "codSancorLocalidad": `${locationCodes.CodigoLocalidadSancor}`,
                        "comision": companyCodesObj.comisiones.general,
                        "tipoUso": "2",
                        // "descuentoEspecial": discount > 0 ? (discount - 5) : discount
                        // This is a business rule now
                        "descuentoEspecial": HARDCODED_DISCOUNT,
                        "descuentoFueraPauta": discountFP,
                        "descuentoModelo": 99,
                    }
                }
                break;
            case 2: //RUS
                advancedConfig = {
                    ...advancedConfig,
                    "RUS": {
                        ...values.configuracionAvanzada.RUS,
                        "codigos": companyCodesObj.codigos,
                        "codRUSLocalidad": `${locationCodes.CodigoLocalidadRUS}`,
                        "comision": companyCodesObj.comisiones.general,
                        "descuentoEspecial": discount > 0 ? (discount - 5) : discount,
                    }
                }
                break
            case 3: //Zurich
                advancedConfig = {
                    ...advancedConfig,
                    "Zurich": {
                        ...values.configuracionAvanzada.Zurich,
                        "codigos": companyCodesObj.codigos,
                        "codZurichLocalidad": `${locationCodes.CodigoLocalidadZurich}`,
                        "codProvincia": localidadOptions[0].idProvincia,
                        "idViaCobro": '7',
                        "vigenciaDesde": moment().format('YYYY-MM-DD'),
                        "vigenciaHasta": moment().add(1, "Y").format("YYYY-MM-DD"),
                        "comision": companyCodesObj.comisiones.general
                    }
                }
                break;
            default:
                advancedConfig = {
                    ...advancedConfig
                }
                break;
        }
    }

    return advancedConfig;
}

export {fillAdvancedConfigByCompany};