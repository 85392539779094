import React, {useContext, useEffect, useState} from 'react';
import {Tabs} from '@mantine/core';
import CoberturaCard from "../../CoberturaCard";
import {currencyFormat} from "../../Utils/currencyFormat";
import {useFormikContext} from "formik";
import MotovehiculoContext from "../../../context/products/MotovehiculoContext/MotovehiculoContext";
import { CarouselWrapper } from '../../CarouselWrapper';

const descriptionDict = {
    "resp_civil": "Seguro contra Terceros únicamente.",
    "basico": "Además del seguro contra Terceros se cubre Incendio y Robo total.",
    "completo": "Además del seguro contra Terceros se cubre Incendio, Robo y Accidente total.",
    "full": "Además del seguro contra Terceros se cubre Incendio y Robo total y parcial, y Accidente total.",
    "todo_riesgo": "Se cubren los daños parciales superiores a la franquicia variable que elijas."
}

function StyledTabs(props) {
    return (
        <div className="tabs__margin--inverse">
            <Tabs
                unstyled
                keepMounted={false}
                classNames={{
                    tabIcon: "tabs__icon",
                    tabsList: "tabs__list",
                }}
                {...props}
            />
        </div>

    );
}

function getLowestPremium(objeto) {
    if (!Array.isArray(objeto) || objeto.length === 0) {
        return null;
    }

    // Inicializar el premium con el primer valor del primer elemento
    let premiumMasChico = objeto[0].franchises[0].premium;

    // Buscar el premium más bajo
    for (const elemento of objeto) {
        for (const franchis of elemento.franchises) {
            if (franchis.premium < premiumMasChico) {
                premiumMasChico = franchis.premium;
            }
        }
    }

    return premiumMasChico;
}

const carouselCardsBuilder = (_cards, _type, _finalValues, _currentProduct) => {
    return (
        <CarouselWrapper slideSize="auto" withControls slideGap="xl"
            slidesToScroll={1}
            initialSlide={0}
            widthIndicators={false}
            withDots={false}
            draggable >
            {_cards.map(_cardInfo => {
                let cardInfo = _cardInfo
                let description = descriptionDict[_type]

                if (_type === "todo_riesgo") {
                    let premiumsArray = _cardInfo['franchises'].map(el => el.premium).reverse();
                    let franchisesArray = _cardInfo['franchises'].map(el => el.franchise).reverse();
                    let idsArray = _cardInfo['franchises'].map(el => el.id).reverse();

                    cardInfo = {
                        "id": idsArray,
                        "compania": _cardInfo.compania,
                        "hasFranchise": true,
                        "nombre": _cardInfo.nombre,
                        "premium": premiumsArray,
                        "franchises": franchisesArray,
                        "parametrosAdicionales": _cardInfo.parametrosAdicionales,
                    };
                }

                // Adjust RUS premium to consider the discount
                // It is done using displayPremium because the premium needs to keep the original value
                // otherwise on every change of the tab, the premium would be recalculated and the discount would be applied again
                if (cardInfo.compania === "RUS") {
                    if (cardInfo.hasFranchise) {
                        cardInfo.displayPremium = cardInfo.premium.map(premium => Math.ceil(premium *
                            (1 - (_finalValues['configuracionAvanzada']['RUS']['descuentoEspecial'] / 100))));
                    } else {
                        cardInfo.displayPremium = Math.ceil(cardInfo.premium *
                            (1 - (_finalValues['configuracionAvanzada']['RUS']['descuentoEspecial'] / 100)));
                    }
                } else { // Required to use the new key
                    cardInfo.displayPremium = cardInfo.premium;
                }

                return (
                    <CoberturaCard
                        finalValues={_finalValues}
                        currentProduct={_currentProduct}
                        cardInfo={{ ...cardInfo, "description": description }} />
                )
            })}
        </CarouselWrapper>
    )
}

function CotizacionSuccess(props) {
    const {finalValues, cotizacionResponse, currentProduct} = props;
    const plans = cotizacionResponse.planCD
    const filteredPlans = {};

    for (const key in plans) {
        if (Array.isArray(plans[key]) && plans[key].length > 0) {
            filteredPlans[key] = plans[key];
        }
    }

    const plansName = Object.keys(filteredPlans);

    const [activeTab, setActiveTab] = useState(filteredPlans.hasOwnProperty("completos") ? 'completos' : plansName[0]);
    const {values} = useFormikContext();
    const {vehicleDetails} = useContext(MotovehiculoContext)

    useEffect(() => {
        if (cotizacionResponse !== "Error") {
            values.idCotizar = cotizacionResponse.idCotizar;
        }
    }, []);

    return (
        <React.Fragment>
            {cotizacionResponse === "Error"
                ?
                <div className="structure__margin--topLG">
                    <p className="form__subtitle structure__center">
                        Hubo un problema al cotizar. Por favor, vuelva a intentarlo más tarde
                    </p>
                </div>
                :
                <>
                    <div className="structure__margin--top structure__column">
                        <p className="form__text--dark" style={{textAlign: "center"}}>
                            {`${vehicleDetails.current.detalle_marca} ${vehicleDetails.current.detalle_version} (${values.vehiculo.anio})`}
                        </p>
                    </div>

                    <StyledTabs value={activeTab} onTabChange={setActiveTab}>
                        <Tabs.List grow position="center">
                            {cotizacionResponse['planCD']['resp_civil'].length > 0 &&
                            <Tabs.Tab value="resp_civil" className={activeTab === "resp_civil" ? "tabs--active" : "tabs"}>
                                <div className="tabs__container">
                                    <p className="tabs__text">Responsabilidad Civil</p>
                                    <p className="tabs__text">$ {currencyFormat(cotizacionResponse['planCD']['resp_civil'][0]['premium'])}</p>
                                </div>
                            </Tabs.Tab>
                            }
                            {cotizacionResponse['planCD']['basicos'].length > 0 &&
                            <Tabs.Tab value="basicos" className={activeTab === "basicos" ? "tabs--active" : "tabs"}>
                                <div className="tabs__container">
                                    <p className="tabs__text">Tercero Básico</p>
                                    <p className="tabs__text">$ {currencyFormat(cotizacionResponse['planCD']['basicos'][0]['premium'])}</p>
                                </div>
                            </Tabs.Tab>
                            }
                            {cotizacionResponse['planCD']['completos'].length > 0 &&
                            <Tabs.Tab value="completos" className={activeTab === "completos" ? "tabs--active" : "tabs"}>
                                <div className="tabs__container">
                                    <p className="tabs__text">Tercero Completo</p>
                                    <p className="tabs__text">$ {currencyFormat(cotizacionResponse['planCD']['completos'][0]['premium'])}</p>
                                </div>
                            </Tabs.Tab>
                            }
                            {cotizacionResponse['planCD']['full'].length > 0 &&
                            <Tabs.Tab value="full"
                                      className={activeTab === "full" ? "tabs--active" : "tabs"}>
                                <div className="tabs__container">
                                    <p className="tabs__text">Full</p>
                                    <p className="tabs__text">$ {currencyFormat(cotizacionResponse['planCD']['completos'][0]['premium'])}</p>
                                </div>
                            </Tabs.Tab>
                            }
                            {cotizacionResponse['planCD']['todo_riesgo'].length > 0 &&
                            <Tabs.Tab value="todo_riesgo"
                                      className={activeTab === "todo_riesgo" ? "tabs--active" : "tabs"}>
                                <div className="tabs__container">
                                    <p className="tabs__text">{"Todo Riesgo"}</p>
                                    <p className="tabs__text">Desde
                                        $ {currencyFormat(getLowestPremium(cotizacionResponse['planCD']['todo_riesgo']))}</p>
                                </div>
                            </Tabs.Tab>
                            }
                        </Tabs.List>

                        <div className="tabs__margin">
                            {cotizacionResponse['planCD']['resp_civil'].length > 0 &&
                            <Tabs.Panel value="resp_civil" pt="xs">
                                {carouselCardsBuilder(cotizacionResponse['planCD']['resp_civil'], "resp_civil", finalValues, currentProduct)}
                            </Tabs.Panel>
                            }
                            {cotizacionResponse['planCD']['basicos'].length > 0 &&
                            <Tabs.Panel value="basicos" pt="xs">
                                {carouselCardsBuilder(cotizacionResponse['planCD']['basicos'], "basico", finalValues, currentProduct)}
                            </Tabs.Panel>
                            }
                            {cotizacionResponse['planCD']['completos'].length > 0 &&
                            <Tabs.Panel value="completos" pt="xs">
                                {carouselCardsBuilder(cotizacionResponse['planCD']['completos'], "completo", finalValues, currentProduct)}
                            </Tabs.Panel>
                            }
                            {cotizacionResponse['planCD']['full'].length > 0 &&
                            <Tabs.Panel value="full" pt="xs">
                                {carouselCardsBuilder(cotizacionResponse['planCD']['full'], "full", finalValues, currentProduct)}
                            </Tabs.Panel>
                            }
                            {cotizacionResponse['planCD']['todo_riesgo'].length > 0 &&
                            <Tabs.Panel value="todo_riesgo" pt="xs">
                                {carouselCardsBuilder(cotizacionResponse['planCD']['todo_riesgo'], "todo_riesgo", finalValues, currentProduct)}
                            </Tabs.Panel>
                            }
                        </div>

                    </StyledTabs>
                </>
            }
        </React.Fragment>
    );
}

export default CotizacionSuccess;
