import React, {useEffect} from 'react';
import SelectField from "../../FormFields/SelectField";
import InputField from "../../FormFields/InputField";
import {useFormikContext} from "formik";
import DateField from "../../FormFields/DateField";

export default function PolizaForm(props) {
    const {
        formField: {
            vigenciaDesde,
            vigenciaHasta,
            formaDePago,
            infoDePago: {
                numeroCbu,
                marcaTarjeta,
                numeroTarjeta,
            },
        }
    } = props;
    const {values, setFieldValue} = useFormikContext();

    const formasDePago = [
        // {value: '0', label: 'Efectivo'},
        {value: '4', label: 'Débito por CBU'},
        {value: '2', label: 'Tarjeta de crédito'}
    ];

    const idsTarjeta = [
        {value: '1', label: 'Cabal'},
        {value: '5', label: 'Visa'},
        {value: '7', label: 'Favacard'},
        {value: '9', label: 'American Express'},
        {value: '12', label: 'Naranja'},
        {value: '16', label: 'Diners Club'},
        {value: '18', label: 'Naranja Visa'},
        {value: '20', label: 'Cordobesa - Matercard'},
        {value: '23', label: 'TDF - Tierra del Fuego'},
        {value: '45', label: 'Mastercard Nueva'},
        {value: '57', label: 'ELEBAR'},
        {value: '70', label: 'Censosud  Mastercard'},
    ];


    useEffect(() => {
        const dateCopy = new Date(values.vigenciaDesde);

        dateCopy.setFullYear(values.vigenciaDesde.getFullYear() + 1);

        setFieldValue("vigenciaHasta", dateCopy);
    }, [values.vigenciaDesde])

    return (
        <React.Fragment>
            <div className="structure__form structure__center">
                <DateField
                    name={vigenciaDesde.name}
                    label={vigenciaDesde.label}
                    allowFreeInput = {false}
                />
                <DateField
                    name={vigenciaHasta.name}
                    label={vigenciaHasta.label}
                    allowFreeInput = {false}
                    disabled
                />
                <SelectField
                    name={formaDePago.name}
                    label={formaDePago.label}
                    data={formasDePago}
                />
                {values.formaDePago === '2' && (
                    <>
                        <SelectField
                            name={`infoDePago.${marcaTarjeta.name}`}
                            label={marcaTarjeta.label}
                            data={idsTarjeta}
                        />
                        <InputField
                            name={`infoDePago.${numeroTarjeta.name}`}
                            label={numeroTarjeta.label}
                        />
                    </>
                )}
                {values.formaDePago === '4' && (
                    <InputField
                        name={`infoDePago.${numeroCbu.name}`}
                        label={numeroCbu.label}
                    />
                )}
            </div>
        </React.Fragment>
    );
}
