import React, {useContext, useState} from 'react';
import './SearchZipCodeModal.css';
import {Grid} from "@mantine/core";
import {useFormikContext} from "formik";
import {get} from "lodash";
import SelectField from "../Formularios/FormFields/SelectField";
import ClienteDirectoContext from "../../context/ClienteDirectoContext";
import {PopUpCardModal} from "../PopUpCardModal";
import {getLocalidadOptionsByMoreData} from "../Utils/getLocalidadOptionsByMoreData";

function SearchZipCodeModal(props) {
    const {codigosLocalidadField, codigoPostalField, provinciaField, setModalToSearch, localidadOptions, setLocalidadOptions, setProvinciasOptions} = props;
    const {values, setFieldValue} = useFormikContext();
    const {provinciasList} = useContext(ClienteDirectoContext)
    const [localidadName, setLocalidadName] = useState('');
    const [loading, setLoading] = useState(false);


    const getLocalidadOptionsAsync = async () => {
        if (loading) return
        try {
            const response = await getLocalidadOptionsByMoreData(get(values, provinciaField.name), localidadName);
            setLocalidadOptions(response);
        } catch (error) {
            console.error('Error al obtener opciones de localidad', error);
        } finally {
            setLoading(false);
        }
    };

    const handleConfirmLocation = () => {
        const objetoDeseado = localidadOptions.find(obj => obj.value === get(values, codigosLocalidadField.name));
        const provinceSelected = provinciasList.find(provincia => provincia.value === objetoDeseado.idProvincia)
        setFieldValue(codigoPostalField.name, objetoDeseado.CodPostal);
        setFieldValue(provinciaField.name, provinceSelected.value);
        setProvinciasOptions([provinceSelected]);
        setModalToSearch(false);
    };

    const handleProvinceChange = () => {
        setLocalidadName('');
        setFieldValue(codigosLocalidadField.name, '');
        setLocalidadOptions([]);
    }

    const handleLocalidadChange = (event) => {
        setLocalidadName(event);

        if (event.length === 4) {
            getLocalidadOptionsAsync();
        }

        if (event.length < 4) {
            setLocalidadOptions([]);
        }
    };


    return (
        <PopUpCardModal
            title="Localizar Código Postal"
            body={
                <div className="searchZipCodeModal__container">
                    <Grid>
                        <Grid.Col xs={12} sm={6}>
                            <SelectField
                                name={provinciaField.name}
                                label={provinciaField.label}
                                data={provinciasList}
                                withAsterisk
                                searchable
                                onChangeAction={handleProvinceChange}
                            />
                        </Grid.Col>
                        <Grid.Col xs={12} sm={6}>
                            <SelectField
                                name={codigosLocalidadField.name}
                                label={codigosLocalidadField.label}
                                searchValue={localidadName}
                                onSearchChange={handleLocalidadChange}
                                data={localidadOptions || []}
                                searchable
                                withAsterisk
                                notShowData={localidadOptions.length === 0}
                                disabled={get(values, provinciaField.name) === ''}
                                filter={(value, item) => {
                                    // Si no hay datos o si el valor de búsqueda tiene menos de 4 caracteres, no filtrar
                                    if (localidadOptions.length === 0 || value.length < 4) {
                                        return true;
                                    }

                                    // Filtrar basándose en el valor de búsqueda
                                    return item.label.toLowerCase().includes(value.toLowerCase().trim());
                                }}
                            />
                        </Grid.Col>
                    </Grid>
                </div>
            }
            closeAction={() => setModalToSearch(false)}
            footer={
                <div className="csm__button__container">
                    <button
                            type="button"
                            className="button"
                            onClick={() => handleConfirmLocation()}
                    >
                        Confirmar
                    </button>
                </div>
            }
        />
    );
}

export default SearchZipCodeModal;