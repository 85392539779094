import "./css/styles.css";
import {
    Route, Routes,
} from "react-router-dom";
import {compose} from "recompose";
import React, {useContext} from "react";
import Landing from "./views/Landing";
import NavBar from "./components/NavBar";
import Formularios from "./views/Formularios";
import ClienteDirectoContext, {withClienteDirectoContextProvider, withClienteDirectoContextReady} from "./context/ClienteDirectoContext";
import WhatsAppFab from "./components/WhatsAppFAB";

function App() {
    const {templateId} = useContext(ClienteDirectoContext);
    const themesDict = {
        0: "neutral-theme",
        1: "sancor-theme",
        2: "rus-theme",
        3: "polko-theme",
    }
    return (
        <div className={themesDict[templateId]}>
            <NavBar />
            <Routes>
                <Route path="/:encodedId" element={<Landing/>}/>
                <Route path="/:encodedId/cotizar/:producto" element={<Formularios />}/>
            </Routes>
            <WhatsAppFab />
        </div>

    );
}

export default compose(
    withClienteDirectoContextProvider,
    withClienteDirectoContextReady,
)(App);
