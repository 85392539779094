import axios from "axios";

function getMotoModels(idCD, marca, anio) {
    let carVersionsUrl = process.env.REACT_APP_BACK_SERVER + '/getVersionesMotos'
    return axios.get(carVersionsUrl, {
        params: {
            'idcanaldigital': idCD,
            'marca': marca,
            'anio': anio
        }
    });
}

export default getMotoModels;