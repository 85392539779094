import React, {useState} from 'react';
import {useField} from 'formik';
import {DatePickerInput} from '@mantine/dates';
import moment from "moment";
import {ReactComponent as CalendarIcon} from "../../../assets/systemIcons/CalendarIcon.svg";

const MantineDatePickerTwoFields = (props) => {
    const {label, name, onChangeAction} = props;
    const firstFieldName = name[0];
    const secondFieldName = name[1];
    const [firstField, firstMeta, firstHelpers] = useField(firstFieldName);
    const [secondField, secondMeta, secondHelpers] = useField(secondFieldName);
    const [firstValue, setFirstValue] = useState(firstField.value === "" ? "" : moment(firstField.value, 'YYYY-MM-DD').toDate());
    const [secondValue, setSecondValue] = useState(secondField.value === "" ? "" : moment(secondField.value, 'YYYY-MM-DD').toDate());
    const {setValue: setFirstFieldValue, setTouched: setFirstTouched} = firstHelpers;
    const {setValue: setSecondFieldValue, setTouched: setSecondTouched} = secondHelpers;

    return (
        <DatePickerInput
            {...props}
            label={label}
            value={[firstValue, secondValue]}
            onBlur={() => {
                setFirstFieldValue(firstValue)
                setSecondFieldValue(secondValue)
                setFirstTouched(firstFieldName, false)
                setSecondTouched(secondFieldName, false)
            }}
            onChange={(e) => {
                setFirstValue(e[0]);
                setSecondValue(e[1])
                onChangeAction(e)
            }}
            type="range"
            error={firstMeta.error && firstMeta.touched && <div>{firstMeta.error}</div>}
            allowFreeInput
            locale="es"
            inputFormat="DD/MM/YYYY"
            valueFormat="DD/MM/YYYY"
            classNames={{
                wrapper: 'mantine__field__wrapper',
                label: firstMeta.error && firstMeta.touched ? 'mantine__field__label--error' : 'mantine__field__label',
                required: firstMeta.error && firstMeta.touched ? 'mantine__field__required--error' : 'mantine__field__required',
                error: 'mantine__field__error',
                day: 'mantine__field__day',
                input: firstMeta.error && firstMeta.touched ? 'mantine__field__field--error' : 'mantine__field__field',
                rightSection: 'mantine__field__rightSection'
            }}
            rightSection={
                <CalendarIcon className="mantine__field__icon"/>
            }
        />
    );
};

export default MantineDatePickerTwoFields;