import React, {createRef, useContext, useEffect, useState} from 'react';
import InputField from "../../FormFields/InputField";
import SelectField from "../../FormFields/SelectField";
import ClienteDirectoContext from "../../../../context/ClienteDirectoContext";
import PhoneInput from "react-phone-number-input";
import InputRefField from "../../FormFields/InputRefField";
import {useFormikContext} from "formik";
import { ZipCodeFieldPackage } from '../../../ZipCodeFieldPackage';
import { Grid } from '@mantine/core';

export default function InformacionForm(props) {
    const {
        formField: {
            tamanioVivienda,
            tipoVivienda,
            cliente: {
                nombre,
                apellido,
                telefono,
                email,
                codigosLocalidad,
                codigoPostal,
                idProvincia,
            }
        },
        modifiedSumas,
    } = props;
    const {localidades} = useContext(ClienteDirectoContext);
    const [localidadOptions, setLocalidadOptions] = useState(localidades);
    const [provinciasOptions, setProvinciasOptions] = useState([]);
    const {values, setFieldValue} = useFormikContext();
    const ref = createRef();
    const {coberturasInfo} = useContext(ClienteDirectoContext);
    const tiposViviendas = [
        {value: '1021', label: 'Casa'},
        {value: '1022', label: 'Departamento'},
        {value: '1023', label: 'Vivienda en barrio cerrado'}
    ];

    const tamaniosVivienda = [
        {value: 'Chica', label: 'Pequeña (Hasta 80 mts²)'},
        {value: 'Mediana', label: 'Mediana (Hasta 150 mts²)'},
        {value: 'Grande', label: 'Grande (150 mts² o más)'},
    ];

    useEffect(() => {
        if (!modifiedSumas || values.tamanioVivienda !== modifiedSumas) {
            Object.keys(values.coberturas).map(cobertura => {
                if (values.tamanioVivienda === 'Chica')
                setFieldValue(`coberturas[${cobertura}].sumaAsegurada`, coberturasInfo[cobertura].sumaChica)
                if (values.tamanioVivienda === 'Mediana')
                setFieldValue(`coberturas[${cobertura}].sumaAsegurada`, coberturasInfo[cobertura].sumaMediana)
                if (values.tamanioVivienda === 'Grande')
                    setFieldValue(`coberturas[${cobertura}].sumaAsegurada`, coberturasInfo[cobertura].sumaGrande)
            })
            Object.keys(values.coberturasAdicionales).map(cobertura => {
                if (values.tamanioVivienda === 'Chica')
                    setFieldValue(`coberturasAdicionales[${cobertura}].sumaAsegurada`, coberturasInfo[cobertura].sumaChica)
                if (values.tamanioVivienda === 'Mediana')
                    setFieldValue(`coberturasAdicionales[${cobertura}].sumaAsegurada`, coberturasInfo[cobertura].sumaMediana)
                if (values.tamanioVivienda === 'Grande')
                    setFieldValue(`coberturasAdicionales[${cobertura}].sumaAsegurada`, coberturasInfo[cobertura].sumaGrande)
            })
        }
    }, [values.tamanioVivienda]);

    return (
        <React.Fragment>
            <Grid>
                <Grid.Col xs={12} sm={6}>
                    <SelectField
                        name={tipoVivienda.name}
                        label={tipoVivienda.label}
                        data={tiposViviendas}
                    />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <SelectField
                        name={tamanioVivienda.name}
                        label={tamanioVivienda.label}
                        data={tamaniosVivienda}
                    />
                </Grid.Col>
                <>
                    <ZipCodeFieldPackage
                        localidadField={codigosLocalidad}
                        codigoPostalField={codigoPostal}
                        provinciaField={idProvincia}
                        localidadSize={6}
                        codigoPostalSize={6}
                        provinciaSize={6}
                        localidadOptions={localidadOptions}
                        provinciasOptions={provinciasOptions}
                        setLocalidadOptions={setLocalidadOptions}
                        setProvinciaOptions={setProvinciasOptions}
                    />
                </>
                <Grid.Col xs={12} sm={6}>
                    <InputField
                        name={`cliente.${nombre.name}`}
                        label={nombre.label}
                    />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <InputField
                        name={`cliente.${apellido.name}`}
                        label={apellido.label}
                    />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <div className="field">
                        <PhoneInput
                            defaultCountry="AR"
                            ref={ref}
                            inputComponent={InputRefField}
                            name={`cliente.${telefono.name}`}
                            label={telefono.label}
                            value={values.cliente.telefono}
                            onChange={(e) => {
                                setFieldValue(`cliente.${telefono.name}`, e)
                            }}
                        />
                    </div>
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <InputField
                        name={`cliente.${email.name}`}
                        label={email.label}
                    />
                </Grid.Col>
            </Grid>
        </React.Fragment>
    );
}
