import React, {useState} from 'react';
import {useField} from 'formik';
import { DateInput } from '@mantine/dates';
import moment from "moment";
import {ReactComponent as ExclamationIcon} from "../../../assets/systemIcons/ic_exclamation.svg";
import {ReactComponent as CalendarIcon} from "../../../assets/systemIcons/CalendarIcon.svg";

const MantineDateField = (props) => {
    const {label, name} = props;
    const [field, meta, helpers] = useField(name);
    const [value, setValue] = useState(field.value && field.value !== "" ? moment(field.value, 'YYYY-MM-DD').toDate() : "");
    const {setValue: setFieldValue, setTouched} = helpers;

    return (
        <DateInput
            {...props}
            label={label}
            value={value}
            onChange={(e) => setValue(moment(e, 'DD/MM/YYYY').toDate())}
            onBlur={() => {
                setFieldValue(value)
                setTouched(name, false)
            }}
            dateParser={(dateString) => moment(dateString, 'DD/MM/YYYY').toDate()}
            error={meta.error && meta.touched && <div>{meta.error}</div>}
            allowFreeInput
            locale="es"
            placeholder="dd/mm/yyyy"
            inputFormat="DD/MM/YYYY"
            valueFormat="DD/MM/YYYY"
            classNames={{
                wrapper: 'mantine__field__wrapper',
                label: meta.error && meta.touched ? 'mantine__field__label--error' : 'mantine__field__label',
                input: meta.error && meta.touched ? 'mantine__field__field--error' : 'mantine__field__field',
                required: meta.error && meta.touched ? 'mantine__field__required--error' : 'mantine__field__required',
                error: 'mantine__field__error',
                rightSection: 'mantine__field__rightSection',
            }}
            rightSection={
                meta.error && meta.touched
                    ? <ExclamationIcon className="mantine__date__icon--error"/>
                    : <CalendarIcon className="mantine__date__icon"/>
            }
        />
    );
};

export default MantineDateField;
