import React from 'react';
import '../css/styles.css';
import {IconCheck} from "@tabler/icons"

function StepDescription(props) {
    const {step, title, description, variant} = props;
    return (
        <>
            <div className="steps__circle">
                {variant === 0
                    ?  <p className="steps__numbers">
                        <IconCheck className="steps__icons"/>
                    </p>
                    :   <p className="steps__numbers">
                        {step}
                    </p>
                }

            </div>
            <p className="steps__title">
                {title}
            </p>
            <p className="steps__text neutralTheme__hide">
                {description}
            </p>
        </>
    );
}

export default StepDescription;