import React from 'react';
import {useFormikContext} from 'formik'
import {currencyFormat} from "../../../Utils/currencyFormat";
import {Divider} from "@mantine/core";
import {formatPhoneNumberIntl} from "react-phone-number-input";
import SancorLogo from "../../../../assets/logos/SancorSeguros.png"
import RUSLogo from "../../../../assets/logos/RUSLogo.png"

export default function SolicitudForm() {
    const {values} = useFormikContext();

    const companiesLogosDict = {
        "Sancor": SancorLogo,
        "RUS": RUSLogo
    }

    return (
        <React.Fragment>
            <p className="form__text--primary" style={{textAlign:"start"}}>
                Datos de la cobertura:
            </p>
            <Divider my="sm" size="xs"/>
            <div className="form__body--noMarginTop structure__columnToRow">
                <div className="structure__margin--topXS structure__columnToRow--centered">
                    <p className="form__text--dark">Cobertura:</p>
                    <p className="form__text--light">{values.nombreCobertura}</p>
                </div>
                <img src={companiesLogosDict[values.compania]} alt="Logo aseguradora" className="form__aseguradora structure__margin--XS"/>
            </div>

            <p className="form__text--primary" style={{textAlign:"start"}}>
                Datos del vehículo:
            </p>
            <Divider my="sm" size="xs"/>
            <div className="form__body--noMarginTop">
                <div className="structure__margin--topXS structure__center structure__columnToRow--centered">
                    <p className="form__text--dark">Vehículo:</p>
                    <p className="form__text--light">{values.vehiculo.detalle_marca} {values.vehiculo.detalle_version} {values.vehiculo.anio}</p>
                </div>

                <div className="structure__margin--topXS structure__columnToRow--centered">
                    <p className="form__text--dark">Patente:</p>
                    <p className="form__text--light">{values.vehiculo.patente}</p>
                </div>
                <div className="structure__margin--topXS structure__columnToRow--centered">
                    <p className="form__text--dark">Valor vehículo:</p>
                    <p className="form__text--light">${currencyFormat(values.vehiculo.valor)}</p>
                </div>
            </div>

            <p className="form__text--primary structure__margin--topOnly" style={{textAlign:"start"}}>
                Datos del asegurado:
            </p>
            <Divider my="sm" size="xs"/>

            <div className="form__body--noMarginTop">
                <div className="structure__margin--topXS structure__columnToRow--centered">
                    <p className="form__text--dark">Nombre:</p>
                    <p className="form__text--light">{values.cliente.nombre} {values.cliente.apellido}</p>
                </div>
                <div className="structure__margin--topXS structure__columnToRow--centered">
                    <p className="form__text--dark">DNI:</p>
                    <p className="form__text--light">{values.cliente.dni}</p>
                </div>
                <div className="structure__margin--topXS structure__columnToRow--centered">
                    <p className="form__text--dark">Celular:</p>
                    <p className="form__text--light">{formatPhoneNumberIntl(values.cliente.telefono)}</p>
                </div>
                <div className="structure__margin--topXS structure__columnToRow--centered">
                    <p className="form__text--dark">E-mail:</p>
                    <p className="form__text--light">{values.cliente.email}</p>
                </div>
            </div>

            <div className="structure__margin--topOnly">
                <div className="form__price">
                    <p className="form__subtitle--white">Cuota mensual:</p>
                    <p className="form__title--white">$ {currencyFormat(values.premium.toFixed(0))}</p>
                </div>
            </div>
        </React.Fragment>
    );
}
