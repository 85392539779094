import * as Yup from "yup";

const EntradaCotizarModel = () => {
    const formField = {
        idPais: {
            name: 'idPais',
            label: 'País de destino',
            requiredErrorMsg: 'Debe seleccionar un país'
        },
        nombrePais: {
            name: 'nombrePais',
        },
        vigenciaDesde: {
            name: 'vigenciaDesde',
        },
        vigenciaHasta: {
            name: 'vigenciaHasta',
            label: 'Salida/Regreso',
            requiredErrorMsg: 'Debe ingresar el periodo del viaje'
        },
        pasajeros: {
            name: 'pasajeros',
            label: 'Pasajeros',
            requiredErrorMsg: 'Debe ingresar al menos un pasajero'
        },
        cliente: {
            nombre: {
                name: 'nombre',
                label: 'Nombre',
                requiredErrorMsg: 'Debe ingresar el nombre del cliente'
            },
            apellido: {
                name: 'apellido',
                label: 'Apellido',
                requiredErrorMsg: 'Debe ingresar el apellido del cliente'
            },
            telefono: {
                name: 'telefono',
                label: 'Teléfono',
                requiredErrorMsg: 'Debe ingresar el teléfono del cliente'
            },
            email: {
                name: 'email',
                label: 'Email',
                requiredErrorMsg: 'Debe ingresar el email del cliente'
            }
        }
    }

    const initialValues = {
        [formField.idPais.name]: '',
        [formField.nombrePais.name]: '',
        [formField.vigenciaDesde.name]: '',
        [formField.vigenciaHasta.name]: '',
        [formField.pasajeros.name]: [
            {
                edad: '',
                tipoDocumento: 'DNI',
                numeroDocumento: '',
                nombre: '',
                apellido: '',
                fechaNacimiento: '',
                genero: '',
                pais_pasajero: '11',
                provincia: '',
                codigoPostal: '',
                ciudad: '',
                calle: '',
                numero: '',
                opcional: '',
                telefono: '',
                email: '',
            },
        ],
        cliente: {
            [formField.cliente.nombre.name]: '',
            [formField.cliente.apellido.name]: '',
            [formField.cliente.telefono.name]: '',
            [formField.cliente.email.name]: '',
        }
    }

    const validationSchema = Yup.object().shape({
        [formField.idPais.name]: Yup.string().required(`${formField.idPais.requiredErrorMsg}`),
        [formField.vigenciaHasta.name]: Yup.string().required(`${formField.vigenciaHasta.requiredErrorMsg}`),
        [formField.pasajeros.name]: Yup.array()
            .of(
                Yup.object().shape({
                    age: Yup.string()
                })
            )
            .required(`${formField.pasajeros.requiredErrorMsg}`)
            .test('al-menos-uno-no-vacio', 'Debe ingresar la edad de al menos un pasajero', (pasajeros) => {
                return pasajeros.some(pasajero => pasajero.age !== '');
            }),
        cliente: Yup.object().shape({
            [formField.cliente.nombre.name]: Yup.string().required(`${formField.cliente.nombre.requiredErrorMsg}`),
            [formField.cliente.apellido.name]: Yup.string().required(`${formField.cliente.apellido.requiredErrorMsg}`),
            [formField.cliente.telefono.name]: Yup.string().required(`${formField.cliente.telefono.requiredErrorMsg}`),
            [formField.cliente.email.name]: Yup.string().required(`${formField.cliente.email.requiredErrorMsg}`)
        }),
    })

    return {formField, initialValues, validationSchema}
}

export {EntradaCotizarModel};
