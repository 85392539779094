import React from "react";
import "../css/styles.css";
import useWindowDimensions from "./Utils/useWindowDimensions";
import { Stepper } from '@mantine/core';

const ProgressBar = (props) => {
    const {activeStep, stepTitles} = props;
    const {width} = useWindowDimensions();
    const isMobile = width < 768;

    return (
        <>
            <p className="progressBar__title structure__center" >
                {stepTitles[activeStep]}
            </p>
            <Stepper active={activeStep} size="xs" iconSize={24} orientation="horizontal"
                     classNames={{
                         step: 'progressBar__step',
                         stepIcon: 'progressBar__icon',
                         stepCompletedIcon: 'progressBar__icon--completed',
                         separator: 'progressBar__separator',
                         separatorActive: 'progressBar__separator--completed',
                     }}
            >
                {stepTitles.map(step => {
                    return (
                        <Stepper.Step
                            key={step}
                            label={isMobile ? null : step}
                            description={null}/>
                    )
                })}
            </Stepper>
        </>

    )
};

export default ProgressBar;
