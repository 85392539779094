import React from "react";
import {Loader} from "@mantine/core";

const Loading = () => (
    <div className="spinner" style={{height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Loader size="lg" color="gray"/>
    </div>
);

export default Loading;
