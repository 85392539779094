export function franchiseStringToObject(str) {
    if (str.includes("Var")) {
        const varMatch = str.match(/(\d+)%/);
        const minMatch = str.match(/\$([\d]+)/);
        return {
            var: varMatch ? parseInt(varMatch[1]) : 0,
            min: minMatch ? parseInt(minMatch[1]) : 0
        };
    } else if (str.includes("Fija")) {
        const minMatch = str.match(/\$([\d]+)/);
        return {
            var: -1,
            min: minMatch ? parseInt(minMatch[1]) : 0
        };
    } else {
        return {
            var: -1,
            min: 0
        };
    }
}