import {currencyFormat} from "./currencyFormat";

function replacePercentage(displayName, franquicia) {
    const partes = displayName.split(' ');

    for (let i = 0; i < partes.length; i++) {
        const parte = partes[i];
        if (parte.includes('%')) {
            partes[i] = `$${currencyFormat(franquicia)})`;
            break;
        }
    }

    return partes.join(' ');
}

const TR_COVERAGES = [20,18,13,9,7,"T3","T20","T13","T31","T34","T32","T14","T9","T25","T24", "T37", "39", "44", "49", "40"];
/**
 * This function replaces the amount of the coverage name with the value of franquicia on response
 * @param _company company name
 * @param _initialName DB received name for coverage
 * @param _covId coverage id
 * @param _franquicia franquicia value received on response
 * @returns {*} string with either same name or with replaced amount
 */
const todoRiesgoAmountReplacer = (_company, _initialName, _covId, _franquicia) => {
    let displayName = _initialName;

    //TODO: Generalizarlo
    if (TR_COVERAGES.includes(_covId) && _franquicia && (_franquicia > 0 || typeof _franquicia === "string")) {
        if (_company === "Sancor") {
            let partes = displayName.split("$");
            let partesDespuesDelMonto = partes[1].split(" ");
            partesDespuesDelMonto[0] = `$${currencyFormat(_franquicia)}`;
            partes[1] = partesDespuesDelMonto.join(" ");
            return partes.join("");
        } else if (_company === "RUS") {
            return replacePercentage(displayName, _franquicia)
        }else if (_company === "Zurich") {
            if (_franquicia.split(" ")[1] === "Fija") return _franquicia
            else return displayName
        }
    } else {
        return displayName
    }
}

export {todoRiesgoAmountReplacer};