import axios from "axios";

async function buildLocationCode(companiesArr, locationId) {
    const url = process.env.REACT_APP_BACK_SERVER + '/getCodigos';
    try {
        const response = await axios.post(url, {
                "companies": companiesArr,
                "idLocalidad": locationId
            });
        return response.data;
    } catch (error) {
        console.log(error);
        return {}
    }
}

export {buildLocationCode};