import React, {forwardRef} from "react";
import {Input} from '@mantine/core';
import {useField} from "formik";
import {ReactComponent as ExclamationIcon} from "../../../assets/systemIcons/ic_exclamation.svg";

const MantineInputRefField = (props, ref) => {
    const {name, label} = props;
    const [field, meta] = useField(name);

    return (
        <Input.Wrapper
            {...props}
            id={`wrapper-${field.name}`}
            label={label}
            error={meta.error && meta.touched && <div>{meta.error}</div>}
            styles={{
                root: {width: "100%"}
            }}
            classNames={{
                label: meta.error && meta.touched ? 'mantine__field__label--error' : 'mantine__field__label',
                required: meta.error && meta.touched ? 'mantine__field__required--error' : 'mantine__field__required',
                error: 'mantine__field__error',
            }}
        >
            <Input
                {...props}
                id={name}
                ref={ref}
                classNames={{
                    wrapper: 'mantine__field__wrapper',
                    input: meta.error && meta.touched ? 'mantine__field__field--error' : 'mantine__field__field',
                    rightSection: 'mantine__field__rightSection',
                    }}
                rightSection={
                    meta.error && meta.touched && <ExclamationIcon className="mantine__inputRef__icon"/>
                }
            />
        </Input.Wrapper>
    );
};

export default forwardRef(MantineInputRefField);