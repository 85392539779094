import axios from "axios";

async function getLocalidadOptionsByMoreData(province, locationName) {
    const url = process.env.REACT_APP_BACK_SERVER + '/getDatosLocalidades';
    try {
        const response = await axios.get(url, {params: {
                "idProvincia": province,
                "localidad": locationName
            }});
        return response.data;
    } catch (error) {
        console.log(error);
        return []
    }
}

export {getLocalidadOptionsByMoreData}