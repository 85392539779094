const updateProvinciaField = (data, currentValue, formikSetter, provinceFieldName, setProvinciaOptions, provinciasList) => {
    const idProvinciaSet = new Set(data.map(localidad => localidad.idProvincia));
    const provinceArray = Array.from(idProvinciaSet);

    if (provinceArray.length === 1) {
        const provinciaObject = provinciasList.find(provincia => provincia.value === provinceArray[0]);
        if (provinciaObject) {
            formikSetter(provinceFieldName, provinciaObject.value);
        }
        setProvinciaOptions(provinciasList.filter(provincia => provinceArray.includes(provincia.value)));
    } else if (provinceArray.length > 1) {
        handleMultipleProvinces(provinceArray, currentValue, formikSetter, provinceFieldName, setProvinciaOptions, provinciasList);
    }
};

const handleMultipleProvinces = (provinceArray, currentValue, formikSetter, provinciaFieldName, setProvinciaOptions, provinciasList) => {
    if (currentValue) {
        const matchedProvincia = provinciasList.find(provincia => provincia.value === currentValue)?.value;
        formikSetter(provinciaFieldName, matchedProvincia || '');
    }
    setProvinciaOptions(provinciasList.filter(provincia => provinceArray.includes(provincia.value)));
};

const updateLocalidadField = (data, currentValue, formikSetter, locationFieldName) => {
    if (currentValue) {
        const matchedLocalidad = data.find(localidad => localidad.value == currentValue)?.value;
        formikSetter(locationFieldName, matchedLocalidad || '');
    }
    else if (data.length === 1) {
        formikSetter(locationFieldName, data[0].value);
    }
};

export {updateProvinciaField, updateLocalidadField, handleMultipleProvinces}