import React from 'react';

const ClienteDirectoContext = React.createContext({

    habilitado: null,
    idRowPolkista: null,
    idRowPaginasPolkista: null,
    description: null,
    datosPolkista: null,
    datosMaster: null,
    redesSociales: null,
    templateId: null,
    activeProducts: null,
    logo: null,
    genericOptionsFormasDePago: null,
    autosMarcas: null,
    localidades: null,
    destinos: null,
    coberturasInfo: null,
    coberturasAdicionalesOrder: null,
    companiesDict: null,
    companiesIdDict: null,
    provinciasList: null,
    coberturasProductsInfo: null,
    isReady: false,

});

export default ClienteDirectoContext;