import React, {useContext} from 'react';
import '../css/styles.css';
import ClienteDirectoContext from "../context/ClienteDirectoContext";

function ProductCard(props) {
    const {icon, iconActive, name, variant, active, fullWidth = false} = props;
    const {templateId} = useContext(ClienteDirectoContext);

    if (templateId === 2 && active) { // If template is rus-theme and card is active
        return (
            <div className="card__container">
                    {iconActive}
                    <p className="card__text card__text--active">{name}</p>
            </div>
        );
    } else {
        return (
            <div className={variant ? "card--micro" : "card__container"} style={fullWidth ? {width: '100%'} : {}}>
                {icon}
                <p className={variant ? "card__text--micro" : "card__text"}>{name}</p>
            </div>
        );
    }
}

export default ProductCard;