import React, {useState} from 'react';
import {useField} from 'formik';
import {MonthPickerInput} from "@mantine/dates";
import moment from "moment";
import {ReactComponent as CalendarIcon} from "../../../assets/systemIcons/CalendarIcon.svg";

const MantineMonthField = (props) => {
    const {label, name} = props;
    const [field, meta, helpers] = useField(name);
    const [value, setValue] = useState(field.value === "" ? null : moment(field.value, 'YYYY-MM').toDate());
    const {setValue: setFieldValue, setTouched} = helpers;



    return (
        <div className="field">
            <MonthPickerInput
                {...props}
                label={label}
                value={value}
                onChange={(e) => {
                    const newDate = moment(e, 'MM/YYYY').toDate();
                    setValue(newDate);
                    setTouched(name, true); // Agrega esta línea
                    setFieldValue(newDate);
                }}
                dateParser={(dateString) => moment(dateString, 'MM/YYYY').toDate()}
                error={meta.error && meta.touched && <div>{meta.error}</div>}
                allowFreeInput
                locale="es"
                placeholder="mm/yyyy"
                inputFormat="MM/YYYY"
                valueFormat="MM/YYYY"
                classNames={{
                    wrapper: 'mantine__field__wrapper',
                    label: meta.error && meta.touched ? 'mantine__field__label--error' : 'mantine__field__label',
                    input: meta.error && meta.touched ? 'mantine__field__field--error' : 'mantine__field__field',
                    required: meta.error && meta.touched ? 'mantine__field__required--error' : 'mantine__field__required',
                    error: 'mantine__field__error',
                    rightSection: 'mantine__field__rightSection',
                }}
                rightSection={<CalendarIcon style={{fill: "var(--primary)"}}/>
                }
            />
        </div>
    );
};

export default MantineMonthField;
