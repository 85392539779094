import React, {
    useEffect,
    useState,
    useMemo,
} from 'react';
import PropTypes from 'prop-types';
import ClienteDirectoContext from "./ClienteDirectoContext";
import axios from "axios";
import ZurichWhite from "../../assets/logos/zurich_white.png";
import TerraWindWhite from "../../assets/logos/terrawind_black.svg";


function isValidBase64(str) {
    return /^[A-Za-z0-9+/=]+$/.test(str) && str.length % 4 === 0;
}

const companiesDict = {
    "Sancor": {
        id: 1,
        logo: "https://i.imgur.com/F55GPdm.png", //TODO: use right link
        products: [{label: "AUTOMOTOR", value: "vehiculo"}, {
            label: "MICROSEGUROS",
            value: "microseguros"
        }, {label: "HOGAR", value: "hogar"}],
        formasDePago: [{label: 'Tarjeta de crédito', value: 'TC'}, {label: 'Débito por CBU', value: 'CBU'}],
        codigoFormasDePago: {TC: '2', CBU: '4'},
        idsTarjeta: [
            {value: '1', label: 'Cabal'},
            {value: '5', label: 'Visa'},
            {value: '7', label: 'Favacard'},
            {value: '9', label: 'American Express'},
            {value: '12', label: 'Naranja'},
            {value: '16', label: 'Diners Club'},
            {value: '18', label: 'Naranja Visa'},
            {value: '20', label: 'Cordobesa Mastercard'},
            {value: '23', label: 'TDF - Tierra del Fuego'},
            {value: '45', label: 'Mastercard Nueva'},
            {value: '57', label: 'ELEBAR'},
            {value: '70', label: 'Cencosud Mastercard'},
        ],
        equipoGncId: '1'
    },
    "RUS": {
        id: 2,
        logo: "https://i.imgur.com/WUaf6QD.png", //TODO: use right link
        products: [{label: "AUTOMOTOR", value: "vehiculo"}],
        formasDePago: [{label: 'Tarjeta de crédito', value: 'TC'}, {label: 'Débito por CBU', value: 'CBU'}],
        codigoFormasDePago: {TC: '2', CBU: '4'},
        idsTarjeta: [
            {value: '1', label: 'Visa'},
            {value: '3', label: 'Mastercard'},
            {value: '6', label: 'Naranja'},
            {value: '7', label: 'Cabal'},
            {value: '11', label: 'American Express'},
            {value: '14', label: 'Kadicard'},
            {value: '16', label: 'Nativa MasterCard'},
            {value: '18', label: 'CMR Falabella'},
            {value: '19', label: 'Tarjeta Plata'},
            {value: '22', label: 'Favacard'},
        ],
        equipoGncId: '1'
    },
    "Zurich": {
        id: 3,
        logo: ZurichWhite,
        products: [{label: "AUTOMOTOR", value: "vehiculo"}],
        formasDePago: [{label: 'Tarjeta de crédito', value: 'TC'}, {label: 'Débito por CBU', value: 'CBU'}],
        codigoFormasDePago: {TC: '4', CBU: '7'},
        idsTarjeta: [
            {value: '30654364229-2', label: 'Cabal'},
            {value: '30684195707-2', label: 'Nevada'},
            {value: '30598910045-2', label: 'Visa'},
            {value: '30574816870-2', label: 'Amex'},
            {value: '30536209383-2', label: 'KADICARD Pesos'},
            {value: '30579836268-2', label: 'Diners'},
            {value: '30619540146-2', label: 'CREDIMAS Pesos'},
            {value: '30522211563-2', label: 'Argen/Master'},
            {value: '30681424721-2', label: 'MIRA Pesos'},
            {value: '30685376349-2', label: 'Naranja'},
            {value: '30619540146-2', label: 'CREDIMAS Pesos'},
        ],
        equipoGncId: '3'
    },
    "TerraWind": {
        id: 4,
        logo: TerraWindWhite,
        products: [{label: "ASISTENCIA AL VIAJERO", value: "asistencia_viajero", key: "asistenciaViajero"}],
        razonSocial: "Terrawind",



        descuento: "prima",
        codigoFormasDePago: {TC: '4', CBU: '7', EF: '5'},
        idsTarjeta: [
            {value: '30654364229-2', label: 'Cabal'},
            {value: '30684195707-2', label: 'Nevada'},
            {value: '30598910045-2', label: 'Visa'},
            {value: '30574816870-2', label: 'Amex'},
            {value: '30536209383-2', label: 'KADICARD Pesos'},
            {value: '30579836268-2', label: 'Diners'},
            {value: '30619540146-2', label: 'CREDIMAS Pesos'},
            {value: '30522211563-2', label: 'Argen/Master'},
            {value: '30681424721-2', label: 'MIRA Pesos'},
            {value: '30685376349-2', label: 'Naranja'},
            {value: '30619540146-2', label: 'CREDIMAS Pesos'},
        ],
        bienesAdicionales: [],
        emissionStatus: {Success: "approved", Pending: "pending", Cancel: "rejected"},
        opcionesRolesExtra: [
            {value: '2', label: 'Asegurado'},
            {value: '1', label: 'Tomador'},
            {value: '3', label: 'Acreedor'},
        ],
        rolPrincipal: {value: '2', label: 'Asegurado'}
    },
}

const companiesIdDict = {
    1: "Sancor",
    2: "RUS",
    3: "Zurich",
}

const coberturasAutomotorNames = {
    "names": {
        "RC": "Responsabilidad civil",
        "IT": "Incendio total",
        "IP": "Incendio parcial",
        "RHT": "Robo y/o hurto total",
        "RHP": "Robo y/o hurto parcial",
        "AT": "Accidente total",
        "APD": "Accidente parcial según deducible",
        "LTP": "Lock out o tumulto popular",
        "GTI": "Granizo / Terremoto / Indundación total y parcial",
        "RCR": "Rotura de cristales",
        "RCE": "Rotura de cerraduras",
        "RRH": "Reposición de ruedas en caso de robo o hurto",
        "DPR": "Daños parciales al amparo del Robo Total",
        "CPL": "Extensión de Cobertura a países limítrofes",
        "AV": "Asistencia al vehículo",
        "SCS": "CLEAS (Sistema Compensación de Siniestros)",
    },
}

const provinciasList = [
    {'value': 1, 'label': 'CIUDAD AUTONOMA DE BUENOS AIRES (CABA)'},
    {'value': 2, 'label': 'BUENOS AIRES'},
    {'value': 3, 'label': 'CATAMARCA'},
    {'value': 4, 'label': 'CORDOBA'},
    {'value': 5, 'label': 'CORRIENTES'},
    {'value': 6, 'label': 'ENTRE RIOS'},
    {'value': 7, 'label': 'JUJUY'},
    {'value': 8, 'label': 'MENDOZA'},
    {'value': 9, 'label': 'LA RIOJA'},
    {'value': 10, 'label': 'SALTA'},
    {'value': 11, 'label': 'SAN JUAN'},
    {'value': 12, 'label': 'SAN LUIS'},
    {'value': 13, 'label': 'SANTA FE'},
    {'value': 14, 'label': 'SANTIAGO DEL ESTERO'},
    {'value': 15, 'label': 'TUCUMAN'},
    {'value': 16, 'label': 'CHACO'},
    {'value': 17, 'label': 'CHUBUT'},
    {'value': 18, 'label': 'FORMOSA'},
    {'value': 19, 'label': 'MISIONES'},
    {'value': 20, 'label': 'NEUQUEN'},
    {'value': 21, 'label': 'LA PAMPA'},
    {'value': 22, 'label': 'RIO NEGRO'},
    {'value': 23, 'label': 'SANTA CRUZ'},
    {'value': 24, 'label': 'TIERRA DEL FUEGO'}
]

const coberturasMotovehiculoNames = {
    "names": {
        "RC": "Responsabilidad civil",
        "IT": "Incendio total",
        "IP": "Incendio parcial",
        "RHT": "Robo y/o hurto total",
        "RHP": "Robo y/o hurto parcial",
        "AT": "Accidente total",
        "GIT": "Granizo / Inundación / Terremoto",
        "DPC": "Daños por robo o intento de robo (Cerraduras)",
        "DT": "Cláusula destrucción total del 80%",
        "AV": "Asistencia al vehículo",
        "EC": "Extensión de Cobertura a países limítrofes"
    },
}

const ClienteDirectoContextProvider = ({
                                           children,
                                       }) => {

        const [idRowPaginasPolkista, setIdRowPaginasPolkista] = useState(null);
        const [idRowPolkista, setIdRowPolkista] = useState(null);
        const [description, setDescription] = useState(null);
        const [habilitado, setHabilitado] = useState(null);
        const [datosPolkista, setDatosPolkista] = useState(null);
        const [redesSociales, setRedesSociales] = useState(null);
        const [datosMaster, setDatosMaster] = useState(null);
        const [templateId, setTemplateId] = useState(null);
        const [activeProducts, setActiveProducts] = useState(null);
        const [logo, setLogo] = useState(null);
        const genericOptionsFormasDePago = [{label: 'Tarjeta de crédito', value: 'TC'}, {
            label: 'Débito por CBU',
            value: 'CBU'
        }];
        // VEHICULO
        const [autosMarcas, setAutosMarcas] = useState(null)
        const [localidades, setLocalidades] = useState(null)
        // MOTO
        const [motosMarcas, setMotosMarcas] = useState(null)
        //HOGAR
        const [coberturasInfo, setCoberturasInfo] = useState(null);
        //PRODUCTOS
        const [coberturasProductsInfo, setCoberturasProductsInfo] = useState(null);

        const [isReady, setIsReady] = useState(false);

        const coberturasAdicionalesOrder = [48, 36, 37, 26];

        useEffect(() => {
                let shouldUpdate = true;

                const init = async () => {
                    const urlPieces = window.location.href.split('/');

                    let idPaginaPolkista;
                    let idPolkista;
                    let coberturasInfoDict = {};

                    for (let urlPiece of urlPieces) {
                        const splitUrl = urlPiece.split('?')[0]
                        if (isValidBase64(splitUrl)) {
                            const decoded = atob(splitUrl).split(':');
                            if (decoded.length === 2) {
                                [idPaginaPolkista, idPolkista] = decoded;
                                break;
                            }
                        }
                    }

                    let urlRequestOne = process.env.REACT_APP_BACK_SERVER + `/newConsultarCanalDigital/?id_cd=${idPaginaPolkista}`
                    let urlRequestTwo = process.env.REACT_APP_BACK_SERVER + '/cd/getDatosCotizar' //POL-426
                    let urlRequestThree = process.env.REACT_APP_QUOTATION_MICROSERVICE + '/getInformacionCoberturas' //POL-426

                    const requestOne = axios.get(urlRequestOne);
                    const requestTwo = axios.get(urlRequestTwo);
                    const requestThree = axios.get(urlRequestThree);

                    axios.all([requestOne, requestTwo, requestThree])
                        .then(axios.spread((responseOne, responseTwo, responseThree) => {
                                if (shouldUpdate) {
                                    //HANDLE RESPONSE ONE
                                    const resp = responseOne['data']
                                    let masterResp = resp['datos_master']

                                    setHabilitado(resp['habilitada'])
                                    setIdRowPaginasPolkista(idPaginaPolkista)
                                    setIdRowPolkista(idPolkista)
                                    setDescription(resp['descripcion'])
                                    setDatosPolkista(resp['datos_polkista'])
                                    setDatosMaster({
                                        nombre: masterResp['Nombre'],
                                        apellido: masterResp['Apellido'],
                                        email: masterResp['Email'],
                                        direccion: `${masterResp['Direccion']}`,
                                        matricula: masterResp['matricula'],
                                        telefono: masterResp['Telefono'],
                                        id_polkista: masterResp['id_polkista'],
                                    })
                                    setRedesSociales(resp['redes_sociales'] ?? [])
                                    setTemplateId(resp['template'])
                                    setLogo(resp['logo']);
                                    setActiveProducts(resp['productos']);


                                    //HANDLE RESPONSE TWO
                                    // console.log('responseTwo', responseTwo)
                                    let options = responseTwo['data']['localidades'].map(d => ({
                                        "value": `${d.CodPostal.toString()},${d.CodSancorLocalidad.toString()},${d.CodRUSLocalidad.toString()}`,
                                        "label": '(' + d.CodPostal.toString() + ') ' + d.Localidad
                                    }));
                                    setLocalidades(options)

                                    setCoberturasInfo(responseTwo['data']['coberturas_hogar'])
                                    setAutosMarcas(responseTwo['data']['marcas_auto'].map(d => ({
                                        "value": d,
                                        "label": d
                                    })))
                                    setMotosMarcas(responseTwo['data']['marcas_moto'].map(d => ({
                                        "value": d,
                                        "label": d
                                    })))

                                    //HANDLE RESPONSE THREE
                                    // console.log('responseThree', responseThree)
                                    coberturasInfoDict["vehiculo"] = {
                                        ...responseThree["data"]["coberturas_auto"],
                                        ...coberturasAutomotorNames
                                    }
                                    coberturasInfoDict["motovehiculo"] = {
                                        ...responseThree["data"]["coberturas_motovehiculo"],
                                        ...coberturasMotovehiculoNames
                                    }

                                    setCoberturasProductsInfo(coberturasInfoDict)

                                    setIsReady(true);
                                }
                            }
                        ))
                }
                init();
                return () => {
                    /* This cleanup function is used to prevent updating the state
                    when the component is unmounted */
                    shouldUpdate = false;
                };
            }, []
        )


        const context = useMemo(() => ({
            habilitado,
            idRowPaginasPolkista,
            idRowPolkista,
            description,
            datosPolkista,
            datosMaster,
            redesSociales,
            templateId,
            logo,
            genericOptionsFormasDePago,
            activeProducts,
            autosMarcas,
            motosMarcas,
            localidades,
            coberturasInfo,
            coberturasAdicionalesOrder,
            companiesDict,
            companiesIdDict,
            provinciasList,
            coberturasProductsInfo,
            isReady
        }), [
            habilitado,
            idRowPaginasPolkista,
            idRowPolkista,
            description,
            datosPolkista,
            datosMaster,
            redesSociales,
            templateId,
            logo,
            genericOptionsFormasDePago,
            activeProducts,
            autosMarcas,
            motosMarcas,
            localidades,
            coberturasInfo,
            coberturasAdicionalesOrder,
            companiesDict,
            companiesIdDict,
            provinciasList,
            coberturasProductsInfo,
            isReady
        ]);

        return (
            <ClienteDirectoContext.Provider value={context}>
                {children}
            </ClienteDirectoContext.Provider>
        );
    }
;

ClienteDirectoContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ClienteDirectoContextProvider;