import React, {Fragment, useContext, useMemo, useState} from "react";
import {Formik, Form} from "formik";
import formModel from '../FormsModel/formModel';

import ProgressBar from "../../ProgressBar";
import Loading from "../../Loading";
import ClienteDirectoContext from "../../../context/ClienteDirectoContext";
import EntradaCotizarForm from "./Forms/Cotizar/EntradaCotizar/EntradaCotizarForm";
import ClienteForm from "./Forms/Emitir/Cliente/ClienteForm";
import SolicitudForm from "./Forms/Emitir/Solicitud/SolicitudForm";
import CotizarSuccessView from "./Forms/Cotizar/CotizarSuccess/CotizarSuccessView";
import {EntradaCotizarModel} from "./Forms/Cotizar/EntradaCotizar/EntradaCotizarModel";
import {ClienteModel} from "./Forms/Emitir/Cliente/ClienteModel";
import axios from "axios";
import {format} from "date-fns";
import EmisionSuccess from "./EmisionSuccess";
import {IconLuggage} from "@tabler/icons";
import {
    withAsistenciaViajeroContextProvider,
    withAsistenciaViajeroContextReady
} from "../../../context/products/AsistenciaViajeroContext";

const steps = ["Viaje", "¡Elegí tu plan!", "Titular", "Póliza", "Resumen"];
const {formField} = formModel;

function _renderStepContent(step, cotizacionResponse, finalValues, setActiveStep, setEmisionResponse, emisionResponse, currentProduct, localidadOptions, setLocalidadOptions, emissionIdentifiers) {
    switch (step) {
        case 0:
            return <EntradaCotizarForm formField={formField} selectedLocation={localidadOptions}
                                       setSelectedLocation={setLocalidadOptions}/>
        case 1:
            return <div>
                {(cotizacionResponse !== null && finalValues !== null)
                    ?
                    <CotizarSuccessView finalValues={finalValues}
                                        currentProduct={currentProduct}
                                        cotizacionResponse={cotizacionResponse}/>
                    : (
                        <div style={{margin: "32px 16px"}}>
                            <p className="form__title">
                                Cotizando...
                            </p>
                            <Loading/>
                        </div>
                    )
                }
            </div>
        case 2:
            return <ClienteForm formField={formField}/>
        case 3:
            return <SolicitudForm formField={formField} cotResponse={cotizacionResponse}/>
        case 4:
            return <div style={{margin: "32px 16px"}}>
                {emisionResponse !== null
                    ?
                    <EmisionSuccess emisionResponse={emisionResponse} setActiveStep={setActiveStep}
                                    setEmisionResponse={setEmisionResponse} emissionIdentifiers={emissionIdentifiers}
                                    finalValues={finalValues}/>
                    : (
                        <Fragment>
                            <p className="form__title">
                                Emitiendo...
                            </p>
                            <Loading/>
                            <p className="form__text--center">
                                Esto puede demorar hasta 60 segundos.
                            </p>
                        </Fragment>
                    )
                }
            </div>
        default:
            return <div>Not Found</div>;
    }
}

function CheckoutCotizar() {
    const [activeStep, setActiveStep] = useState(0);
    const [cotizacionResponse, setCotizacionResponse] = useState(null)
    const [emisionResponse, setEmisionResponse] = useState(null)
    const [emissionIdentifiers, setEmissionIdentifiers] = useState(null);
    const [finalValues, setFinalValues] = useState(null);
    const [localidadOptions, setLocalidadOptions] = useState([]);
    const {idRowPaginasPolkista, activeProducts, datosMaster} = useContext(ClienteDirectoContext);
    const currentProduct = 'asistencia_viajero'
    const formSchemas = useMemo(() => {
        return [EntradaCotizarModel(), {}, ClienteModel(), {}, {}];
    }, [])

    async function _cotizarForm(values, actions) {
        let url = process.env.REACT_APP_BACK_SERVER + '/newcotizar/asistencia-viajero';
        let realPassengersArray = [];
        values.pasajeros.map((pasajero) => {
            if (typeof pasajero.edad === 'number') {
                realPassengersArray.push(pasajero)
            }
        })

        actions.setFieldValue('pasajeros', realPassengersArray);

        const jsonData = {
            ...values,
            vigenciaDesde: format(values.vigenciaDesde, 'dd/MM/yyyy'),
            vigenciaHasta: format(values.vigenciaHasta, 'dd/MM/yyyy'),
            "idCanalDigital": idRowPaginasPolkista,
        }

        axios.post(url, jsonData).then(res => {
            setCotizacionResponse(res.data);
            setFinalValues(jsonData)
            actions.setFieldValue('idCotizar', res.data['idCotizar'])
        }).catch(err => {
            setFinalValues(jsonData)
            setCotizacionResponse("Error");
            actions.setSubmitting(false);
        }).finally(() => {
            if (actions) actions.setSubmitting(false);
        });
    }

    async function _emitirForm(values, actions) {
        let url = process.env.REACT_APP_BACK_SERVER + '/newemitir/asistencia-viajero';

        values.pasajeros.forEach(pasajero => {
            pasajero.nombre_contacto_emergencia = values.nombre_contacto_emergencia;
            pasajero.apellido_contacto_emergencia = values.apellido_contacto_emergencia;
            pasajero.telefono_contacto_emergencia = values.telefono_contacto_emergencia;
        })

        const jsonData = {
            ...values,
            vigenciaDesde: format(values.vigenciaDesde, 'dd/MM/yyyy'),
            vigenciaHasta: format(values.vigenciaHasta, 'dd/MM/yyyy'),
            idProducto: values.selectedCobertura,
            comision: activeProducts['asistencia_viajero'].codigos_aseguradoras[0].comisiones.general,
            comision_polkista: activeProducts['asistencia_viajero'].codigos_aseguradoras[0].comisiones.polkista,
            idCotizacion: values.idCotizar,
            idCanalDigital: idRowPaginasPolkista,
            idMaster: datosMaster.id_polkista,
        }

        axios.post(url, jsonData).then(res => {
            if (res.data.pasajeros) {
                const tempEmissionIdentifiers = res.data.pasajeros.reduce((acc, pasajero) => {
                    pasajero.identificadores.forEach(identificador => {
                        if (!acc[identificador.nombre]) {
                            acc[identificador.nombre] = [];
                        }
                        acc[identificador.nombre].push(identificador.valor);
                    });
                    return acc;
                }, {});
                setEmissionIdentifiers(tempEmissionIdentifiers);
            }
            setEmisionResponse(res.data)
        }).catch(err => {
            console.log(err)
            setEmisionResponse("Error")
        }).finally(() => {
            setFinalValues(values);
            actions.setSubmitting(false);
        });
    }

    function _handleSubmit(values, actions) {
        actions.setSubmitting(true);
        switch (activeStep) {
            case 0:
                setActiveStep(activeStep + 1);
                _cotizarForm(values, actions)
                break;
            case 3:
                setActiveStep(activeStep + 1);
                _emitirForm(values, actions);
                break;
            default:
                actions.setTouched({});
                actions.setSubmitting(false);
                setActiveStep(activeStep + 1);
                break;
        }
    }

    function _handleBack() {
        if (activeStep === 1) {
            setFinalValues(null)
        } else if (activeStep === 4) {
            setEmisionResponse(null)
        }
        setActiveStep(activeStep - 1);
    }

    const buttonText = () => {
        switch (activeStep) {
            case 0:
                return "Cotizar";
            case 3:
                return "Confirmar";
            default:
                return "Siguiente";
        }
    }

    return (
        <React.Fragment>
            <>
                <div className="form__circle__container">
                    <div className="form__circle">
                        <IconLuggage className="form__icon--withoutFill"/>
                    </div>
                </div>

                <div style={{margin: "16px 0px"}}>
                    <ProgressBar activeStep={activeStep} stepTitles={steps}/>
                </div>

                <Fragment>
                    <Formik
                        initialValues={formSchemas[activeStep].initialValues}
                        validationSchema={formSchemas[activeStep].validationSchema}
                        validateOnBlur={false}
                        onSubmit={_handleSubmit}
                    >
                        {({isSubmitting}) => (
                            <Form id={"customerAutomotorForm"}>
                                {
                                    _renderStepContent(
                                        activeStep,
                                        cotizacionResponse,
                                        finalValues,
                                        setActiveStep,
                                        setEmisionResponse,
                                        emisionResponse,
                                        currentProduct,
                                        localidadOptions,
                                        setLocalidadOptions,
                                        emissionIdentifiers
                                    )
                                }

                                <div
                                    className="form__footer"
                                    style={{display: activeStep > 3 && emisionResponse !== "Error" && "none"}}
                                >
                                    {activeStep !== 0 && (emisionResponse === null || emisionResponse === "Error") && (
                                        <button
                                            type="button"
                                            onClick={_handleBack}
                                            className="button__outlined"
                                            disabled={isSubmitting}
                                        >
                                            Atras
                                        </button>
                                    )}
                                    {activeStep !== 1 && activeStep < 4 && (
                                        <button
                                            className="button"
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            {buttonText()}
                                        </button>
                                    )}
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Fragment>
            </>
        </React.Fragment>
    );
}

export default withAsistenciaViajeroContextProvider(withAsistenciaViajeroContextReady(CheckoutCotizar));