import React, {useContext, useState} from 'react';
import {currencyFormat} from "./Utils/currencyFormat";
import rightArrow from "../assets/systemIcons/right-arrow.png"
import FranchiseSelector from "./FranchiseSelector";
import ClienteDirectoContext from "../context/ClienteDirectoContext";
import {useFormikContext} from "formik";

function GenericCoberturaCard(props) {
    const {
        cardInfo:
            {id, nombre, displayPremium: premium, description, compania, hasFranchise, franchises, parametrosAdicionales, moreDetails},
        currentProduct,
        finalValues
    } = props;
    const [variantDetails, setVariantDetails] = useState(false);
    const [nombreCobertura, setNombreCobertura] = useState(nombre);
    const [selected, setSelected] = useState(0);
    const {companiesDict, coberturasProductsInfo} = useContext(ClienteDirectoContext);
    const {setFieldValue} = useFormikContext();

    const descriptionItems = () => {
        //Create coverage details
        let coverageDetails = Object.values(moreDetails).map((detail, index) => {
            return (
                <p className="coberturaCard__item form__text--light structure__margin--XS">
                    {detail?.coverage_name} {detail?.coverage_val}
                </p>
            )
        });

        //Add vigencia to details
        let vigenciaText = "Anual con refacturación mensual"
        if (compania === "RUS")
            vigenciaText = "Semestral con refacturación trimestral"
        coverageDetails.push(
            <p className="coberturaCard__item form__text--light structure__margin--XS">
                Vigencia: {vigenciaText}
            </p>
        )

        return coverageDetails
    }

    const assignValues = () => {
        let nombreCoberturaToSave = nombreCobertura;
        setFieldValue("compania", compania);
        setFieldValue("selectedCobertura", hasFranchise ? id[selected] : id);
        setFieldValue("premium", hasFranchise ? premium[selected] : premium);
        setFieldValue("nombreCobertura", nombreCoberturaToSave)
    }

    return (
        <div className="coberturaCard__container">
            <div className={variantDetails ? "coberturaCard--details" : "coberturaCard"}>
                <div className="coberturaCard__header">
                    <img className="coberturaCard__logo" src={companiesDict[compania]['logo']} alt="aseguradora_logo"/>
                </div>
                <div className="coberturaCard__body">
                    <div className={variantDetails ? "coberturaCard__title--primary" : "coberturaCard__title"}>
                        {variantDetails ? "Este plan incluye" : nombreCobertura}
                    </div>

                    {variantDetails
                        ?
                        <div className="structure__margin--top">
                            {descriptionItems()}

                            <div className="structure__column structure__margin--topOnly">
                                <p className="coberturaCard__title--primary coberturaCard__title--noBorder">
                                    {nombreCobertura}
                                </p>
                                <p className="coberturaCard__premium">$
                                    <span className="coberturaCard__premium--number">
                                        {hasFranchise ? currencyFormat(Math.ceil(premium[selected])) : currencyFormat(Math.ceil(premium))}
                                    </span>
                                </p>
                            </div>

                        </div>
                        :
                        <div className="coberturaCard__info">
                            <p className="coberturaCard__premium">$
                                <span className="coberturaCard__premium--number">
                                    {hasFranchise ? currencyFormat(Math.ceil(premium[selected])) : currencyFormat(Math.ceil(premium))}
                                </span>
                            </p>
                            <p className="coberturaCard__description">{description}</p>
                            {
                                hasFranchise &&
                                <FranchiseSelector franchises={franchises} initial={selected}
                                                   setSelected={setSelected}/>
                            }
                        </div>
                    }

                    <div className="coberturaCard__body">
                        <button
                            className="button__secondary--large"
                            type="button"
                            onClick={() => setVariantDetails(!variantDetails)}
                        >
                            <span style={{marginRight: "8px"}}>
                                {variantDetails
                                    ? "Volver"
                                    : "Ver detalle"
                                }
                            </span>
                            <img className="navbar__icon"
                                 src={rightArrow}/>
                        </button>
                        <button
                            className="button--large"
                            onClick={() => assignValues()}
                            type="submit"
                        >
                            Contratar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GenericCoberturaCard;