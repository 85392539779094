import React from 'react';
import './CustomModal.css';

function CustomModal(props) {
    const {children} = props;

    return (
        <div className="customModal__container--fullwidth">
            <div className="customModal__content">
                {children}
            </div>
        </div>
    );
}

export default CustomModal;