import { buildLocationCode } from "../../../Utils/buildLocationCode";

const fillAdvancedConfigByCompany = async (companies, values, productCodesData, companiesNames) => {
    let advancedConfig = {};
    const locationCodes = await buildLocationCode(companiesNames, values.codigosLocalidad);

    for (let company of companies) {
        const companyCodesObj = productCodesData.find(item => item.id_aseguradora === company);
        switch (company) {
            case 1: //Sancor
                advancedConfig = {
                    ...advancedConfig,
                    "Sancor": {
                        ...values.configuracionAvanzada.Sancor,
                        "codigos": companyCodesObj.codigos,
                        "codSancorLocalidad": locationCodes.CodigoLocalidadSancor,
                        "comision": companyCodesObj.comisiones.general,
                        "tipoUso": "2",
                        "descuentoEspecial": 0,
                        "descuentoModelo": 99,
                    }
                }
                break;
            case 2: //RUS
                advancedConfig = {
                    ...advancedConfig,
                    "RUS": {
                        ...values.configuracionAvanzada.RUS,
                        "codigos": companyCodesObj.codigos,
                        "codRUSLocalidad": locationCodes.CodigoLocalidadRUS,
                        "comision": companyCodesObj.comisiones.general,
                        "descuentoEspecial": 15,
                    }
                }
                break;
            default:
                advancedConfig = {
                    ...advancedConfig
                }
                break;
        }
    }

    return advancedConfig;
}

export { fillAdvancedConfigByCompany };