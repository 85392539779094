import React, {useEffect, useContext} from 'react';
import {useFormikContext} from "formik";
import {Checkbox} from '@mantine/core';
import ClienteDirectoContext from "../../../../context/ClienteDirectoContext";
import NumberField from "../../FormFields/NumberField";
import PopOverInfo from "../../../PopOverInfo";
import {IconBike, IconDeviceLaptop, IconDeviceTablet, IconLock} from "@tabler/icons";

const coberturasAdicionalesIcons = {
    26: <IconLock className="form__coberturaIcon"/>,
    48: <IconBike className="form__coberturaIcon"/>,
    36: <IconDeviceLaptop className="form__coberturaIcon"/>,
    37: <IconDeviceTablet className="form__coberturaIcon"/>,

}

export default function CoberturasForm(props) {

    const {coberturasInfo, coberturasAdicionalesOrder} = useContext(ClienteDirectoContext);
    const {values, setFieldValue} = useFormikContext();
    const {setModifiedSumas} = props;

    useEffect(() => {
        setModifiedSumas(values.tamanioVivienda)
    }, [values.tamanioVivienda])


    return (
        <React.Fragment>

            {Object.keys(values.coberturas).map(cobertura => {
                return (
                    <div className="form__item--hogar" key={cobertura}>
                        <p className="form__text">{coberturasInfo[cobertura].descripcion}</p>

                        <NumberField
                            name={`coberturas.${cobertura}.sumaAsegurada`}
                            min={coberturasInfo[cobertura]['sumaMinima']}
                            max={coberturasInfo[cobertura]['sumaMaxima']}
                        />

                        <div className="structure__center">
                            <PopOverInfo descripcion={coberturasInfo[cobertura].descripcionCompleta}/>
                        </div>
                    </div>
                )
            })}

            {coberturasAdicionalesOrder.map(cobertura => {
                return (
                    <div className="form__item--hogar" key={cobertura}>
                        <div className="structure__spaced-between">
                            <Checkbox
                                checked={values.coberturasAdicionales[cobertura].selectedCobertura}
                                classNames={{
                                    input: "checkbox"
                                }}
                                size="sm"
                                onChange={(e) => {
                                    const {checked} = e.target;
                                    setFieldValue(`coberturasAdicionales.${cobertura}`, {
                                        selectedCobertura: checked,
                                        sumaAsegurada: values.coberturasAdicionales[cobertura].sumaAsegurada
                                    });
                                }}
                            />
                            {coberturasAdicionalesIcons[cobertura]}
                            <p className="form__text--tiny">{coberturasInfo[cobertura].descripcion}</p>
                        </div>


                        <div
                            style={{visibility: values.coberturasAdicionales[cobertura] && values.coberturasAdicionales[cobertura].selectedCobertura ? "visible" : "hidden"}}>
                            <NumberField
                                name={`coberturasAdicionales.${cobertura}.sumaAsegurada`}
                                min={coberturasInfo[cobertura]['sumaMinima']}
                                max={coberturasInfo[cobertura]['sumaMaxima']}
                            />
                        </div>
                        <div className="structure__center">
                            <PopOverInfo descripcion={coberturasInfo[cobertura].descripcionCompleta}/>
                        </div>
                    </div>
                )
            })}
        </React.Fragment>
    );
}
