export const CardNumberValidator = (tarjeta, numeroTarjeta) => {

    const NARANJA = /^(589562|402917|402918|527571|527572|0377798|0377799)[0-9]*$/;
    const AMEX = /^(34|37)\d+$/;

    if (tarjeta === "12" || tarjeta === "18") {
        return NARANJA.test(numeroTarjeta);
    } else if (tarjeta === "9") {
        return AMEX.test(numeroTarjeta)
    } else {
        return luhnCheck(numeroTarjeta)
    }

    function luhnCheck(val) {
        let sum = 0;
        for (let i = 0; i < val.length; i++) {
            let intVal = parseInt(val.substr(i, 1));
            if (i % 2 === 0) {
                intVal *= 2;
                if (intVal > 9) {
                    intVal = 1 + (intVal % 10);
                }
            }
            sum += intVal;
        }
        return (sum % 10) === 0;
    }
};