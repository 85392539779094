const fillAdvancedConfigByCompany = (companies, values, productCodesData) => {
    let advancedConfig = {};

    for (let company of companies) {
        const companyCodesObj = productCodesData.find(item => item.id_aseguradora === company);
        switch (company) {
            case 1: //Sancor
                advancedConfig = {
                    ...advancedConfig,
                    "codigos": companyCodesObj.codigos,
                    "comision": companyCodesObj.comisiones.general,
                    "frecuenciaPago": values.frecuenciaPago,
                }
                break;
            default:
                advancedConfig = {
                    ...advancedConfig
                }
                break;
        }
    }

    return advancedConfig;
}

export {fillAdvancedConfigByCompany};