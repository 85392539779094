import React, {useContext} from 'react';
import WappIcon from "../assets/systemIcons/whatsapp.svg";
import {sendWapp} from "./Utils/sendWapp";
import ClienteDirectoContext from "../context/ClienteDirectoContext";

function WhatsAppFab() {
    const {datosPolkista} = useContext(ClienteDirectoContext);
    return (
        <div className="FAB"
             onClick={
                 () => sendWapp(`https://wa.me/${datosPolkista['Telefono']}?text=¡Buenos%20días!%20Necesito%20ayuda%20con%20la%20compra%20de%20un%20seguro%20desde%20su%20plataforma%20digital...`)
             }
        >
            <img className="FAB__image" src={WappIcon}/>
        </div>
    );
}

export default WhatsAppFab;