import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import CheckoutCotizarHogar from "../components/Formularios/CombinadoForm";
import CheckoutCotizarVehiculo from "../components/Formularios/VehiculoForm";
import CheckoutCotizarMicroseguros from "../components/Formularios/MicrosegurosForm";
import LeftArrow from '../assets/systemIcons/left-arrow.png'
import Footer from "../components/Footer";
import CheckoutCotizarMotovehiculo from "../components/Formularios/MotovehiculoForm";
import CheckoutCotizarAsistenciaViajero from "../components/Formularios/AsistenciaViajeroForm";

const microsegurosDict = {
    "robovarios": 26,
    "bicicleta": 48,
    "monopatin": 48,
    "tablet": 37,
    "notebook": 36
}

const getForm = ((producto) => {
    switch (producto) {
        case 'automotor':
            return <CheckoutCotizarVehiculo/>
        case 'motovehículo':
            return <CheckoutCotizarMotovehiculo/>
        case 'hogar':
            return <CheckoutCotizarHogar/>
        case 'asistenciaviajero':
            return <CheckoutCotizarAsistenciaViajero/>
        default:
            return <CheckoutCotizarMicroseguros
                pickedCobertura={microsegurosDict[producto]}/>
    }
})

function Formularios() {
    const params = useParams();
    let navigate = useNavigate();

    return (
        <div className="form">
            <section className="navbar__navigation">
                <div className="navbar__circle" onClick={() => navigate(-1)}>
                    <img src={LeftArrow} className="navbar__icon" alt="Arrow"/>
                </div>
                <p className="navbar__text">Atrás</p>
            </section>

            <section className="form__box">
                {getForm(params['producto'])}
            </section>

            <Footer/>
        </div>
    );
}

export default Formularios;