import axios from "axios";

function getCarModels(idCD, marca, anio, modelo) {
    let carVersionsUrl = process.env.REACT_APP_BACK_SERVER + '/getVersionesAutos'
    return axios.get(carVersionsUrl, {
        params: {
            'idcanaldigital': idCD,
            'marca': marca,
            'anio': anio,
            'modelo': modelo
        }
    });
}

export default getCarModels;