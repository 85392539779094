import React, {useEffect, useState} from 'react';
import {Input} from '@mantine/core';
import {useField} from 'formik';
import {ReactComponent as ExclamationIcon} from "../../../assets/systemIcons/ic_exclamation.svg";

function MantineInputField(props) {
    const {label, name} = props;
    const [field, meta, helpers] = useField(name);
    const [value, setValue] = useState(field.value);
    const {setValue: setFieldValue, setTouched} = helpers;

    useEffect(() => {
        setValue(field.value);
    }, [field.value]);

    return (
        <Input.Wrapper
            {...props}
            id={`wrapper-${name}`}
            label={label}
            error={meta.error && meta.touched && <div>{meta.error}</div>}
            classNames={{
                label: meta.error && meta.touched ? 'mantine__field__label--error' : 'mantine__field__label',
                required: meta.error && meta.touched ? 'mantine__field__required--error' : 'mantine__field__required',
                error: 'mantine__field__error',
            }}
        >
            <Input id={name}
                   {...props}
                   value={value}
                   onBlur={(e) => {
                       setFieldValue(e.target.value)
                       setTouched(name, false)
                   }}
                   onChange={(e) => setValue(e.target.value)}
                   classNames={{
                       wrapper: 'mantine__field__wrapper',
                       rightSection: 'mantine__field__rightSection',
                       input: meta.error && meta.touched ? 'mantine__field__field--error' : 'mantine__field__field',
                   }}
                   rightSection={
                       meta.error && meta.touched && <ExclamationIcon className="mantine__input__icon"/>
                   }
            />
        </Input.Wrapper>
    );
}

export default MantineInputField;