import React, {useContext, useState} from 'react';
import '../css/styles.css';
import ProductCard from "../components/ProductCard";
import StepDescription from "../components/StepDescription";
import {useNavigate} from "react-router-dom";
import ClienteDirectoContext from "../context/ClienteDirectoContext";
import LandingBanner from "../components/LandingBanner";
import Footer from "../components/Footer";
import AccordionDetails from "../components/AccordionDetails"
import {IconMoodSad, IconLuggage} from "@tabler/icons";
import {ReactComponent as IconAutomotor} from '../assets/products/auto.svg';
import {ReactComponent as IconMotovehiculo} from '../assets/products/moto.svg';
import {ReactComponent as IconHogar} from '../assets/products/hogar.svg';
import {ReactComponent as IconBicileta} from '../assets/products/bici.svg';
import {ReactComponent as IconMonopatin} from '../assets/products/monopatin.svg';
import {ReactComponent as IconNotebook} from '../assets/products/notebook.svg';
import {ReactComponent as IconRoboObjetosVarios} from '../assets/products/candado.svg';
import {ReactComponent as IconTablet} from '../assets/products/tablet.svg';
import {Carousel} from "@mantine/carousel";
import CheckoutCotizarVehiculo from "../components/Formularios/VehiculoForm";
import CheckoutCotizarMotovehiculo from "../components/Formularios/MotovehiculoForm";
import CheckoutCotizarHogar from "../components/Formularios/CombinadoForm";
import CheckoutCotizarMicroseguros from "../components/Formularios/MicrosegurosForm";
import CheckoutCotizarAsistenciaViajero from "../components/Formularios/AsistenciaViajeroForm";
import useWindowDimensions from "../components/Utils/useWindowDimensions";

const COLUMN_FIXED_WIDTH = 305;

const microsegurosDict = {
    "robovarios": 26,
    "bicicleta": 48,
    "monopatin": 48,
    "tablet": 37,
    "notebook": 36
}

const getForm = ((producto) => {
    switch (producto) {
        case 'automotor':
            return <CheckoutCotizarVehiculo/>
        case 'motovehículo':
            return <CheckoutCotizarMotovehiculo/>
        case 'hogar':
            return <CheckoutCotizarHogar/>
        case 'asistenciaviajero':
            return <CheckoutCotizarAsistenciaViajero/>
        default:
            return <CheckoutCotizarMicroseguros
                pickedCobertura={microsegurosDict[producto]}/>
    }
})

const Landing = () => {
    let navigate = useNavigate();
    const {width} = useWindowDimensions();
    const isMobile = width < 768;
    const [selectedProduct, setSelectedProduct] = useState('automotor');
    const {activeProducts, habilitado, templateId} = useContext(ClienteDirectoContext);

    const stepsDescription = [
        {
            step: "1",
            title: "Completá tus datos",
            description: "Contanos que querés asegurar y dejá todo en nuestras manos."
        },
        {
            step: "2",
            title: "Cotizá",
            description: "Cotizá en tiempo real las mejores coberturas."
        },
        {
            step: "3",
            title: "Contratá tu seguro",
            description: "Elegí la forma de pago y ya estás asegurado."
        },
    ]
    const activatedProducts = [
        {
            name: "Automotor",
            icon: <IconAutomotor className="card__icon--design"/>,
            iconActive: <IconAutomotor className="card__icon--design card__icon--design--active"/>,
            activated: activeProducts['vehiculo'] && activeProducts['vehiculo']['active']
        },
        {
            name: "Motovehículo",
            icon: <IconMotovehiculo className="card__icon--design"/>,
            iconActive: <IconMotovehiculo className="card__icon--design card__icon--design--active"/>,
            activated: activeProducts['motovehiculo'] && activeProducts['motovehiculo']['active']
        },
        {
            name: "Hogar",
            icon: <IconHogar className="card__icon--design"/>,
            iconActive: <IconHogar className="card__icon--design card__icon--design--active"/>,
            activated: activeProducts['hogar'] && activeProducts['hogar']['active']
        },
        {
            name: "Asistencia Viajero",
            icon: <IconLuggage className="card__icon--designStroke"/>,
            iconActive: <IconLuggage className="card__icon--designStroke card__icon--designStroke--active"/>,
            activated: activeProducts['asistencia_viajero'] && activeProducts['asistencia_viajero']['active']
        },
    ]
    const activatedProductsMicro = [
        {
            name: "Bicicleta",
            icon: <IconBicileta className="card__icon--design"/>,
            iconActive: <IconBicileta className="card__icon--design card__icon--design--active"/>,
            activated: activeProducts['microseguros'] && activeProducts['microseguros']['active']
        },
        {
            name: "Monopatin",
            icon: <IconMonopatin className="card__icon--design"/>,
            iconActive: <IconMonopatin className="card__icon--design card__icon--design--active"/>,
            activated: activeProducts['microseguros'] && activeProducts['microseguros']['active']
        },
        {
            name: "Notebook",
            icon: <IconNotebook className="card__icon--design"/>,
            iconActive: <IconNotebook className="card__icon--design card__icon--design--active"/>,
            activated: activeProducts['microseguros'] && activeProducts['microseguros']['active']
        },
        {
            name: "Tablet",
            icon: <IconTablet className="card__icon--design"/>,
            iconActive: <IconTablet className="card__icon--design card__icon--design--active"/>,
            activated: activeProducts['microseguros'] && activeProducts['microseguros']['active']
        },
        {
            name: "Robo varios",
            icon: <IconRoboObjetosVarios className="card__icon--design"/>,
            iconActive: <IconRoboObjetosVarios className="card__icon--design card__icon--design--active"/>,
            activated: activeProducts['microseguros'] && activeProducts['microseguros']['active']
        },
    ];

    const renderProducts = () => {
        const _activeProducts = activatedProducts.filter(product => product.activated);

        const generateProductElement = (product, isFirstActivated) => (
            <div className="card" key={product.name} onClick={() =>
                navigate(`cotizar/${product.name.replace(/\s/g, '').toLowerCase()}`)
            }>
                <ProductCard
                    fullWidth={isMobile ? isFirstActivated : false}
                    icon={product.icon}
                    name={product.name}
                />
            </div>
        );

        if (!isMobile) {
            return (
                <div className={`structure__center`}>
                    {_activeProducts.map(product => generateProductElement(product, false))}
                </div>
            )
        }

        if (_activeProducts.length % 2 !== 0) {
            return (
                <div className="structure__center" style={{ margin: '8px' }}>

                        {generateProductElement(_activeProducts[0], true)}

                        {_activeProducts.slice(1).map(product => generateProductElement(product, false))}

                </div>
            )
        } else {
            return <div className="structure__center">
                {_activeProducts.map(product => generateProductElement(product, false))}
            </div>
        }
    }

    if (habilitado) {
        return (
            <div className="section">
                <section className="section__container section__pattern">
                    <h1 className="section__title">Contratá tu seguro fácil, rápido y 100% online</h1>
                    <p className="section__subtitle">Con el respaldo de un productor asesor matriculado</p>
                </section>

                <section className="section__container--directionVariant">
                    {/*AUTO, MOTO Y HOGAR*/}
                    {renderProducts()}
                    {/*CAROUSEL MICRO - MOBILE*/}
                    <div className="structure__center carousel__products--mobile">
                        {activeProducts['microseguros']['active'] && (
                            <Carousel
                                sx={{maxWidth: COLUMN_FIXED_WIDTH}}
                                mx="auto"
                                dragFree
                                height={"auto"} //Card height + margin + space for shadow
                                slideSize="33.33%"
                                slideGap="xs"
                                loop
                                align="start"
                                slidesToScroll={1}
                                withControls={false}
                            >
                                {activatedProductsMicro.map(actProd =>
                                    <Carousel.Slide>
                                        <div onClick={() =>
                                            navigate(`cotizar/${actProd.name.replace(/\s/g, '').toLowerCase()}`)
                                        }>
                                            <ProductCard
                                                variant="micro"
                                                icon={actProd.icon}
                                                name={actProd.name}
                                            />
                                        </div>
                                    </Carousel.Slide>
                                )}
                            </Carousel>
                        )
                        }
                    </div>

                    {/*MICRO ESCRITORIO*/}
                    <div className="carousel__products--desktop">
                        <Carousel mx="auto"
                                  dragFree
                                  height={"auto"} // 96px Card height + margin + space for shadow
                                  slideSize="auto"
                                  slideGap="1rem"
                                  loop
                                  align="start"
                                  withControls={false}>
                        {activatedProductsMicro.map((product, idx) => {
                            if (product.activated) {
                                return (
                                    <Carousel.Slide>
                                    <div style={{width: "fit-content"}} key={product.name} onClick={() =>
                                        navigate(`cotizar/${activatedProductsMicro[idx].name.replace(/\s/g, '').toLowerCase()}`)
                                    }>
                                        <ProductCard
                                            variant="micro"
                                            icon={product.icon}
                                            name={product.name}
                                        />
                                    </div>
                                    </Carousel.Slide>
                                )
                            } else return <div></div>;
                        })}
                        </Carousel>
                    </div>
                </section>

                {/*ONLY DISPLAYED ON FORMISTIC THEME*/}
                <section className="section__container--formistic">
                    <div className="sidebar__container">
                        <Carousel
                            mx="auto"
                            dragFree
                            height={"auto"} // 96px Card height + margin + space for shadow
                            slideSize="auto"
                            slideGap="1rem"
                            loop
                            align="start"
                            slidesToScroll={1}
                            withControls={false}
                        >
                            {activatedProducts.concat(activatedProductsMicro).map(actProd => {
                                    if (actProd.activated) {
                                        const isActive = selectedProduct === actProd.name.replace(/\s/g, '').toLowerCase();
                                        return (
                                            <Carousel.Slide>
                                                <div onClick={() => {
                                                    setSelectedProduct(actProd.name.replace(/\s/g, '').toLowerCase())
                                                }} className={isActive ? "card card--active" : "card"}>
                                                    <ProductCard
                                                        icon={actProd.icon}
                                                        iconActive={actProd.iconActive}
                                                        name={actProd.name}
                                                        active={isActive}
                                                    />
                                                </div>
                                            </Carousel.Slide>)
                                    }
                                }
                            )}
                        </Carousel>
                    </div>
                    <div className="form">
                        <div className="form__box">
                            {getForm(selectedProduct)}
                        </div>
                    </div>
                </section>

                <section className="section__container--white">
                    {stepsDescription.map(step => {
                        return (
                            <div className="steps" key={step.step}>
                                <StepDescription
                                    step={step.step}
                                    title={step.title}
                                    description={step.description}
                                    variant={templateId}/>
                            </div>
                        )
                    })}
                </section>

                <LandingBanner/>

                <section className="structure__center structure__fullwidth">
                <div className="section__container structure__width">
                    <p className="section__title--secondary">Detalle de cobertura</p>
                    <AccordionDetails/>
                </div>
                </section>

                {/*Disabled temporarily*/}
                {/*<section className="section__container structure__width mainTheme__hide">*/}
                {/*    <SancorLogo/>*/}
                {/*</section>*/}

                <section className={`structure__fullwidth ${templateId !== 0 && "structure__margin--topOnly"}`}>
                    <Footer/>
                </section>
            </div>
        );
    } else {
        return (
            <div className="section">
                <section className="section__container">
                    <p className="section__title">¡Ups!</p>
                    <h1 className="section__title">Parece que este enlace no está habilitado</h1>

                    <div className="section__container">
                        <IconMoodSad className="notFound__icon"/>
                    </div>
                    <p className="section__subtitle">Si crees que se trata de un error, ponte en contacto con tu responsable de Polko</p>

                    <a href="https://www.polko.com.ar" target="_blank" className="notFound__link">Ir a Polko</a>
                </section>
            </div>)
    }

}

export default Landing;