import React, {useContext} from 'react';

import ClienteDirectoContextProvider from './ClienteDirectoContextProvider';
import ClienteDirectoContext from './ClienteDirectoContext';
import Loading from "../../components/Loading";

/**
 * Inserts UserContextProvider in the react tree
 * before adding the given component.
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withClienteDirectoContextProvider = (Component) => (props) => (
    <ClienteDirectoContextProvider>
        <Component {...props} />
    </ClienteDirectoContextProvider>
);

/**
 * Renders the given component when the user context is ready.
 *
 * @param {Object} Component The component to render.
 * @param {Object} Component The rendered component.
 * @returns {Object} The component to render or the LoadingPage component.
 */
const withClienteDirectoContextReady = (Component) => (props) => {

    const {isReady} = useContext(ClienteDirectoContext);
    return isReady ? <Component {...props} /> :
        <Loading/>;
};

export {
    withClienteDirectoContextReady,
    withClienteDirectoContextProvider,
};