import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter as Router} from "react-router-dom";
import {ApolloClient, ApolloProvider, HttpLink, InMemoryCache, ApolloLink} from "@apollo/client";
import {split} from 'apollo-link';
import {getMainDefinition} from 'apollo-utilities';

const AppWithApollo = () => {

    const httpLink = new HttpLink({
        uri: 'https://db.polko.com.ar/v1/graphql',
    });

    const link = split(
        // split based on operation type
        ({query}) => {
            const {kind, operation} = getMainDefinition(query);
            return kind === 'OperationDefinition' && operation === 'subscription';
        },
        ApolloLink.from([httpLink]),
    );

    const client = new ApolloClient({
        link,
        cache: new InMemoryCache(),
    });

    return (
        <ApolloProvider client={client}>
            <App/>
        </ApolloProvider>
    )
};

ReactDOM.render(
    <Router>
        <AppWithApollo/>
    </Router>,
    document.getElementById('root')
);