function getBirthDateForAge(age) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const birthYear = currentYear - age;

    const birthDate = new Date(currentDate.setFullYear(birthYear));

    const isoBirthDate = birthDate.toISOString();

    return isoBirthDate;
}

export { getBirthDateForAge};